import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'installmentsMinimum',
    'installmentsNumber',
    'installmentsMaximum',
    'paymentDays',
    'amortizationSystem',
    'isActiveProduct',
    'minimumPayment',
    'disableByActiveProduct',
    'investmentFundConfiguration',
    'profitsPercentage',
    'multipleOutput'
  ];

  connect() {
    this.toggleFields();
  }

  investmentFundConfigurationToggle() {
    if(this.amortizationSystemTarget.value === 'mezzanine_fund') {
      this.profitsPercentageTarget.disabled = false;
      this.multipleOutputTarget.disabled = true;
      this.investmentFundConfigurationTarget.classList.remove('is-hidden');
    } else if(this.amortizationSystemTarget.value === 'capital_fund') {
      this.multipleOutputTarget.disabled = false;
      this.profitsPercentageTarget.disabled = true;
      this.investmentFundConfigurationTarget.classList.remove('is-hidden');
    } else {
      this.profitsPercentageTarget.disabled = true;
      this.multipleOutputTarget.disabled = true;
      this.investmentFundConfigurationTarget.classList.add('is-hidden');
    }
  }

  toggleFields() {
    if(this.isActiveProductTarget.value === 'true') {
      this.minimumPaymentTarget.classList.remove('is-hidden');

      return;
    }

    this.investmentFundConfigurationToggle();

    if (this.amortizationSystemTarget.value === 'credit_line') {
      this.disableFields();
    }
    else {
      this.enableFields();
    }
  }

  enableFields() {
    this.installmentsMaximumTarget.disabled = false;
    this.installmentsMinimumTarget.disabled = false;
    this.installmentsNumberTarget.disabled = false;
    this.paymentDaysTarget.disabled = true;
    this.minimumPaymentTarget.classList.add('is-hidden');
  }

  disableFields() {
    this.installmentsMaximumTarget.disabled = true;
    this.installmentsMinimumTarget.disabled = true;
    this.installmentsNumberTarget.disabled = true;
    this.paymentDaysTarget.disabled = false;
    this.minimumPaymentTarget.classList.remove('is-hidden');
  }
}
