export default class I18n {

  static t(key, translationsObj = null, translationTree = []) {
    let language = document.querySelector('body').dataset.locale || 'es';
    let translations = translationsObj || window.locales[language];
    let tree = translationTree;
    let nestedKeys = key.split('.');

    if (Object.keys(translations).length > 0 && (typeof(translations) === 'object')) {
      if (nestedKeys.length == 1) {
        tree.push(key);
        let translation = translations[key];
        translation = (translation == undefined ? `Missing translation ${tree.join('.')}` : translation);
        return translation;
      } else {
        let currentKey = nestedKeys.shift();
        tree.push(currentKey);
        let nestedKeysString = nestedKeys.join('.');
        return I18n.t(nestedKeysString, translations[currentKey], tree);
      }
    } else {
      return `Missing translation ${tree.join('.')}`;
    }
  }
}
