import { Controller } from 'stimulus';

export default class extends Controller {

  openNavMenu() {
    const navbar = document.getElementById('navbar');
    const arrowRight = document.getElementById('right-arrow');
    const arrowLeft = document.getElementById('left-arrow');

    navbar.classList.remove('is-closed');
    arrowRight.classList.add('is-hidden');
    arrowLeft.classList.remove('is-hidden');
  }

  closeNavMenu() {
    const navbar = document.getElementById('navbar');
    const arrowRight = document.getElementById('right-arrow');
    const arrowLeft = document.getElementById('left-arrow');

    navbar.classList.add('is-closed');
    arrowRight.classList.remove('is-hidden');
    arrowLeft.classList.add('is-hidden');
  }
}
