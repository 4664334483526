import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['installmentPlan'];

  change(e)  {
    let installmentPlanIdSelected = e.currentTarget.value;

    this.installmentPlanTargets.forEach((installmentPlan) => {
      installmentPlan.classList.add('is-hidden');
    });

    this.installmentPlanTargets.
      find(element => element.id === `installment_plan_${installmentPlanIdSelected}`).
      classList.remove('is-hidden');
  }
}
