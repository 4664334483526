import I18n from './utils/i18n';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'amountType',
    'minimumAmount',
    'maximumAmount',
    'percentage',
    'ruleContent',
    'fieldError'
  ]

  addRule(event) {
    event.preventDefault();

    if(this.validFields()) {
      this.buildNewRule();
      this.resetTargets();
    }
  }

  validFields() {
    this.hiddenError();
    return this.validAmounts() && this.validPercentage();
  }

  validPercentage() {
    let result = this.percentageToNumber(this.percentageTarget.value) > 0;
    if(!result) this.showError(I18n.t('products.minimum_payment.errors.percentage.invalid'));
    return result;
  }

  validAmounts() {
    let result = this.moneyToNumber(this.minimumAmountTarget.value) < this.moneyToNumber(this.maximumAmountTarget.value);
    if(!result) this.showError(I18n.t('products.minimum_payment.errors.amounts.invalid'));
    return result;
  }

  hiddenError() {
    this.fieldErrorTarget.innerHTML = '';
    this.fieldErrorTarget.classList.add('is-hidden');
  }

  showError(message) {
    this.fieldErrorTarget.innerHTML = message;
    this.fieldErrorTarget.classList.remove('is-hidden');
  }

  buildNewRule() {
    let minimumPaymentRulesList = document.querySelector('.minimum-payment-rules');
    let newRule = this.ruleContentTarget.cloneNode(true);

    this.buildAmountType(newRule);
    this.buildMinimumAmount(newRule);
    this.buildMaximumAmount(newRule);
    this.buildPercentage(newRule);
    newRule.classList.remove('is-hidden');
    newRule.removeAttribute('data-target');

    minimumPaymentRulesList.append(newRule);
  }

  buildAmountType(rule) {
    let amountType = rule.querySelector('.amount-type');
    let key = `products.minimum_payment.amount_types.${this.amountTypeTarget.value}`;

    amountType.innerHTML = `${I18n.t(key)}:`;
    amountType.append(this.createInput('product[settings][minimum_payment][rules][][amount_type]', this.amountTypeTarget.value, 'hidden'));
  }

  buildMinimumAmount(rule) {
    let minimumAmount = rule.querySelector('.minimum-amount');

    minimumAmount.innerHTML = `${I18n.t('products.minimum_payment.minimum_amount')}: ${this.minimumAmountTarget.value}`;
    minimumAmount.append(this.createInput('product[settings][minimum_payment][rules][][minimum_amount]', this.minimumAmountTarget.value, 'hidden'));
  }

  buildMaximumAmount(rule) {
    let maximumAmount = rule.querySelector('.maximum-amount');

    maximumAmount.innerHTML = `${I18n.t('products.minimum_payment.maximum_amount')}: ${this.maximumAmountTarget.value}`;
    maximumAmount.append(this.createInput('product[settings][minimum_payment][rules][][maximum_amount]', this.maximumAmountTarget.value, 'hidden'));
  }

  buildPercentage(rule) {
    let percentage = rule.querySelector('.percentage');

    percentage.innerHTML = `${I18n.t('products.minimum_payment.percentage')}: ${this.percentageTarget.value}`;
    percentage.append(this.createInput('product[settings][minimum_payment][rules][][percentage]', this.percentageTarget.value, 'hidden'));
  }

  removeRule(event) {
    event.currentTarget.parentNode.parentNode.remove();
  }

  resetTargets() {
    this.minimumAmountTarget.value = '';
    this.maximumAmountTarget.value = '';
    this.percentageTarget.value = '';
  }

  createInput(name, value = '', type = 'text', cssClass = '') {
    let input = document.createElement('input');
    input.setAttribute('type', type);
    input.setAttribute('name', name);
    input.setAttribute('value', value);
    input.className = `${cssClass} input`;
    return input;
  }

  moneyToNumber(currency) {
    return Number(currency.replace(/[^0-9.-]+/g, ''));
  }

  percentageToNumber(percentage) {
    return Number(percentage.replace(/[%,]+/g, ''));
  }
}
