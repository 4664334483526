import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['metadataList'];

  add(event) {
    event.preventDefault();

    let button = event.target;
    let entry = button.closest('.metadata-entry');

    let inputs = entry.querySelectorAll('input');
    let empty = this.emptyInputs(inputs);

    if (!empty) {
      let clone = this.cloneEntry(entry);
      this.metadataListTarget.insertBefore(clone, entry);
    }
  }

  remove(event) {
    event.preventDefault();

    let button = event.target;
    let entry = button.closest('.metadata-entry');

    this.metadataListTarget.removeChild(entry);
  }

  cloneEntry(entry) {
    let cloneNode = entry.cloneNode(true);
    let buttonDiv = cloneNode.getElementsByClassName('entry-button')[0];
    let button = buttonDiv.children[0];

    button.dataset.action = 'click->metadata#remove';
    button.text = 'Eliminar';
    entry.querySelectorAll('input').forEach(input =>
      input.value = ''
    );

    return cloneNode;
  }

  emptyInputs(inputs) {
    let empty = false;
    Array.from(inputs, input => {
      if (!empty) {
        let { value } = input;
        if (value) {
          value = value.trim();
        }

        empty = value === '';
      }
      return empty;
    });

    return empty;
  }
}
