import moment from 'moment';
import * as daterangepicker from 'daterangepicker'; // eslint-disable-line no-unused-vars

export default class Datepicker {
  constructor() {
    this.initializeDatePicker($('.input-date'));
  }

  initializeDatePicker(element) {
    element.daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      autoUpdateInput: false,
      minYear: 1901,
      maxYear: moment().add(5, 'years').year(),
      locale: {
        format: 'DD/MM/YYYY'
      }
    });

    element.on('apply.daterangepicker', function(ev, picker) {
      $(this).val(picker.startDate.format('DD/MM/YYYY'));
    });
  }
}
