import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'selectField',
    'selectCondition',
    'fieldValue',
    'addConditionFieldButton',
    'turboFrameValue'
  ];

  static values = {
    fieldName: String
  };

  createFieldValue(event) {
    this._injectHeaderTurboStream();
    this.configureTurboFrameValue(event.currentTarget);
  }

  submitConditionField() {
    const form = document.createElement('form');

    form.setAttribute('action', '/conditions_fields');
    form.setAttribute('method', 'post');

    const fieldNameHidden = this.buildInputHidden(
      'conditions_fields[field_name]',
      this.fieldNameValue
    );
    const fieldValueHidden = this.buildInputHidden(
      'conditions_fields[field]',
      this.selectFieldTarget.options[this.selectFieldTarget.selectedIndex].value
    );
    const conditionValueHidden = this.buildInputHidden(
      'conditions_fields[condition]',
      this.selectConditionTarget.options[this.selectConditionTarget.selectedIndex].value
    );
    const valueHidden = this.buildInputHidden(
      'conditions_fields[value]',
      this.fieldValueTarget.value
    );

    form.append(fieldValueHidden);
    form.append(conditionValueHidden);
    form.append(valueHidden);
    form.append(fieldNameHidden);

    const submit = document.createElement('input');
    submit.setAttribute('type', 'submit');
    submit.setAttribute('value', 'send');

    form.append(submit);

    document.body.append(form);

    submit.click();

    form.remove();

    this.turboFrameValueTarget.src = '';
    this.selectFieldTarget.selectedIndex = 0;
    this.selectConditionTarget.selectedIndex = 0;
    this.fieldValueTarget.remove();
  }

  remove(event) {
    event.preventDefault();
    event.currentTarget.parentElement.parentElement.parentElement.remove();
  }

  toggleAddConditionFieldButton() {
    if(
      this.selectFieldTarget.options[this.selectFieldTarget.selectedIndex].value != '' &&
      this.selectConditionTarget.options[this.selectConditionTarget.selectedIndex].value != ''
    ) {
      this.addConditionFieldButtonTarget.disabled = false;
    } else {
      this.addConditionFieldButtonTarget.disabled = true;
    }
  }

  configureTurboFrameValue(element) {
    let model_or_column = element.value.split('#');

    const url = `/conditions_fields/${model_or_column[0]}/${model_or_column[1]}`;

    this.turboFrameValueTarget.src = url;
  }

  _injectHeaderTurboStream() {
    const injectHeaders = event => {
      const { headers } = event.detail.fetchOptions || {};
      if (headers) {
        headers.Accept = `text/vnd.turbo-stream.html, ${headers.Accept}`;
      }
    };

    addEventListener('turbo:before-fetch-request', injectHeaders, {
      once: true,
    });
  }

  buildInputHidden(name, value) {
    let hiddenField = document.createElement('input');

    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', name);
    hiddenField.setAttribute('value', value);

    return hiddenField;
  }
}
