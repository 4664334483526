import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'paymentTypeAnnuity',
    'paymentTypeAnnuityAmount',
    'paymentTypeZeroAmount',
    'paymentTypeEndingBalance',
    'paymentTypePartial',
    'paymentTypePartialAmount',
    'paymentTypePartialPrepaidType',
    'paymentMethod',
    'reducedInstallmentsNumber'
  ];

  static values = {
    index: String
  }

  connect() {
    if(this.indexValue === 'annuity') {
      this.paymentTypeAnnuityTarget.click();
    } else if(this.indexValue == 'partial') {
      this.paymentTypePartialTarget.click();
    } else if(this.indexValue === 'ending_balance') {
      this.paymentTypeEndingBalanceTarget.click();
    } else if(this.indexValue === 'zero_amount') {
      this.paymentTypeZeroAmount.click();
    }
  }

  toggle(e) {
    this.paymentMethodTarget.disabled = false;

    if (e.target.dataset['paymentsTarget'] === 'paymentTypePartial') {
      this.hidePaymentTypeAnnuity();
      this.showPaymentTypePartial();
    } else if(e.target.dataset['paymentsTarget'] === 'paymentTypeEndingBalance') {
      this.hidePaymentTypePartial();
      this.hidePaymentTypeAnnuity();
    } else if(e.target.dataset['paymentsTarget'] === 'paymentTypeAnnuity'){
      this.hidePaymentTypePartial();
      this.showPaymentTypeAnnuity();
    } else if(e.target.dataset['paymentsTarget'] === 'paymentTypeZeroAmount') {
      this.hidePaymentTypePartial();
      this.hidePaymentTypeAnnuity();
      this.showPaymentTypeZeroAmount();
    }
  }

  showPaymentTypeAnnuity() {
    this.paymentMethodTarget.disabled = false;
    this.paymentTypeAnnuityAmountTarget.disabled = false;
    this.paymentTypeAnnuityAmountTarget.parentNode.parentNode.classList.remove(
      'is-hidden'
    );
  }

  hidePaymentTypeAnnuity() {
    this.paymentTypeAnnuityAmountTarget.disabled = true;
    this.paymentTypeAnnuityAmountTarget.parentNode.parentNode.classList.add(
      'is-hidden'
    );
  }

  showPaymentTypeZeroAmount() {
    this.paymentMethodTarget.disabled = true;
  }

  hidePaymentTypeZeroAmount() {
    this.paymentMethodTarget.disabled = false;
  }

  showPaymentTypeEndingBalance() {
    this.paymentMethodTarget.disabled = false;
  }

  hidePaymentTypeEndingBalance() {
    this.paymentMethodTarget.disabled = true;
  }

  showPaymentTypePartial() {
    this.paymentTypePartialAmountTarget.disabled = false;
    this.paymentTypePartialAmountTarget.parentNode.parentNode.classList.remove(
      'is-hidden'
    );
    this.paymentTypePartialPrepaidTypeTarget.parentNode.parentNode.parentNode.classList.remove(
      'is-hidden'
    );
  }

  hidePaymentTypePartial() {
    this.paymentTypePartialAmountTarget.disabled = true;
    this.paymentTypePartialAmountTarget.parentNode.parentNode.classList.add(
      'is-hidden'
    );
    this.paymentTypePartialPrepaidTypeTarget.parentNode.parentNode.parentNode.classList.add(
      'is-hidden'
    );
  }

  choose(e) {
    let prepaidType = e.currentTarget.value;

    if(prepaidType === 'reduce_installments_number') {
      this.reducedInstallmentsNumberTarget.parentNode.parentNode.classList.remove('is-hidden');
    } else {
      this.reducedInstallmentsNumberTarget.parentNode.parentNode.classList.add('is-hidden');
    }
  }
}
