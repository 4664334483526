import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['notification']

  connect() {
    let _notificationTargets = this.notificationTargets;

    setTimeout(() => {
      _notificationTargets.forEach((notification)=>{
        notification.remove();
      });
    }, 2000);
  }

  closeNotification(event) {
    event.target.remove();
  }
}
