import { Controller } from 'stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  static targets = ['container', 'turbo'];

  toggleModal(event) {
    event.preventDefault();

    this.toggleContainerTarget();
    this._injectHeaderTurboStream();
    this.configureTurboFrame(event.currentTarget);
  }

  configureTurboFrame(element) {
    const url = element.getAttribute('href') || element.dataset.url;

    this.turboTarget.innerHTML = '';
    this.turboTarget.src = url;
  }

  toggleContainerTarget() {
    this.containerTarget.classList.toggle('is-active');
  }

  _injectHeaderTurboStream() {
    const injectHeaders = event => {
      const { headers } = event.detail.fetchOptions || {};
      if (headers) {
        headers.Accept = `text/vnd.turbo-stream.html, ${headers.Accept}`;
      }
    };

    addEventListener('turbo:before-fetch-request', injectHeaders, {
      once: true,
    });
  }

  formSubmitEnd(event) {
    event.preventDefault();

    const response = event.detail.fetchResponse.response;

    if (response.status >= 200 && response.status <= 226) {
      if (response.redirected) {
        Turbo.visit(response.url);
      } else if (response.headers.get('Location')) {
        Turbo.visit(response.headers.get('Location'));
      } else {
        this.toggleContainerTarget();
      }
    }
  }
}
