import { Controller } from 'stimulus';

export default class extends Controller {
  updateResource(event) {
    if(window.location.href.indexOf('?') > 0) {
      let url = window.location.href.slice(0, window.location.href.indexOf('?'));

      window.location.href = `${url}?model=${event.target.value}`;
    } else {
      window.location.href = `${window.location.href}?model=${event.target.value}`;
    }
  }
}
