import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let cardToggles = document.querySelectorAll('.card-toggle');

    cardToggles.forEach(function(cardToggle){
      cardToggle.addEventListener('click', e => {
        e.stopPropagation();
        e.currentTarget.parentElement.parentElement.parentElement.parentElement.childNodes[3].classList.toggle('is-hidden');
      });
    });

    let cardCollapseToggles = document.querySelectorAll('a[data-action="collapse"]');

    cardCollapseToggles.forEach(function(cardToggle){
      cardToggle.addEventListener('click', e => {
        e.stopPropagation();
        e.currentTarget.parentElement.parentElement.children[1].classList.toggle('is-hidden');
      });
    });
  }
}
