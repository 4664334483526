import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content'];

  printContent(event) {
    event.preventDefault();
    const printTag = document.getElementById('print');

    printTag.innerHTML = this.contentTarget.innerHTML;
    window.print();
    printTag.innerHTML = '';
  }
}
