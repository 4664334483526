import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'modal',
    'form',
    'inputField',
    'errorField',
    'notification',
  ];

  initialize() {
    if (this.targets.element.className.includes('notification')) {
      setTimeout(() => {
        this.targets.element.remove();
      }, 2000);
    }
  }

  openModal(event) {
    event.preventDefault();
    this.clearFields();
    this.clearValueFields();
    const modalToOpen = event.currentTarget.getAttribute('modalTrigger');
    const modal = document.getElementById(modalToOpen);
    modal.classList.add('is-active');
  }

  closeModal(event) {
    event.preventDefault();
    if (event.currentTarget.getAttribute('markdownModal')) {
      const modal = document.getElementById('markdown-modal');
      modal.classList.remove('is-active');
    } else {
      this.modalTargets.forEach(function (modalTarget) {
        if (event.target.id == modalTarget.id) {
          modalTarget.classList.remove('is-active');
        }
      });

      this.clearNotifications();

      try {
        let elem = document.querySelector('.modal-alert');
        elem.parentElement.removeChild(elem);
      } catch (error) {
        return false;
      }
    }
  }

  onSuccess(event) {
    // eslint-disable-next-line
    let [data, _status, xhr] = event.detail;

    if (data.hasOwnProperty('redirect_to')) {
      // eslint-disable-next-line
      Turbolinks.visit(data['redirect_to']);
    } else {
      location.reload();
    }
  }

  onError(event) {
    event.preventDefault();
    // eslint-disable-next-line
    let [data, status, xhr] = event.detail;
    let notificationError = this.notificationError;

    switch (xhr.status) {
      case 403:
        notificationError(data.alert);
        break;
      // eslint-disable-next-line
      case 422:
        let inputFieldTargets = this.inputFieldTargets;
        let errors = JSON.parse(xhr.response);
        let keys = [];

        for (let error in errors) {
          keys.push(error);
        }

        this.clearFields();

        if (this.hasErrorFieldTarget) {
          this.errorFieldTargets.forEach(function (errorField) {
            if (keys.includes(errorField.id)) {
              inputFieldTargets.forEach(function (inputField) {
                if (inputField.id == errorField.id)
                  inputField.classList.add('is-danger');
              });

              errorField.classList.add('is-danger');
              errorField.classList.remove('is-invisible');
              errorField.innerHTML = errors[errorField.id][0];
            }
          });
        } else {
          notificationError(errors[Object.keys(errors)[0]][0]);
        }

        break;
    }
  }

  _camelcaseToUnderscore(text) {
    return text.replace(/([A-Z])/g, '_$1').toLowerCase();
  }

  setupDataToFormModal(event) {
    event.preventDefault();
    const modalTrigger = event.currentTarget.getAttribute('modalTrigger');
    const modal = document.getElementById(modalTrigger);
    const form = modal.getElementsByTagName('form')[0];
    const dataset = event.currentTarget.dataset;

    for (let data in dataset) {
      let selector = form.querySelector(
        `#${this._camelcaseToUnderscore(data)}`
      );

      if (selector) selector.value = dataset[data];
    }
  }

  resetForms() {
    this.formTargets.forEach(function (form) {
      form.reset();
    });
  }

  clearFields() {
    this.inputFieldTargets.forEach(function (inputField) {
      inputField.classList.remove('is-danger');
    });

    this.errorFieldTargets.forEach(function (errorField) {
      errorField.classList.add('is-invisible');
      errorField.innerHTML = '';
    });
  }

  clearValueFields() {
    this.inputFieldTargets.forEach(function (inputField) {
      if (!inputField.classList.value.match(/value-no-clear/)) {
        inputField.value = '';
      }
    });
  }

  clearNotifications() {
    document.querySelectorAll('.notification').forEach(notification => {
      notification.remove();
    });
  }

  notificationError(text) {
    let nextPositionTop =
      document.querySelectorAll('.notification').length * 84;

    let div = document.createElement('div');
    let textNode = document.createTextNode(text);
    let closeButton = document.createElement('button');

    div.style.top = `${nextPositionTop}px`;
    div.classList.add('notification', 'is-danger');
    closeButton.onclick = event => {
      event.currentTarget.parentNode.remove();
    };
    closeButton.classList.add('delete');
    div.appendChild(closeButton);
    div.appendChild(textNode);

    document.querySelector('body').prepend(div);
  }
}
