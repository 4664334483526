import { Controller } from 'stimulus';
import fontawesome from '@fortawesome/fontawesome';
import {
  faAngleDown,
  faSearch,
  faTimesCircle,
  faEllipsisV,
  faEye,
  faTrash,
  faQuestionCircle,
  faWindowClose,
  faCog,
  faCaretLeft,
  faCaretRight,
  faUpload
} from '@fortawesome/fontawesome-free-solid';

export default class extends Controller {
  connect() {
    this.load();
  }

  load() {
    fontawesome.library.add(faAngleDown);
    fontawesome.library.add(faSearch);
    fontawesome.library.add(faTimesCircle);
    fontawesome.library.add(faEllipsisV);
    fontawesome.library.add(faEye);
    fontawesome.library.add(faTrash);
    fontawesome.library.add(faQuestionCircle);
    fontawesome.library.add(faWindowClose);
    fontawesome.library.add(faCog);
    fontawesome.library.add(faCaretLeft);
    fontawesome.library.add(faCaretRight);
    fontawesome.library.add(faUpload);
  }
}
