import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['file', 'fileInput', 'fileName'];

  fileUploaded(event) {
    event.prevetDefault;

    if(this.fileInputTarget.files.length > 0) {
      this.fileNameTarget.textContent = this.fileInputTarget.files[0].name;
    }
  }
}
