import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'accessoryCalculationType',
    'accessoryCalculationBase',
    'accessoryFixedValue',
    'accessoryPercentage',
    'accessoryInputValue'
  ];

  initialize() {
    if (this.accessoryCalculationTypeTarget.value === 'percentage') {
      this.accessoryCalculationBaseTarget.classList.remove('is-hidden');
      this.accessoryPercentageTarget.classList.remove('is-hidden');
      this.accessoryFixedValueTarget.classList.add('is-hidden');
      return;
    } else if (this.accessoryCalculationTypeTarget.value === 'fixed') {
      this.accessoryCalculationBaseTarget.classList.add('is-hidden');
      this.accessoryPercentageTarget.classList.add('is-hidden');
      this.accessoryFixedValueTarget.classList.remove('is-hidden');
      return;
    } else {
      this.accessoryFixedValueTarget.classList.add('is-hidden');
      this.accessoryCalculationBaseTarget.classList.add('is-hidden');
      this.accessoryPercentageTarget.classList.add('is-hidden');
    }
  }

  selectCalculation() {
    if (this.accessoryCalculationTypeTarget.value === 'percentage') {
      this.accessoryCalculationBaseTarget.classList.remove('is-hidden');
      this.accessoryPercentageTarget.classList.remove('is-hidden');
      this.accessoryFixedValueTarget.classList.add('is-hidden');
      return;
    } else if (this.accessoryCalculationTypeTarget.value === 'fixed') {
      this.accessoryCalculationBaseTarget.classList.add('is-hidden');
      this.accessoryPercentageTarget.classList.add('is-hidden');
      this.accessoryFixedValueTarget.classList.remove('is-hidden');
      return;
    } else {
      this.accessoryFixedValueTarget.classList.add('is-hidden');
      this.accessoryCalculationBaseTarget.classList.add('is-hidden');
      this.accessoryPercentageTarget.classList.add('is-hidden');
    }
  }
}
