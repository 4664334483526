import { Controller } from 'stimulus';
import I18n from './utils/i18n';
import maskInput from 'vanilla-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Datepicker from './utils/datepicker';
import * as chosen from 'chosen-js'; // eslint-disable-line no-unused-vars
import * as choicesJS from 'choices.js';

export default class extends Controller {
  connect() {
    this.load();
  }

  load() {
    this.initializeMoneyFields();
    this.initializePercentageFields();
    this.initializeNumberFields();
    this.initializeChosenSelect();
    this.initializeDateFields();
    this.initializeChoicesJSSelect();
  }

  initializeMoneyFields() {
    const moneyMask = createNumberMask({
      prefix: '$ ',
      suffix: '',
      allowDecimal: true,
    });

    const moneyWithNegativeMask = createNumberMask({
      prefix: '$ ',
      suffix: '',
      allowNegative: true,
      allowDecimal: true,
    });

    let moneyInputs = document.querySelectorAll('.input-money');
    let moneyWithNegativeInputs = document.querySelectorAll(
      '.input-money-with-negative'
    );

    for (let input of moneyInputs) {
      maskInput({
        inputElement: input,
        mask: moneyMask,
      });
    }

    for (let input of moneyWithNegativeInputs) {
      maskInput({
        inputElement: input,
        mask: moneyWithNegativeMask,
      });
    }
  }

  initializePercentageFields() {
    const percentageMask = createNumberMask({
      prefix: '',
      suffix: ' %',
      allowDecimal: true,
    });

    let percentageInputs = document.querySelectorAll('.input-percentage');

    for (let input of percentageInputs) {
      maskInput({
        inputElement: input,
        mask: percentageMask,
      });
    }
  }

  initializeNumberFields() {
    const numberMask = createNumberMask({
      prefix: '',
      suffix: '',
      allowNegative: true
    });

    const decimalNumberMask = createNumberMask({
      prefix: '',
      suffix: '',
      allowDecimal: true,
    });

    let numberInputs = document.querySelectorAll('.input-number');

    for (let input of numberInputs) {
      maskInput({
        inputElement: input,
        mask: numberMask,
      });
    }

    let decimalNumberInputs = document.querySelectorAll(
      '.input-decimal-number'
    );

    for (let input of decimalNumberInputs) {
      maskInput({
        inputElement: input,
        mask: decimalNumberMask,
      });
    }
  }

  initializeChosenSelect() {
    $('.chosen-select').chosen({
      no_results_text: I18n.t('.no_results'),
    });
  }

  initializeChoicesJSSelect() {
    const elements = document.querySelectorAll('.choicesjs-select');

    elements.forEach(ele => {
      new choicesJS(ele, {
        removeItems: true,
        removeItemButton: true,
        highlightedState: 'tag is-primary',
      });
    });
  }

  initializeDateFields() {
    const dateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

    let dateInputs = document.querySelectorAll('.input-date');

    for (let input of dateInputs) {
      maskInput({
        inputElement: input,
        mask: dateMask,
      });
    }

    new Datepicker();
  }
}
