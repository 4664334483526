import { Turbo } from '@hotwired/turbo-rails';
import * as ActiveStorage from '@rails/activestorage';
import './locales';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

ActiveStorage.start();

const application = Application.start();
const context = require.context('./controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

window.Turbo = Turbo;
