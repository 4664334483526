import { Controller } from 'stimulus';
import I18n from '../components/i18n';
import moment from 'moment';
import * as daterangepicker from 'daterangepicker'; // eslint-disable-line no-unused-vars

export default class extends Controller {
  connect () {
    this.filtersForm = $('.filters form');
    this.showFilterCheckbox = this.filtersForm.find('.show-filter');
    this.checkedShowFilterCheckbox = this.filtersForm.find('.show-filter:checked');

    this.filtersForm.on('submit', this.filterFormSubmit.bind(this));
    this.filtersForm.find(':input').prop('disabled', false);

    this.showFilterCheckbox.on('click', this.showFilterClicked.bind(this));

    this.checkedShowFilterCheckbox.each(this.showChecked.bind(this));
  }

  showChecked (index, filter) {
    let filterContainer = $($(filter).attr('card-content'));
    this.initializeDateRangePicker(filterContainer.find('.input-date-filter'));
  }

  showFilterClicked (event) {
    let filterContainer = $($(event.target).attr('card-content'));

    if ($(event.target).is(':checked')) {
      this.initializeDateRangePicker(filterContainer.find('.input-date-filter'));
    } else {
      let dateRangePicker = filterContainer.find('.input-date-filter').data('daterangepicker');
      dateRangePicker && dateRangePicker.remove();
    }

    filterContainer.find(':input').val('');
  }

  filterFormSubmit (event) {
    $(event.target).find(':input').filter(function() { return !this.value; }).attr('disabled', 'disabled');
  }

  initializeDateRangePicker (element) {
    element.daterangepicker({
      parentEl: '.filters form',
      opens: 'center',
      buttonClasses: 'btn btn-secondary btn-xs',
      locale: {
        'format': 'DD/MM/YYYY',
        'separator': ' - ',
        'applyLabel': I18n.t('apply'),
        'cancelLabel': I18n.t('cancel'),
        'customRangeLabel': I18n.t('datepicker.ranges.custom'),
        'daysOfWeek': I18n.t('day_names'),
        'monthNames': I18n.t('month_names'),
        'firstDay': 1
      },
      ranges: this.generateRanges()
    });
  }

  generateRanges () {
    let ranges = {};

    ranges[I18n.t('datepicker.ranges.today')] = [moment(), moment()];
    ranges[I18n.t('datepicker.ranges.yesterday')] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
    ranges[I18n.t('datepicker.ranges.last_7_days')] = [moment().subtract(6, 'days'), moment()];
    ranges[I18n.t('datepicker.ranges.last_30_days')] = [moment().subtract(29, 'days'), moment()];
    ranges[I18n.t('datepicker.ranges.this_month')] = [moment().startOf('month'), moment().endOf('month')];
    ranges[I18n.t('datepicker.ranges.last_month')] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
    ranges[I18n.t('datepicker.ranges.last_3_months')] = [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];

    return ranges;
  }
}
