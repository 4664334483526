import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    let navsAccordions = document.querySelectorAll('.nav-accordion');

    navsAccordions.forEach(function(navAccordion) {
      navAccordion.addEventListener('click', function(event) {
        event.preventDefault();

        let navAccordionClicked = event.target;

        navsAccordions.forEach(function(navAccordion) {
          navAccordion.classList.remove('active');

          document.querySelector(`.nav-accordions-content ${navAccordion.getAttribute('accordion')}`).classList.add('is-hidden');
        });

        navAccordionClicked.classList.add('active');
        document.querySelector(`.nav-accordions-content ${navAccordionClicked.getAttribute('accordion')}`).classList.remove('is-hidden');

        return false;
      });
    });

    this.clickLinkAnchor();
  }

  clickLinkAnchor() {
    let anchor = this.getAnchor();

    if(anchor)
      document.querySelector(`a[href="#${anchor}"]`).click();
  }

  getAnchor() {
    return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  }
}
