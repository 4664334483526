// IMPORTANT: This file should be generated via `bundle exec rake js_locales:generate`
// To add translations update file: `lib/i18n_javascript.rb`

window.locales = {
  "es": {
  "date": {
    "short_day_names": [
      "Dom",
      "Lun",
      "Mar",
      "Mie",
      "Jue",
      "Vie",
      "Sab"
    ],
    "day_names": [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    "formats": {
      "default": "%B %d, %Y",
      "long": "%A, %d de %B de %Y",
      "short": "%d de %b",
      "long_without_day": "%d de %B, %Y"
    },
    "month_names": [
      null,
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ]
  },
  "time": {
    "pm": "pm",
    "am": "am",
    "formats": {
      "default": "%B %d, %Y %I:%M %P",
      "long": "%A, %d de %B de %Y %I:%M %P",
      "short": "%d de %b",
      "day_first": "%d de %B del %Y a las %I:%M %P"
    }
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "Hace cerca de 1 hora",
        "other": "Hace cerca de %{count} horas"
      },
      "about_x_months": {
        "one": "Hace cerca de 1 mes",
        "other": "Hace cerca de %{count} meses"
      },
      "about_x_years": {
        "one": "Hace cerca de 1 año",
        "other": "Hace cerca de %{count} años"
      },
      "almost_x_years": {
        "one": "Hace casi 1 año",
        "other": "Hace casi %{count} años"
      },
      "half_a_minute": "Hace medio minuto",
      "less_than_x_minutes": {
        "one": "Hace menos de 1 minuto",
        "other": "Hace menos de %{count} minutos"
      },
      "less_than_x_seconds": {
        "one": "Hace menos de 1 segundo",
        "other": "Hace menos de %{count} segundos"
      },
      "over_x_years": {
        "one": "Hace más de 1 año",
        "other": "Hace más de %{count} años"
      },
      "x_days": {
        "one": "Hace 1 día",
        "other": "Hace %{count} días"
      },
      "x_minutes": {
        "one": "Hace 1 minuto",
        "other": "Hace %{count} minutos"
      },
      "x_months": {
        "one": "Hace 1 mes",
        "other": "Hace %{count} meses"
      },
      "x_years": {
        "one": "Hace 1 año",
        "other": "Hace %{count} años"
      },
      "x_seconds": {
        "one": "Hace 1 segundo",
        "other": "Hace %{count} segundos"
      }
    }
  },
  "enums": {
    "models": {
      "crowdfunding/investor": {
        "attributes": {
          "investor_type": {
            "investor": "Inversionista",
            "donor": "Donante"
          }
        }
      },
      "crowdfunding/crowdfund": {
        "attributes": {
          "status": {
            "active": "Activa",
            "rejected": "Rechazada",
            "completed": "Completada",
            "credit": "En crédito",
            "closed": "Cerrada"
          }
        }
      },
      "crowdfunding/movement": {
        "attributes": {
          "movement_type": {
            "cash_out": "Retiro",
            "commission": "Comisión",
            "commission_tax": "IVA de comisión",
            "credit": "Crédito",
            "fund": "Préstamo",
            "payment": "Pago",
            "refund": "Reembolso",
            "cancellation": "Cancelación"
          }
        }
      },
      "crowdfunding/fund": {
        "attributes": {
          "status": {
            "finished": "Finalizado",
            "cancelled": "Cancelado",
            "funded": "Prestado",
            "funding": "En préstamo"
          }
        }
      },
      "accessory_applicable": {
        "attributes": {
          "payment_base": {
            "credit_approve_amount": "Capital (Monto aprobado)",
            "credit_ending_balance": "Capital",
            "installment_interest": "Interés sobre plazo",
            "installment_penalty_interest": "Interés moratorio sobre plazo",
            "installment_total_payment": "Plazo"
          }
        }
      },
      "accessory_result": {
        "attributes": {
          "status": {
            "applied": "Aplicado",
            "expired": "Expiró",
            "pending": "Pendiente"
          }
        }
      },
      "credit": {
        "attributes": {
          "credit_type": {
            "credit_balance": "Balance de crédito",
            "credit_line": "Línea de crédito",
            "credit_simple": "Crédito simple",
            "investment_fund": "Fondo de inversión"
          },
          "installments_state": {
            "expiring_with_grace_days": "Pago en días de gracia",
            "more_than_one_expired": "Más de un pago atrasado",
            "no_installments_remaining": "Crédito pagado",
            "one_expired": "Un pago atrasado",
            "restructured": "Crédito reestructurado",
            "up_to_date": "Pagos al corriente",
            "write_off": "Crédito incobrable"
          }
        }
      },
      "credit_application": {
        "attributes": {
          "state": {
            "accepted": "Aceptada",
            "approved": "Aprobada",
            "crowdfund": "En campaña",
            "has_credit": "En crédito",
            "ongoing": "En trámite",
            "rejected": "Rechazada",
            "score_flow": "En calificación"
          }
        }
      },
      "installment": {
        "attributes": {
          "status": {
            "cancelable": "Por pagar",
            "expired": "Expirado",
            "no_paid": "Se acumuló a la siguiente parcialidad",
            "paid": "Pagado",
            "pending": "Pendiente",
            "restructured": "Reestructurado",
            "write_off": "Incobrable"
          }
        }
      },
      "movement": {
        "attributes": {
          "movement_type": {
            "charge": "Cargo",
            "informative": "Promoción",
            "payment": "Abono"
          }
        }
      },
      "payment": {
        "attributes": {
          "payment_type": {
            "advance": "Pago adelantado",
            "annuity": "Pago a la anualidad",
            "ending_balance": "Pago al saldo insoluto",
            "minimum_amount": "Pago a monto mínimo",
            "partial": "Pago parcial",
            "zero_amount": "Se acumula a siguiente parcialidad"
          },
          "prepaid_type": {
            "not_reduce": "No reducir",
            "reduce_annuity": "Reducir anualidad",
            "reduce_installments_automatically": "Reducir plazos automáticamente",
            "reduce_installments_number": "Reducir número de plazos"
          },
          "status": {
            "cancelable": "Cancelable",
            "canceled": "Cancelado",
            "completed": "Completado",
            "failed": "Fallido"
          }
        }
      },
      "product": {
        "attributes": {
          "amortization_system_class": {
            "capital_fund": "Fondo de inversión capital",
            "credit_line": "Línea de credito",
            "french_interest": "Sistema francés",
            "global_interest": "Sistema interés global",
            "mezzanine_fund": "Fondo de inversión mezzanine"
          },
          "crowdfunding_type": {
            "between_people": "Préstamos personales entre personas",
            "between_people_with_capital": "Préstamos empresariales entre personas de capital, copropiedad o regalías",
            "between_people_with_debt": "Préstamos empresariales entre personas de deuda o para inmobiliario",
            "none": "Ninguno"
          }
        }
      },
      "score_component_result": {
        "attributes": {
          "state": {
            "passed": "Aprobado",
            "pending": "Pendiente",
            "rejected": "Rechazado"
          }
        }
      }
    }
  },
  "activerecord": {
    "models": {
      "crowdfunding/investor": "Inversionista",
      "crowdfunding/crowdfund": "Campaña",
      "crowdfunding/movement": "Movimiento",
      "crowdfunding/fund": "Préstamo",
      "crowdfunding/setting": "Configuración de Crowdfunding",
      "crowdfunding/contract": "Contrato",
      "crowdfunding/contract_template": "Plantilla del contrato",
      "no_password/session": "Sessión",
      "user": {
        "one": "Usuario",
        "other": "Usuarios"
      },
      "accessory": "Accesorio",
      "accessory_result": "Resultado de Accesorios",
      "attachment": "Archivos",
      "catalog": "Catálogo",
      "contract": "Contrato",
      "contract_template": "Plantilla del Contrato",
      "credit": "Crédito",
      "credit_application": "Solicitud",
      "credit_history": "Historial Crediticio",
      "customer": "Cliente",
      "event": "Evento",
      "expense": "Gasto",
      "global_setting": "Configuración global",
      "holiday": {
        "one": "Día feriado",
        "other": "Días feriados"
      },
      "income": "Ingreso",
      "installment": "Plazo",
      "installment_plan": "Tabla de Amortización",
      "movement": "Movimiento",
      "payment": "Pago",
      "product": "Producto",
      "promotion": "Promoción",
      "reference": "Referencia",
      "report": "Reporte",
      "score_component": "Componente de Calificación",
      "score_component_result": {
        "one": "Resultado del Componente de Calificación",
        "other": "Calificación"
      },
      "score_flow": "Flujo de Calificación",
      "webhook": {
        "one": "Webhook",
        "other": "Webhooks"
      },
      "webhook_tracker": "Registros de Webhook"
    },
    "attributes": {
      "crowdfunding/investor": {
        "available_balance_amount": "Monto disponible",
        "invested_amount": "Monto préstado",
        "interest_amount": "Rendimiento obtenido",
        "available_balance_amount_cents": "Monto disponible",
        "invested_amount_cents": "Monto invertido",
        "interest_amount_cents": "Monto del interés obtenido",
        "blocked": "Bloqueado",
        "investor_type": "Tipo de inversionista",
        "created_at": "Fecha de creación",
        "updated_at": "Última actualización",
        "contract_id": "Contrato",
        "first_name": "Primer nombre",
        "second_name": "Segundo nombre",
        "last_name": "Apellido paterno",
        "mother_name": "Apellido materno",
        "email": "Correo electrónico",
        "curp": "CURP",
        "rfc": "RFC",
        "clabe": "CLABE",
        "official_id": "Documento de identidad oficial",
        "active": "Activo",
        "signed_contract_at": "Fecha de firma de contrato"
      },
      "crowdfunding/crowdfund": {
        "crowdfund_number": "Número de compaña",
        "status": "Estado de la campaña",
        "amount_cents": "Monto requerido",
        "amount": "Monto requerido",
        "collected_amount": "Monto recaudado",
        "collected_amount_cents": "Monto recaudado",
        "collected_amount_percentage": "% Monto recaudado",
        "success_percentage": "% Meta mínima a recaudar",
        "funds_without_limit": "Fondeo sin límite",
        "campaign_days": "Días de campaña",
        "expiration_date": "Fecha de expiración",
        "created_at": "Fecha de creación",
        "updated_at": "Última actualización"
      },
      "crowdfunding/movement": {
        "movement_type": "Tipo de movimiento",
        "amount": "Monto",
        "amount_cents": "Monto",
        "expiration_date": "Fecha de expiración",
        "reference": "Referencia",
        "description": "Descripción",
        "created_at": "Fecha de creación",
        "updated_at": "Última actualización",
        "tracking_number": "Clave de rastreo"
      },
      "crowdfunding/fund": {
        "amount": "Monto del préstamo",
        "paid_amount": "Capital pagado",
        "interest_paid_amount": "Interés pagado",
        "tax_paid_amount": "IVA pagado",
        "status": "Estatus",
        "investor": "Inversionista",
        "investor_id": "Inversionista",
        "created_at": "Fecha de creación",
        "updated_at": "Última actualización",
        "accessories_paid_amount": "Accesorios pagados",
        "interest_tax_paid_amount": "IVA de interés pagado",
        "payment_details": "Detalle de pagos",
        "penalty_interest_paid_amount": "Interés moratorio pagado",
        "penalty_interest_tax_paid_amount": "IVA de interés moratorio pagado",
        "principal_paid_amount": "Capital pagado",
        "profit_commission_amount": "Comisión",
        "profit_commission_tax_amount": "IVA de comisión",
        "total_paid_amount": "Total pagado"
      },
      "crowdfunding/setting": {
        "minimum_goal_reached_percentage": "% Meta mínima a recaudar",
        "funds_without_limit": "Fondeo sin límite",
        "crowdfund_campaign_days": "Días de campaña",
        "profit_commission_percentage": "% de comisión por lucro",
        "transfer_fund_commission_percentage": "% de comisión por transferencia de préstamo",
        "minimum_investment_amount": "Monto mínimo para prestamos",
        "tax": "IVA",
        "contract_title": "Título",
        "contract_body": "Contenido",
        "udis": "Valor de UDIS",
        "udis_at": "Fecha de última actualización de valor de UDIS"
      },
      "crowdfunding/contract": {
        "title": "Título",
        "body": "Contenido"
      },
      "crowdfunding/contract_template": {
        "title": "Título",
        "body": "Contenido"
      },
      "no_password/session": {
        "email": "Su correo electrónico"
      },
      "user": {
        "confirmation_sent_at": "Confirmación enviada a",
        "confirmation_token": "Código de confirmación",
        "confirmed_at": "Confirmado en",
        "created_at": "Creado en",
        "current_password": "Contraseña actual",
        "current_sign_in_at": "Fecha del ingreso actual",
        "current_sign_in_ip": "IP del ingreso actual",
        "email": "Correo electrónico",
        "encrypted_password": "Contraseña cifrada",
        "failed_attempts": "Intentos fallidos",
        "last_sign_in_at": "Fecha del último ingreso",
        "last_sign_in_ip": "IP del último inicio",
        "locked_at": "Fecha de bloqueo",
        "password": "Contraseña",
        "password_confirmation": "Confirmar contraseña",
        "remember_created_at": "Fecha de 'Recordarme'",
        "remember_me": "Recordar mi sesión",
        "reset_password_sent_at": "Fecha de envío de código para contraseña",
        "reset_password_token": "Código para restablecer contraseña",
        "sign_in_count": "Cantidad de ingresos",
        "unconfirmed_email": "Email no confirmado",
        "unlock_token": "Código de desbloqueo",
        "updated_at": "Actualizado en",
        "blocked": "Bloqueado",
        "id": "ID",
        "old_password": "Contraseña actual",
        "roles": "Privilegios",
        "roles_mask": "Privilegios"
      },
      "accessory": {
        "active": "Activo",
        "archived": "Archivado",
        "calculate_iva": "Calcular IVA",
        "calculation_base": "Base de cálculo",
        "calculation_type": "Tipo de cálculo",
        "created_at": "Fecha de creación",
        "days_offset": "Días de gracia",
        "description": "Descripción",
        "fixed_value": "Valor",
        "name": "Nombre",
        "payment_base": "Base de cobro",
        "percentage": "Porcentaje",
        "settings": "Condiciones",
        "updated_at": "Última actualización"
      },
      "accessory_applicable": {
        "calculate_iva": "Calcular IVA"
      },
      "accessory_result": {
        "status": "Estado",
        "triggers_at": "Fecha de activación",
        "value_cents": "Monto"
      },
      "attachment": {
        "attachment_type": "Tipo de archivo",
        "created_at": "Fecha de creación",
        "file": "Archivo"
      },
      "catalog": {
        "active": "Activo",
        "field": "Campo",
        "name": "Etiqueta",
        "value": "Valor"
      },
      "contract": {
        "body": "Contenido",
        "title": "Título"
      },
      "contract_template": {
        "body": "Contenido",
        "title": "Título"
      },
      "credit": {
        "accepted_at": "Aceptado el",
        "amount": "Monto solicitado",
        "amount_cents": "Monto solicitado",
        "application_number": "Número de crédito",
        "approved_amount": "Monto aprobado",
        "approved_amount_cents": "Monto aprobado",
        "approved_at": "Aprobado el",
        "attachment_type": "Tipo de archivo",
        "average_daily_balance": "Saldo promedio diario",
        "charges_amount": "Total de cargos",
        "charges_promotion_informative_amount": "Total de cargos de promociones",
        "city_address": "Ciudad",
        "clabe": "CLABE",
        "classification": "Tipo de crédito",
        "closing_date": "Fecha de corte",
        "closing_date_day": "Días de fecha de corte",
        "created_at": "Fecha de creación",
        "credit_type": "Tipo de crédito",
        "customer": "Cliente",
        "description": "Descripción",
        "ending_balance": {
          "one": "Saldo pendiente",
          "zero": "Saldo disponible"
        },
        "ending_balance_cents": "Saldo pendiente",
        "exterior_number_address": "Número exterior",
        "first_payment_date": "Fecha de pago inicial",
        "flow": "Flujo",
        "home_phone": "Teléfono de casa",
        "installments": "Plazos",
        "installments_state": "Estado del crédito",
        "interest_rate": "Interés",
        "interior_number_address": "Número interior",
        "late_payment_interest": "Interés moratorio",
        "metadata": "Metadata",
        "minimum_payment_amount": "Pago mínimo",
        "mobile_phone": "Teléfono celular",
        "neighborhood_address": "Colonia",
        "outstanding_balance_previous": "Saldo anterior",
        "password_confirmation": "Confirmar contraseña",
        "payment_due_limit_date": "Fecha límite de pago",
        "payments_amount": "Total de abonos",
        "period_balance_amount": "Saldo del periodo",
        "period_commissions": "Comisiones del periodo",
        "period_commissions_tax": "IVA de Comisiones del periodo",
        "period_days": "Días del periodo",
        "period_interests": "Intereses del periodo",
        "period_interests_tax": "IVA de Intereses del periodo",
        "product": "Producto",
        "product_id": "Producto",
        "rejected_at": "Rechazado el",
        "rejected_notes": "Motivo del rechazo",
        "state": "Estado del crédito",
        "state_address": "Estado",
        "street_address": "Calle",
        "updated_at": "Última actualización",
        "zipcode_address": "Código postal"
      },
      "credit_application": {
        "accepted_at": "Aceptado el",
        "amount": "Monto solicitado",
        "amount_cents": "Monto solicitado",
        "application_number": "Número de solicitud",
        "approved_amount": "Monto aprobado",
        "approved_amount_cents": "Monto aprobado",
        "approved_at": "Aprobado el",
        "attachment_type": "Tipo de archivo",
        "city_address": "Ciudad",
        "clabe": "CLABE",
        "classification": "Tipo de crédito",
        "closing_date_at": "Fecha de corte",
        "created_at": "Fecha de creación",
        "customer": "Cliente",
        "description": "Descripción",
        "exterior_number_address": "Número exterior",
        "first_payment_date": "Fecha de pago inicial",
        "flow": "Flujo de solicitud",
        "home_phone": "Teléfono de casa",
        "installments": "Plazos",
        "interest_rate": "Interés",
        "interior_number_address": "Número interior",
        "late_payment_interest": "Interés moratorio",
        "maximum_penalty_days": "Días máximos de penalizaciones",
        "metadata": "Metadata",
        "mobile_phone": "Teléfono celular",
        "neighborhood_address": "Colonia",
        "payment_days": "Días de pago",
        "product": "Producto",
        "product_id": "Producto",
        "rejected_at": "Rechazado el",
        "rejected_notes": "Motivo del rechazo",
        "state": "Estado del crédito",
        "state_address": "Estado",
        "street_address": "Calle",
        "updated_at": "Última actualización",
        "zipcode_address": "Código postal"
      },
      "credit_history": {
        "created_at": "Fecha de creación",
        "document": "Documento (Buró de crédito)"
      },
      "customer": {
        "attachment_type": "Tipo de archivo",
        "blocked": {
          "one": "Bloqueado",
          "other": "Bloquear",
          "zero": "Desbloquear"
        },
        "created_at": "Fecha de registro",
        "credit_applications": "Solicitudes de Crédito",
        "credit_score": "Calificación crediticia",
        "curp": "CURP",
        "curp_hash": "CURP",
        "date_of_birth": "Fecha de nacimiento",
        "date_of_birth_hash": "Fecha de nacimiento",
        "email": "Correo electrónico",
        "first_name": "Primer nombre",
        "fullname": "Nombre completo",
        "last_name": "Apellido paterno",
        "last_names": "Apellidos",
        "mother_name": "Apellido materno",
        "names": "Nombres",
        "rfc": "RFC",
        "rfc_hash": "RFC",
        "second_name": "Segundo nombre",
        "source": "Medio de adquisición",
        "updated_at": "Última Actualización"
      },
      "event": {
        "body": "Mensaje",
        "created_at": "Creado en",
        "event_type": "Tipo",
        "owner": "Creador",
        "participant_ids": "Participantes",
        "subject": "Motivo"
      },
      "expense": {
        "amount": "Cantidad",
        "classification": "Tipo de gasto"
      },
      "global_setting": {
        "maximum_amount_allowed": "Monto máximo de crédito",
        "maximum_credit_allowed": "Número máximo de créditos"
      },
      "holiday": {
        "created_at": "Fecha de creación",
        "description": "Descripción",
        "happening_at": "Fecha",
        "updated_at": "Última actualización"
      },
      "income": {
        "amount": "Cantidad",
        "classification": "Tipo de ingreso"
      },
      "installment": {
        "accessories_amount_cents": "Total de accesorios",
        "application_number": "Número de crédito",
        "created_at": "Fecha de creación",
        "ending_balance_cents": "Saldo final",
        "interest_cents": "Pago a intereses",
        "interest_tax_cents": "IVA de intereses",
        "number": "Núm. de plazo",
        "payment_date": "Fecha de pago",
        "penalty_interest_cents": "Intereses moratorios",
        "penalty_interest_tax_cents": "IVA de intereses moratorios",
        "principal_cents": "Pago a capital",
        "status": "Estado",
        "total_payment_cents": "Total a pagar",
        "updated_at": "Fecha de actualización"
      },
      "movement": {
        "amount": {
          "one": "Monto del movimiento",
          "zero": "Monto"
        },
        "amount_cents": "Monto",
        "commission": "Es una comisión",
        "created_at": "Fecha de creación",
        "folio_number": "Número de folio",
        "interest": "Es un interés",
        "movement_type": "Tipo de movimiento",
        "reference": "Referencia",
        "tax": "Es un impuesto"
      },
      "payment": {
        "amount": "Monto",
        "amount_cents": "Monto",
        "created_at": "Fecha de pago",
        "payment_method": "Método de pago",
        "payment_reference": "Referencia de pago",
        "payment_type": "Tipo de pago",
        "prepaid_type": "Tipo de prepago",
        "reduced_installments_number": "No. de plazos",
        "status": "Estado"
      },
      "product": {
        "active": "Activo",
        "allow_prepaid": "Permitir pagar por adelantado",
        "amortization_system_class": "Sistema de amortización",
        "calculator_class": "Calculadora de interés",
        "created_at": "Fecha de creación",
        "crowdfunding": "Financiamiento colectivo",
        "crowdfunding_type": "Tipo de operación de financiamiento colectivo",
        "default_amount": "Monto por defecto",
        "default_amount_cents": "Monto por defecto",
        "description": "Descripción",
        "grace_days_application_expires": "Días de rechazo automático",
        "grace_days_interest": "Días de gracia",
        "installments_maximum": "Plazo máximo",
        "installments_minimum": "Plazo mínimo",
        "installments_number": "Plazos por defecto",
        "interest_rate": "Interés",
        "interest_rate_days": "Días de tasa de interés",
        "late_payment_base": "Cálculo de penalización",
        "late_payment_interest": "Interés moratorio",
        "maximum_amount": "Monto máximo",
        "maximum_amount_cents": "Monto máximo",
        "maximum_penalty_days": "Días máximos de penalizaciones",
        "minimum_amount": "Monto mínimo",
        "minimum_amount_cents": "Monto mínimo",
        "name": "Nombre",
        "payment_days": "Días de pago",
        "payment_periodicity": "Periodo de pago",
        "score_flow_id": "Flujo de calificación",
        "short_name": "Nombre corto del producto",
        "tax": "IVA",
        "updated_at": "Fecha de actualización"
      },
      "promotion": {
        "amount": "Monto",
        "created_at": "Fecha de creación",
        "elapsed_installments": "Pagos",
        "interest": "Interés",
        "monthly_payment_amount": "Mensualidad",
        "reference": "Referencia",
        "remaining_balance_cents": "Saldo"
      },
      "reference": {
        "classification": "Tipo de referencia",
        "name": "Nombre",
        "phone": "Teléfono"
      },
      "score_component": {
        "application_fields": "Campos de solicitud",
        "condition_fields": "Campos condicionales",
        "created_at": "Fecha de creación",
        "customer_fields": "Campos de cliente",
        "details_manual": "Detalles",
        "factor": "Factor",
        "label": "Nombre",
        "max_amount_balance": "Monto máximo",
        "min_amount_balance": "Monto mínimo",
        "min_score_buro": "Puntuación mínima",
        "position": "Posición",
        "qualifier_class": "Nombre del componente",
        "updated_at": "Fecha de actualización"
      },
      "score_component_result": {
        "created_at": "Fecha de creación",
        "notes": "Notas",
        "score_component": "Componente de calificación",
        "state": "Estado",
        "updated_at": "Fecha de actualización",
        "value": "Valor"
      },
      "score_flow": {
        "created_at": "Fecha de creación",
        "formula": "Fórmula",
        "id": "ID",
        "name": "Nombre",
        "updated_at": "Fecha de actualización"
      },
      "webhook": {
        "active": "Activo",
        "callback_url": "URL del callback",
        "created_at": "Fecha de creación",
        "description": "Descripción",
        "field": "Campo",
        "name": "Nombre",
        "updated_at": "Fecha de actualización"
      },
      "webhook_tracker": {
        "created_at": "Fecha de creación",
        "response": "Respuesta",
        "status_code": "Código de estado HTTP",
        "target_id": "ID del modelo",
        "target_type": "Modelo",
        "updated_at": "Fecha de actualización"
      }
    },
    "errors": {
      "models": {
        "crowdfunding/crowdfund": {
          "attributes": {
            "rejected": {
              "canceled_notes": "La campaña expiró después de %{days_count} días."
            },
            "amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "amount_cents": {
              "greater_than": "El monto debe ser mayor a cero"
            },
            "collected_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "success_percentage": {
              "not_a_number": "El % meta mínima a recaudar no tiene un formato válido",
              "greater_than": "El % meta mínima a recaudar debe ser mayor a cero"
            },
            "campaign_days": {
              "not_a_number": "Los días de campaña no tiene un formato válido",
              "greater_than": "Los días de campaña debe ser mayor a cero"
            }
          }
        },
        "crowdfunding/investor": {
          "attributes": {
            "first_name": {
              "blank": "El primer nombre no puede estar vacío"
            },
            "last_name": {
              "blank": "El apellido no puede estar vacío"
            },
            "email": {
              "blank": "El correo no puede estar vacío"
            },
            "rfc": {
              "blank": "El rfc no puede estar vacío"
            },
            "curp": {
              "blank": "El curp no puede estar vacío"
            },
            "clabe": {
              "wrong_length": "La CLABE debe ser igual a 18 dígitos númericos",
              "blank": "La CLABE no puede estar vacía"
            },
            "signed_contract_at": {
              "missing_official_id_and_contract": "Es necesario que el Inversionista cuente con el contrato y el documento de identidad oficial",
              "different_than": "La fecha de firma de contrato debe ser con fecha actual"
            },
            "available_balance_amount_cents": {
              "insufficient_balance": "Saldo disponible insuficiente"
            },
            "investorable_id": {
              "taken": "Ya existe un Inversionista con los datos enviados"
            },
            "available_balance_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "invested_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "interest_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            }
          }
        },
        "crowdfunding/movement": {
          "attributes": {
            "amount": {
              "greater_than": "Monto debe ser mayor a cero",
              "insufficient_balance": "Saldo disponible insuficiente",
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "amount_cents": {
              "greater_than": "Monto debe ser mayor a cero",
              "insufficient_balance": "Saldo disponible insuficiente",
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "movement_type": {
              "not_permitted": "Tipo de movimiento no permitido",
              "blank": "Seleccione un tipo de movimiento"
            },
            "description": {
              "blank": "La descripción no puede estar vacío"
            },
            "reference": {
              "taken": "Ya existe una referencia"
            },
            "tracking_number": {
              "taken": "Ya existe una clave de rastreo"
            }
          }
        },
        "crowdfunding/fund": {
          "attributes": {
            "investor_id": {
              "blank": "Seleccione un inversionista",
              "transfer_fund_investor_invalid": "No puedes transferir un préstamo al mismo Inversionista"
            },
            "amount": {
              "greater_than": "La cantidad debe ser mayor a cero",
              "insufficient_balance": "Saldo disponible insuficiente",
              "reached_goal": "La campaña ha alcanzado su monto requerido",
              "minimum_investment_amount": "El monto debe ser igual ó mayor a $%{amount}",
              "less_than_or_equal_to": "Monto rebasa el tope permitido",
              "article_forty_nine": "El inversionista rebasa el monto de fondeo permitido",
              "rule_i_ii_invalid": "El inversionista rebasa el monto de fondeo permitido",
              "rule_iii_invalid": "El inversionista rebasa el monto de fondeo permitido",
              "rule_iv_invalid": "El inversionista rebasa el monto de fondeo permitido"
            },
            "amount_cents": {
              "greater_than": "La cantidad debe ser mayor a cero",
              "insufficient_balance": "Saldo disponible insuficiente",
              "reached_goal": "La campaña ha alcanzado su monto requerido",
              "minimum_investment_amount": "El monto debe ser igual ó mayor a $%{amount}"
            },
            "paid_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "interest_paid_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "tax_paid_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "investor": {
              "required": "Es necesario definir un inversionista"
            },
            "movements": {
              "invalid": "No fue posible crear el movimiento"
            }
          }
        },
        "crowdfunding/setting": {
          "attributes": {
            "minimum_goal_reached_percentage": {
              "out_of_range": "Debe ser dentro de 0 a 100%"
            },
            "crowdfund_campaign_days": {
              "greater_than_or_equal_to": "Debe ser mayor o igual a 0",
              "less_than_or_equal_to": "Debe ser menor o igual a 90"
            },
            "base": {
              "only_record": "Solo puede existir una configuración"
            }
          }
        },
        "crowdfunding/contract": {
          "attributes": {
            "title": {
              "blank": "No puede estar vacío",
              "contract_template_invalid": "Es necesario completar la información del contrato"
            },
            "body": {
              "blank": "No puede estar vacío",
              "contract_template_invalid": "Es necesario completar la información del contrato"
            },
            "investor": {
              "required": "Es necesario vincular un el inversionista con el contrato"
            }
          }
        },
        "crowdfunding/contract_template": {
          "attributes": {
            "title": {
              "blank": "No puede estar vacío"
            },
            "body": {
              "blank": "No puede estar vacío"
            }
          }
        },
        "payment": {
          "attributes": {
            "prepaid_type": {
              "invalid_enum": "%{value} es invalido",
              "blank": "Seleccione un tipo de pago por adelantado.",
              "not_allow_prepaid": "No se permiten pagos por adelantado."
            },
            "amount": {
              "equal_to_ending_balance": "El monto debe ser igual al saldo insoluto",
              "greater_than": "El monto debe ser mayor a cero",
              "greater_than_total_payment": "El monto debe ser mayor al pago de la anualidad",
              "less_than_ending_balance": "El monto debe ser menor al saldo insoluto",
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "payment_method": {
              "blank": "Seleccione un método de pago"
            },
            "reduced_installments_number": {
              "less_than": "El número de plazos debe ser menor al número de cuotas pendientes ó mayor a una cuota"
            },
            "status": {
              "not_cancelable": "No es posible cancelar el pago por que no es el más reciente."
            },
            "tracking_number": {
              "taken": "Ya existe una clave de rastreo"
            }
          }
        },
        "accessory": {
          "attributes": {
            "active": {
              "already_used": "El accessorio está siendo usado por un producto"
            },
            "calculation_type": {
              "blank": "El tipo de cálculo no puede estar vacío.",
              "name": "El tipo de cálculo no puede estar vacío."
            },
            "days_offset": {
              "blank": "El valor de días de gracia no puede estar vacío.",
              "greater_than_or_equal_to": "Días de gracia debe ser mayor o igual a 0.",
              "not_a_number": "El valor de días gracia no es el correcto."
            },
            "fixed_value": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "name": {
              "blank": "El nombre no puede estar vacío."
            },
            "payment_base": {
              "blank": "Seleccione un base de cobro"
            },
            "value": {
              "blank": "El valor del accesorio no puede estar vacío.",
              "not_a_number": "El valor del accesorio no es el correcto.",
              "not_an_integer": "El valor del accesorio no es el correcto."
            }
          }
        },
        "accessory_applicable": {
          "attributes": {
            "description": {
              "blank": "Descripción no puede estar vacio"
            },
            "fixed_value": {
              "less_than": "El monto debe ser menor que el saldo pendiente",
              "less_than_ending_balance": "El monto debe ser menor al saldo pendiente",
              "less_than_interest": "El monto debe ser menor al interés neto",
              "less_than_or_equal_to": "Monto rebasa el tope permitido",
              "must_be_negative": "El monto debe ser una cantidad negativa",
              "negative_amount": "El monto debe ser negativo para aplicar ajuste a crédito",
              "negative_less_than": "El monto debe ser negativo y menor que al saldo pendiente"
            },
            "payment_base": {
              "blank": "Seleccione un tipo de ajuste",
              "credit_adjustment_invalid": "El tipo de ajuste es invalido",
              "quita_taken": "Ya existe un ajuste con Quita"
            }
          }
        },
        "accessory_result": {
          "attributes": {
            "value": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            }
          }
        },
        "attachment": {
          "attributes": {
            "attachment_type": {
              "blank": "Tipo de archivo no puede estar vacío"
            }
          }
        },
        "catalog": {
          "attributes": {
            "field": {
              "invalid": "Formato invalid, verifique que sea correcto."
            },
            "name": {
              "blank": "Nombre no puede estar vacío."
            },
            "value": {
              "blank": "Valor no puede estar vacío.",
              "taken": "Ya existe un catálogo con ese valor."
            }
          }
        },
        "contract_template": {
          "attributes": {
            "body": {
              "blank": "La plantilla de contrato debe tener descripción."
            },
            "title": {
              "blank": "La plantilla de contrato debe tener título."
            }
          }
        },
        "credit_application": {
          "attributes": {
            "accepted_at": {
              "invalid": "La Fecha de aceptación no tiene un formato válido",
              "less_than_or_equal_to": "La Fecha de aceptación debe ser menor o igual a la Fecha de pago inicial.",
              "state_invalid": "La solicitud no puede ser aceptada cuando no ha sido aprobada"
            },
            "amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido",
              "not_a_number": "El monto ingresado no es válido."
            },
            "approved_amount": {
              "amount_not_allowed": "Monto máximo agotado",
              "blank": "Debe ingresar un monto aprobado",
              "credit_not_allowed": "Máximo de créditos agotado",
              "less_than": "El monto aprobado no puede ser mayor al monto solicitado",
              "less_than_or_equal_to": "Monto rebasa el tope permitido",
              "not_a_number": "Debe ingrese un monto aprobado"
            },
            "approved_amount_cents": {
              "blank": "Debe ingresar un monto aprobado",
              "greater_than": "El monto aprobado no puede ser mayor al monto solicitado",
              "less_than": "El monto aprobado no puede ser mayor al monto solicitado",
              "not_a_number": "Debe ingrese un monto aprobado"
            },
            "approved_at": {
              "invalid": "Es necesario que la solicitud haya sido calificada y con todos sus componentes aprobados para poder ser aprobada"
            },
            "base": {
              "cant_create_contract": "Hay problemas con la plantilla del contrato del producto asignado",
              "contract_template_missing": "Es necesario completar la plantilla del contrato del producto asignado a la solicitud"
            },
            "clabe": {
              "blank": "La CLABE no puede estar vacío.",
              "format": "La CLABE es inválida."
            },
            "closing_date_at": {
              "blank": "Fecha de corte no debe estar vacío",
              "greater_than": "La fecha de corte debe ser mayor a la fecha actual"
            },
            "credit": {
              "cant_be_updated": "La Solicitud no puede ser modificada ya que tiene un Crédito asociado."
            },
            "first_payment_date": {
              "blank": "La Fecha de pago inicial no puede estar vacío.",
              "greater_than_or_equal_to": "La Fecha de pago inicial debe ser una fecha actual ó al futuro."
            },
            "installments": {
              "greater_than": "El número de Plazos debe ser mayor a cero.",
              "not_a_number": "Debe introducir un número entero para Plazos."
            },
            "maximum_penalty_days": {
              "greater_than_or_equal_to": "Valor inválido, debe ser mayor o igual a 0",
              "not_a_number": "Debe ingresar un valor númerico"
            },
            "payment_days": {
              "blank": "Días de pago no debe estar vacío",
              "greater_than": "Días de pago debe ser mayor a cero"
            },
            "product_id": {
              "amount_range": "El monto solicitado está fuera del rango permitido por el Producto.",
              "blank": "Necesitas seleccionar un Producto.",
              "cant_be_changed": "No puedes cambiar el Producto de la Solicitud.",
              "cant_enable_crowdfunding": "No es posible usar este producto, favor de contactar al correo electrónico soporte@creditar.io",
              "inactive": "El Producto necesita estar activo.",
              "installments_number": "El número de Plazos no corresponde al del Producto.",
              "installments_range": "El número de Plazos está fuera del rango permitido por el Producto."
            },
            "rejected": {
              "rejected_notes": "La solicitud expiró después de %{days_count} días."
            },
            "rejected_notes": {
              "blank": "Motivo del rechazo no debe estar vacío"
            }
          }
        },
        "credit_history": {
          "attributes": {
            "document": {
              "blank": "Documento no puede estar vacío."
            }
          }
        },
        "customer": {
          "attributes": {
            "base": {
              "product_id": "El Cliente debe tener un Producto activo."
            },
            "blocked": {
              "blocked": "El Cliente está bloqueado."
            },
            "date_of_birth": {
              "invalid": "La Fecha de nacimiento no tiene un formato de fecha válido.",
              "not_a_date": "Fecha de nacimiento no puede estar vacío"
            },
            "email": {
              "blank": "El correo no puede estar vacío.",
              "invalid": "El correo no tiene un formato válido.",
              "taken": "Ya existe un cliente con ese correo."
            }
          }
        },
        "event": {
          "attributes": {
            "event_type": {
              "blank": "Selecciona un Tipo de Evento"
            },
            "subject": {
              "blank": "El título del Evento no puede estar vacío."
            }
          }
        },
        "expense": {
          "attributes": {
            "amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "classification": {
              "blank": "Necesitas seleccionar un tipo de gasto"
            }
          }
        },
        "global_setting": {
          "attributes": {
            "maximum_amount_allowed": {
              "greater_than_or_equal_to": "El monto no es valido, debe ser mayor o igual a 0",
              "not_a_number": "El monto no puede estar en blanco"
            },
            "maximum_credit_allowed": {
              "greater_than_or_equal_to": "La cantidad de creditos no es valida, debe ser mayor o igual a 0",
              "not_a_number": "La cantidad de creditos no puede estar en blanco"
            }
          }
        },
        "holiday": {
          "attributes": {
            "happening_at": {
              "blank": "La fecha no puede estar vacía.",
              "inclusion": "La fecha tiene que ser a futuro.",
              "taken": "El día feriado ya existe."
            }
          }
        },
        "income": {
          "attributes": {
            "amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "classification": {
              "blank": "Necesitas seleccionar un tipo de ingreso"
            }
          }
        },
        "installment": {
          "attributes": {
            "accessories_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "ending_balance": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "interest": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "missing_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "penalty_interest": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "penalty_interest_tax": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "principal": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            },
            "total_payment": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido"
            }
          }
        },
        "movement": {
          "attributes": {
            "amount": {
              "blank": "El monto no debe estar vacío",
              "greater_than": "Introduce un monto mayor a cero",
              "less_than": "Credito sin saldo suficiente",
              "less_than_or_equal_to": "Monto rebasa el tope permitido",
              "not_a_number": "El monto no debe estar vacío"
            },
            "movement_type": {
              "blank": "Seleccione un tipo de movimiento",
              "invalid": "El tipo de movimiento es inválido"
            },
            "reference": {
              "blank": "La referencia no debe estar vacío"
            }
          }
        },
        "product": {
          "attributes": {
            "active": {
              "crowdfunding_deactivate": "No puedes desactivar un producto con solicitudes en recaudación de fondos.",
              "deactivate": "No puedes desactivar un producto con solicitudes ya aprobadas."
            },
            "base": {
              "inactive": "No puedes seleccionar un producto inactivo."
            },
            "calculator_class": {
              "blank": "Calculadora de interés no puede estar vacía."
            },
            "contract_template": {
              "blank": "El producto debe tener plantilla de contrato."
            },
            "crowdfunding": {
              "amortization_system_invalid": "Para un sistema de amortización con línea de crédito es inválido.",
              "cant_be_updated": "No es posible habilitar recaudación de fondos."
            },
            "crowdfunding_type": {
              "blank": "Seleccione un tipo de operación de financiamiento colectivo",
              "inclusion": "Tipo de operación de financiamiento colectivo inválido"
            },
            "default_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido",
              "not_a_number": "La cantidad del monto por defecto no es correcta."
            },
            "grace_days_interest": {
              "less_than_or_equal_to": "Días de gracia para cobro de intereses debe ser menor o igual a 7."
            },
            "interest_rate_days": {
              "greater_than": "Días de tasa de interés debe ser mayor a 0.",
              "less_than_or_equal_to": "Días de tasa de interés debe ser menor o igual a 365."
            },
            "late_payment_base": {
              "blank": "Base para calcular penalización."
            },
            "maximum_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido",
              "not_a_number": "La cantidad del monto máximo no es correcta."
            },
            "minimum_amount": {
              "less_than_or_equal_to": "Monto rebasa el tope permitido",
              "not_a_number": "La cantidad del monto mínimo no es correcta."
            },
            "name": {
              "blank": "El nombre no puede estar vacío."
            },
            "payment_periodicity": {
              "less_than_or_equal_to": "La periodicidad debe ser menor o igual a 365.",
              "not_a_number": "Debes seleccionar una periodicidad de pago."
            },
            "score_flow": {
              "cant_be_updated": "No puedes cambiar el Flujo de calificación por que hay Solicitudes en estado \"En calificación\" que tienen el Flujo de calificación actual"
            },
            "short_name": {
              "blank": "El nombre corto no puede estar vacío."
            }
          }
        },
        "product_accessory": {
          "attributes": {
            "accessory": {
              "required": "Debe seleccionar un Accesorio."
            },
            "accessory_id": {
              "invalid": "El Accesorio debe estar activo."
            },
            "product_id": {
              "invalid": "Para agregar un Accesorio es necesario que el Producto este desactivado."
            }
          }
        },
        "promotion": {
          "attributes": {
            "total_installments": {
              "blank": "El número de plazos no puede estar vacío"
            }
          }
        },
        "score_component": {
          "attributes": {
            "score_flow_id": {
              "locked": "No puedes actualizar por que el Flujo de Calificación está bloqueado."
            },
            "settings": {
              "min_amount_cents": "La cantidad mínima debe ser menor que la cantidad máxima."
            }
          }
        },
        "score_flow": {
          "attributes": {
            "formula": {
              "invalid": "Fórmula es invalida.",
              "score_components_empty": "No se tiene algún Componente de Calificación para definir una fórmula."
            },
            "name": {
              "blank": "Nombre no puede estar vacío."
            }
          }
        },
        "user": {
          "attributes": {
            "current_password": {
              "invalid": "Contraseña actual invalida"
            },
            "email": {
              "blank": "El correo no puede estar vacío.",
              "invalid": "El correo no tiene un formato válido."
            },
            "old_password": {
              "blank": "Contraseña actual no puede estar vacío",
              "invalid": "Contraseña actual incorrecta"
            },
            "password": {
              "blank": "La contraseña no puede estar vacío",
              "too_short": "La contraseña es demasiado corta, introducir al menos 8 caracteres"
            },
            "password_confirmation": {
              "confirmation": "La confirmación de la contraseña no es igual a la nueva contraseña",
              "invalid": "La confirmación de la contraseña no es igual a la nueva contraseña"
            }
          }
        },
        "webhook": {
          "attributes": {
            "callback_url": {
              "blank": "URL del callback no puede estar vacío.",
              "invalid": "URL del callback debe ser HTTPS."
            },
            "description": {
              "blank": "Descripción no puede estar vacío."
            },
            "field": {
              "blank": "Campo no puede estar vacío.",
              "invalid": "El formato del Campo es invalido."
            },
            "name": {
              "blank": "Nombre no puede estar vacío."
            }
          }
        }
      }
    }
  },
  "crowdfunding": {
    "crowdfunds": {
      "show": {
        "collected_amount_percentage": "% Monto recaudado",
        "missing_collect_amount": "Monto faltante",
        "general_information": "Información general",
        "title": "Campaña %{crowdfund_number}"
      },
      "create": {
        "success": "Campaña creada correctamente",
        "alert": "Surgio un error al crear la Campaña: %{error}"
      },
      "new_fund_modal": {
        "prompt": "Seleccionar",
        "title": "Agregar préstamo",
        "submit": "Guardar"
      },
      "funds": {
        "new_fund": "Nuevo préstamo"
      },
      "reject_crowdfund": {
        "rejected": "La campaña ha sido rechazada"
      },
      "transfer_fund_modal": {
        "prompt": "Seleccionar",
        "title": "Transferir préstamo",
        "submit": "Aceptar"
      },
      "cancel_fund_modal": {
        "title": "Cancelar préstamo",
        "submit": "Aceptar"
      },
      "new": {
        "title": "Convertir a campaña"
      }
    },
    "investors": {
      "edit": {
        "title": "Inversionista %{email}"
      },
      "create": {
        "notice": "Inversionista creado correctamente",
        "alert": "Surgio un error al crear el Inversionista: %{error}"
      },
      "update": {
        "notice": "El inversionista ha sido actualizado correctamente",
        "alert": "Surgio un error al actualizar el Inversionista"
      },
      "movement_modal": {
        "title": "Nuevo movimiento",
        "submit": "Crear movimiento",
        "select": "Selecciona"
      },
      "show": {
        "general_information": "Información general",
        "title": "Inversionista %{email}"
      },
      "movements": {
        "new_movement": "Nuevo movimiento"
      },
      "settings": {
        "update": {
          "notice": "Configuración actualizada correctamente",
          "alert": "Surgio un error al actualizar"
        }
      },
      "button": {
        "create": "Crear como Inversionista"
      }
    },
    "settings": {
      "edit": {
        "general_configuration": "Configuración general",
        "configuration_crowdfunds": "Configuración de las campañas"
      },
      "update": {
        "alert": "Surgio un error al actualizar",
        "notice": "Configuración actualizada correctamente"
      },
      "contract_template": {
        "available_fields": "Lista de Campos Disponibles",
        "fields_hint": "Recuerda que para usarlos, debes poner el nombre del campo dentro de %{}"
      }
    },
    "contracts": {
      "field_not_found": "Ocurrió un error al Generar el Contrato, el campo \"%{field}\" no existe. Necesitas corregir la Plantilla del Contrato."
    },
    "funds": {
      "destroy": {
        "notice": "Préstamo cancelado correctamente"
      },
      "create": {
        "description": {
          "fund": "Préstamo a campaña"
        }
      },
      "new": {
        "title": "Nuevo préstamo"
      }
    },
    "transfer_funds": {
      "label": "Transferir préstamo",
      "authorization": {
        "confirm": "Confirmar transferir préstamo",
        "invalid": "Autorización inválida"
      }
    },
    "cancel_funds": {
      "label": "Cancelar préstamo",
      "authorization": {
        "confirm": "Confirmar cancelación",
        "invalid": "Autorización inválida"
      }
    },
    "forbidden": "No es posible utilizar Crowdfunding, favor de contactar al correo electrónico soporte@creditar.io",
    "movements": {
      "description": {
        "cancellation": "Cancelación de %{description}",
        "cash_out": "Retiro de fondos",
        "commission": "Cobro de comisión por manejo de cuenta",
        "commission_tax": "Cobro de IVA por manejo de cuenta",
        "credit": "Deposito de fondos a cuenta",
        "fund": "Préstamo a campaña",
        "payment": "Pago por parcialidad pagada",
        "refund": "Reembolso por %{description}"
      },
      "movements": {
        "new_movement": "Nuevo movimiento"
      },
      "new": {
        "title": "Nuevo movimiento"
      }
    }
  },
  "actions": {
    "edit": "Modificar",
    "back": "Regresar",
    "cancel": "Cancelar",
    "delete": "Eliminar",
    "new": "Nuevo",
    "preview": "Vista previa",
    "show": "Ver detalles",
    "view": "Consultar"
  },
  "generic": {
    "no_records": "No hay %{model} creados todavía.",
    "no_records_associated": "No hay %{association} para este %{model}",
    "no_results": "Tu búsqueda no tuvo resultados.",
    "no_matches": "No hay %{model} que cumplan con tus criterios de filtrado.",
    "record_not_found": "No se encontró el recurso.",
    "actions": "Acciones",
    "activate": "Activar",
    "deactivate": "Desactivar",
    "archive": "Archivar",
    "unarchive": "Desarchivar",
    "confirm": "¿Estás seguro?",
    "disabling": "Cargando...",
    "processing": "Procesando...",
    "deleting": "Eliminando...",
    "filters": {
      "between": "Entre las fechas",
      "contains_to": "Contiene a",
      "different_than": "Diferente de",
      "equal_than": "Igual a",
      "greater_or_equal_than": "Mayor o Igual a",
      "greater_than": "Mayor que",
      "less_or_equal_than": "Menor o Igual a",
      "less_than": "Menor que"
    },
    "print": "Imprimir",
    "n_a": "N/A",
    "submit": "Enviar",
    "close": "Cerrar",
    "accept": "Aceptar",
    "cancel": "Cancelar",
    "nothing_to_show": "No hay %{label} que mostrar",
    "resource_not_found": "%{resource} no encontrado",
    "model_information": "Información del %{model}",
    "upload_file": "Cargar archivo...",
    "show_file": "Ver archivo",
    "show_or_hide": {
      "zero": "Ocultar %{label}",
      "one": "Mostrar %{label}"
    },
    "records_count": {
      "one": "1 registro",
      "other": "%{count} registros"
    },
    "yes": "Sí",
    "no": "No",
    "false": "No",
    "true": "Sí",
    "add": "Agregar",
    "branding": "Aoorora",
    "business_name": {
      "new": "Digital LMS, Inc",
      "old": "Creditar.io Digital SAPI"
    },
    "conditions": {
      "contains_to": "Contiene",
      "different_than": "Diferente que",
      "equal_to": "Igual a",
      "greater_or_equal_to": "Mayor o igual que",
      "greater_than": "Mayor que",
      "less_or_equal_to": "Menor o igual que",
      "less_than": "Menor que"
    },
    "create": "Crear",
    "delete": "Eliminar",
    "edit": "Editar",
    "errors_header": "Surgieron los siguiente errores:",
    "file_not_charge": "No se ha subido ningún archivo",
    "file_upload": "Subir archivo",
    "no_catalogs": "No hay Catálogos con el campo: %{field}",
    "notification": "Notificación",
    "save": "Guardar",
    "selecting": "Seleccionar",
    "show_resource_name": "Ver %{resource_name}",
    "update": "Actualizar"
  },
  "views": {
    "pagination": {
      "next": "Siguiente",
      "last": "Fin",
      "previous": "Previo",
      "first": "Inicio"
    }
  },
  "filters": {
    "operator": "Filtros",
    "value": "Valor",
    "clear": "Limpiar",
    "submit": "Aplicar",
    "operator_prompt": "Selecciona",
    "equal_than": "Igual a",
    "different_than": "Diferente de",
    "greater_than": "Mayor que",
    "less_than": "Menor que",
    "greater_or_equal_than": "Mayor o Igual a",
    "less_or_equal_than": "Menor o Igual a",
    "between": "Entre las fechas",
    "apply": "Aplicar",
    "cancel": "Cancelar",
    "contains_to": "Contiene a"
  },
  "datepicker": {
    "ranges": {
      "today": "Hoy",
      "yesterday": "Ayer",
      "last_7_days": "Últimos 7 días",
      "last_30_days": "Últimos 30 días",
      "this_month": "Mes actual",
      "last_month": "Mes anterior",
      "last_3_months": "Últimos 3 meses",
      "custom": "Rango personalizado"
    }
  },
  "payments": {
    "create": {
      "notice": "Payment was successfully created",
      "alert": "Something went wrong with the payment"
    },
    "update": {
      "notice": "Payment was successfully canceled",
      "alert": "Something went wrong"
    },
    "alerts": {
      "not_found": "El Plazo que intentas procesar no fue encontrado."
    },
    "cancel": "Cancelar",
    "capture": "Capturar",
    "destroy": {
      "notice": "El pago ha sido cancelado satisfactoriamente."
    },
    "expired": "Expirado",
    "not_cancelable": "",
    "paid": "Pagado",
    "payment_capture": "Capturar pago",
    "pending": "Pendiente",
    "prepaid_types": {
      "reduce_annuity": "Reducir anualidad",
      "reduce_installments_automatically": "Reducir pagos automáticamente",
      "reduce_installments_number": "Reducir numero de pagos"
    }
  },
  "number": {
    "format": {
      "delimiter": ",",
      "separator": "."
    }
  },
  "movements": {
    "description": {
      "cash_out": "Retiro de fondos %{description}",
      "commission": "Cobro de comisión %{description}",
      "commission_tax": "Cobro de IVA de comisión %{description}",
      "credit": "Deposito de fondos a cuenta %{description}",
      "fund": "Préstamo a campaña %{description}",
      "payment": "Parcialidad de campaña pagada %{description}",
      "refund": "Reembolso por préstamos %{description}",
      "cancellation": "Cancelación de préstamo %{description}"
    },
    "new": {
      "title": "Nuevo movimiento"
    },
    "payment_reference": "Pago de período: %{id}",
    "promotions": {
      "interest_movement_reference": "%{elapsed_installments} de %{total_installments} %{movement_reference} intereses",
      "monthly_payment_movement_reference": "%{elapsed_installments} de %{total_installments} %{movement_reference}",
      "tax_movement_reference": "%{elapsed_installments} de %{total_installments} %{movement_reference} IVA"
    }
  },
  "queryable": {
    "models": {
      "crowdfunding/investor": {
        "crowdfunding/contract": {
          "attributes": {
            "investor_id": "Contrato"
          }
        }
      },
      "credit": {
        "credit_application": {
          "attributes": {
            "application_number": "Número de Crédito"
          }
        }
      },
      "installment": {
        "credit_application": {
          "attributes": {
            "application_number": "Número de Crédito"
          }
        }
      }
    }
  },
  "errors": {
    "messages": {
      "taken_in_past": "La contraseña fue usada previamente, favor elegir otra.",
      "equal_to_current_password": "Tiene que ser diferente a la contraseña actual.",
      "equal_to_email": "tiene que ser diferente al email",
      "password_complexity": {
        "digit": {
          "one": "Tiene que contener al menos un digito",
          "other": "Tiene que contener al menos %{count} digitos"
        },
        "lower": {
          "one": "Tiene que contener al menos un minúscula",
          "other": "Tiene que contener al menos %{count} minúsculas"
        },
        "symbol": {
          "one": "Tiene que contener al menos un signo de puntuación",
          "other": "Tiene que contener al menos %{count} signos de puntuación"
        },
        "upper": {
          "one": "Tiene que contener al menos un mayúscula",
          "other": "Tiene que contener al menos %{count} mayúsculas"
        }
      },
      "already_confirmed": "ya ha sido confirmada, por favor intenta iniciar sesión",
      "confirmation_period_expired": "necesita confirmarse dentro de %{period}, por favor solicita una nueva",
      "expired": "ha expirado, por favor solicita una nueva",
      "not_found": "no se ha encontrado",
      "not_locked": "no estaba bloqueada",
      "not_saved": {
        "one": "Ocurrió un error al tratar de guardar %{resource}:",
        "other": "Ocurrieron %{count} errores al tratar de guardar %{resource}:"
      }
    }
  },
  "devise": {
    "invalid_captcha": "El captcha ingresado es inválido.",
    "invalid_security_question": "La respuesta a la pregunta de seguridad fue incorrecta.",
    "paranoid_verify": {
      "code_required": "Por favor ingrese el código provisto por nuestro equipo de soporte"
    },
    "password_expired": {
      "updated": "Su nueva contraseña ha sido guardada.",
      "change_required": "Su contraseña ha expirado. Por favor renueve su contraseña.",
      "show": {
        "description": "Introduce una nueva contraseña para poder continuar",
        "new_password": "Nueva contraseña",
        "sign_in": "Iniciar sesión",
        "submit": "Cambiar contraseña",
        "title": "Ha expirado tu contraseña"
      }
    },
    "failure": {
      "session_limited": "Sus credenciales de inicio de sesión fueron usadas en otro navegador. Por favor inicie sesión nuevamente para continuar en éste navegador.",
      "expired": "Su cuenta ha expirado debido a inactividad. Por favor contacte al administrador de la aplicación.",
      "already_authenticated": "Ya has iniciado sesión.",
      "inactive": "Tu cuenta aún no ha sido activada.",
      "invalid": "Contraseña incorrecta.",
      "last_attempt": "Tienes un intento más antes de que tu cuenta sea bloqueada.",
      "locked": "Tu cuenta está bloqueada.",
      "not_found_in_database": "La cuenta no existe.",
      "timeout": "Tu sesión expiró. Por favor, inicia sesión nuevamente para continuar.",
      "unauthenticated": "Tienes que iniciar sesión o registrarte para poder continuar.",
      "unconfirmed": "Tienes que confirmar tu cuenta para poder continuar."
    },
    "confirmations": {
      "confirmed": "Tu cuenta ha sido confirmada satisfactoriamente.",
      "new": {
        "resend_confirmation_instructions": "Reenviar instrucciones de confirmación"
      },
      "send_instructions": "Vas a recibir un correo con instrucciones sobre cómo confirmar tu cuenta en unos minutos.",
      "send_paranoid_instructions": "Si tu correo existe en nuestra base de datos, en unos minutos recibirás un correo con instrucciones sobre cómo confirmar tu cuenta."
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirmar mi cuenta",
        "greeting": "¡Bienvenido %{recipient}!",
        "instruction": "Usted puede confirmar el correo electrónico de su cuenta a través de este enlace:",
        "subject": "Instrucciones de confirmación"
      },
      "email_changed": {
        "greeting": "¡Hola %{recipient}! ",
        "message": "Estamos contactando contigo para notificarte que tu email ha sido cambiado a %{email}.",
        "subject": "Email cambiado"
      },
      "password_change": {
        "greeting": "Hola %{recipient}!",
        "message": "Le estamos contactando para notificarle que su contraseña ha sido cambiada.",
        "subject": "Contraseña cambiada"
      },
      "reset_password_instructions": {
        "action": "Cambiar mi contraseña",
        "greeting": "¡Hola %{recipient}!",
        "instruction": "Alguien ha solicitado un enlace para cambiar su contraseña, lo que se puede realizar a través del siguiente enlace.",
        "instruction_2": "Si usted no lo ha solicitado, por favor ignore este correo electrónico.",
        "instruction_3": "Su contraseña no será cambiada hasta que usted acceda al enlace y cree una nueva.",
        "subject": "Instrucciones de recuperación de contraseña",
        "body_html": "No te preocupes, podemos ayudarte. <br> Para restablecer tu contraseña por favor utiliza el enlace.",
        "disclaimer_html": "Si tu no solicitaste cambiar tu contraseña, puedes ignorar este correo. <br/> Tu contraseña no cambiará hasta que uses el link de arriba.",
        "help": "Si el enlace no funciona, por favor copia y pega esta URL en tu navegador:",
        "link": "RESTABLECER MI CONTRASEÑA",
        "title": "¿Olvidaste tu contraseña?"
      },
      "unlock_instructions": {
        "action": "Desbloquear mi cuenta",
        "greeting": "¡Hola %{recipient}!",
        "instruction": "Haga click en el siguiente enlace para desbloquear su cuenta:",
        "message": "Su cuenta ha sido bloqueada debido a una cantidad excesiva de intentos infructuosos para ingresar.",
        "subject": "Instrucciones para desbloquear",
        "body_html": "Por seguridad tu cuenta ha sido bloqueada debido al exceso de intentos fallidos para <br/> iniciar sesión. <br/> Para desbloquearla por favor utiliza el siguiente enlace.",
        "disclaimer_html": "Si no has sido tú, por favor contáctanos %{mail}. <br/> Si crees que recibiste este correo electrónico por error, por favor ignoralo.",
        "link_text": "DESBLOQUEAR MI CUENTA",
        "title": "¡Hola!"
      }
    },
    "omniauth_callbacks": {
      "failure": "No has sido autorizado en la cuenta %{kind} porque \"%{reason}\".",
      "success": "Has sido autorizado satisfactoriamente en la cuenta %{kind}."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Cambiar mi contraseña",
        "change_your_password": "Cambie su contraseña",
        "confirm_new_password": "Confirme la nueva contraseña",
        "new_password": "Nueva contraseña"
      },
      "new": {
        "forgot_your_password": "¿Ha olvidado su contraseña?",
        "send_me_reset_password_instructions": "Envíeme las instrucciones para resetear mi contraseña"
      },
      "no_token": "No puedes acceder a esta página si no es a través de un enlace para resetear tu contraseña. Si has llegado hasta aquí desde el email para resetear tu contraseña, por favor asegúrate de que la URL introducida está completa.",
      "send_instructions": "Recibirás un correo con instrucciones sobre cómo resetear tu contraseña en unos pocos minutos.",
      "send_paranoid_instructions": "Si tu correo existe en nuestra base de datos, recibirás un correo con instrucciones sobre cómo resetear tu contraseña en tu bandeja de entrada.",
      "updated": "Se ha cambiado tu contraseña. Ya iniciaste sesión.",
      "updated_not_active": "Tu contraseña fue cambiada."
    },
    "registrations": {
      "destroyed": "¡Adiós! Tu cuenta ha sido cancelada correctamente. Esperamos verte pronto.",
      "edit": {
        "are_you_sure": "¿Está usted seguro?",
        "cancel_my_account": "Anular mi cuenta",
        "currently_waiting_confirmation_for_email": "Actualmente esperando la confirmacion de: %{email} ",
        "leave_blank_if_you_don_t_want_to_change_it": "dejar en blanco si no desea cambiarlo",
        "title": "Editar %{resource}",
        "unhappy": "Infeliz",
        "update": "Actualizar",
        "we_need_your_current_password_to_confirm_your_changes": "necesitamos su contraseña actual para confirmar los cambios"
      },
      "new": {
        "sign_up": "Registrarse"
      },
      "signed_up": "Bienvenido. Tu cuenta fue creada.",
      "signed_up_but_inactive": "Tu cuenta ha sido creada correctamente. Sin embargo, no hemos podido iniciar la sesión porque tu cuenta aún no está activada.",
      "signed_up_but_locked": "Tu cuenta ha sido creada correctamente. Sin embargo, no hemos podido iniciar la sesión porque que tu cuenta está bloqueada.",
      "signed_up_but_unconfirmed": "Se ha enviado un mensaje con un enlace de confirmación a tu correo electrónico. Abre el enlace para activar tu cuenta.",
      "update_needs_confirmation": "Has actualizado tu cuenta correctamente, pero es necesario confirmar tu nuevo correo electrónico. Por favor, comprueba tu correo y sigue el enlace de confirmación para finalizar la comprobación del nuevo correo electrónico.",
      "updated": "Tu cuenta se ha actualizada."
    },
    "sessions": {
      "already_signed_out": "Sesión finalizada.",
      "inactive_account": "Tu cuenta se encuentra bloqueada, favor de contactar a tu administrador",
      "new": {
        "sign_in": "Iniciar sesión"
      },
      "signed_in": "Sesión iniciada.",
      "signed_out": "Sesión finalizada."
    },
    "shared": {
      "links": {
        "back": "Atrás",
        "didn_t_receive_confirmation_instructions": "¿No ha recibido las instrucciones de confirmación?",
        "didn_t_receive_unlock_instructions": "¿No ha recibido instrucciones para desbloquear?",
        "forgot_your_password": "¿Ha olvidado su contraseña?",
        "sign_in": "Iniciar sesión",
        "sign_in_with_provider": "Iniciar sesión con %{provider}",
        "sign_up": "Registrarse"
      },
      "minimum_password_length": {
        "one": "(%{count} caractere como mínimo)",
        "other": "(%{count} caracteres como mínimo)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Reenviar instrucciones para desbloquear"
      },
      "send_instructions": "Vas a recibir instrucciones para desbloquear tu cuenta en unos pocos minutos.",
      "send_paranoid_instructions": "Si tu cuenta existe, vas a recibir instrucciones para desbloquear tu cuenta en unos pocos minutos.",
      "unlocked": "Tu cuenta ha sido desbloqueada. Ya puedes iniciar sesión."
    }
  },
  "flash": {
    "update": {
      "invalid_code": {
        "alert": {
          "title": "Código inválido",
          "description": "Revise su código no es válido o ya expiró. Puede solicitar uno nuevo."
        }
      },
      "session": {
        "alert": {
          "title": "No existe sesión activa",
          "description": "Inicie una sesión para continuar."
        }
      }
    },
    "record_not_found": "El registro al que intentas acceder no existe.",
    "user_not_authorized": "No estás autorizado para realizar esta acción."
  },
  "form": {
    "placeholders": {
      "email": "ana@mi-correo.com",
      "token": "4646-39103-0867"
    },
    "labels": {
      "token": "Código"
    },
    "submit": {
      "login": "Continuar"
    }
  },
  "layouts": {
    "no_password": {
      "mailer": {
        "service": "Servicio de Aoorora",
        "platform": "La plataforma de core bancario sobre la cual ejecutar tu negocio financiero."
      }
    }
  },
  "mailers": {
    "default_from": "no-reply@aoorora.com",
    "send_token": {
      "subject": "Su código de sesión temporal está aquí.",
      "greetings": "Hola!",
      "instructions_1": "Usted solicitó un código de acceso a nuestro servicio. Su código temporal es el siguiente. Copie y pegue el código en nuestro sition. Por favor no comparta el código con otras personas.",
      "instructions_2": "O puede iniciar sesión automáticamente con el siguiente botón. Se abrirá una nueva ventana en su navegador.",
      "instructions_2_text": "O puede iniciar sesión automáticamente con el siguiente vínculo. Cópielo y péguelo en su navegador.",
      "instructions_3": "Si usted no solicitó este correo, por favor haga caso omiso de él y eliminelo. No lo reenvie o comparta con otras personas.",
      "start_session": "Continuar a su sessión"
    }
  },
  "no_password": {
    "sessions": {
      "new": {
        "title": "Nueva sesión",
        "description": "Introduzca su correo electrónico para recibir un código y un enlace mágico para iniciar sesión."
      }
    },
    "session_confirmations": {
      "edit": {
        "title": "Iniciar sesión",
        "description": "Utilice el código o la URL que recibió en su correo. Si no lo encuentra, búsquelo en la bandeja de Spam.",
        "submit": "Iniciar sesión",
        "request_token": "Solicitar nuevo código"
      }
    }
  },
  "decorators": {
    "payment": {
      "payment_type_with_prepaid_type": "%{from} para %{to}"
    }
  },
  "accepted_applications": {
    "new": {
      "notice": "Esta acción se entiende que el Cliente acepto el crédito.",
      "submit": "Aceptar",
      "title": "Aceptar solicitud"
    }
  },
  "accessories": {
    "calculation_type": {
      "fixed": "Fijo",
      "percentage": "Porcentaje"
    },
    "conditions": {
      "add": "Agregar",
      "condition": "Condición",
      "condition_fields": "Condiciones",
      "conditions": {
        "condition": "Seleccione una condición",
        "field": "Seleccione un campo"
      },
      "fields": "Campos",
      "fields_added": "Campos añadidos",
      "value": "Valor"
    },
    "create": {
      "notice": "El Accessorio fue creado satisfactoriamente."
    },
    "edit": {
      "title": "Accesorio %{name}"
    },
    "model_fields": "Campos de %{model}",
    "new": {
      "title": "Nuevo Accesorio"
    },
    "show": {
      "title": "Accesorio %{name}"
    },
    "update": {
      "notice": "El Accessorio fue actualizado correctamente."
    }
  },
  "activate_accessories": {
    "create": {
      "notice": "El Accesorio ha sido activado correctamente."
    },
    "destroy": {
      "notice": "El Accesorio ha sido desactivado correctamente."
    }
  },
  "activate_catalogs": {
    "create": {
      "notice": "El catálogo ha sido activado correctamente."
    },
    "destroy": {
      "notice": "El catálogo ha sido desactivado correctamente."
    }
  },
  "activate_products": {
    "create": {
      "notice": "El Producto ha sido activado correctamente."
    },
    "destroy": {
      "notice": "El Producto ha sido desactivado correctamente."
    }
  },
  "activate_sandbox": {
    "create": {
      "activated": "Entorno de pruebas activado"
    },
    "destroy": {
      "desactivated": "Entorno de pruebas desactivado"
    }
  },
  "activate_webhooks": {
    "create": {
      "notice": "El Webhook ha sido activado correctamente."
    },
    "destroy": {
      "notice": "El Webhook ha sido desactivado correctamente."
    }
  },
  "activemodel": {
    "attributes": {
      "investment_fund_configuration": {
        "multiple_output": "Múltiplo de salida",
        "profits_percentage": "Porcentaje sobre utilidades"
      }
    },
    "errors": {
      "models": {
        "balance_score_qualifier": {
          "attributes": {
            "incomes": {
              "above_maximum": "La diferencia entre Ingresos y Egresos supera el máximo.",
              "below_minimum": "La diferencia entre Ingresos y Egresos no supera el mínimo."
            }
          }
        },
        "buro_score_qualifier": {
          "attributes": {
            "credit_history": {
              "below_minimum": "El Score del Buró no supera el mínimo esperado.",
              "not_found": "La Solicitud de Crédito no tiene ningún Historial Crediticio asociado."
            }
          }
        },
        "document_score_qualifier": {
          "attributes": {
            "application": {
              "document_blank": "No hay documento que verificar",
              "document_invalid": "Documento invalido",
              "document_timeout": "No se pudo procesar por problemas de conexión"
            }
          }
        }
      }
    }
  },
  "api": {
    "errors": {
      "generic": {
        "not_found": "El %{model_name} no existe."
      }
    }
  },
  "api_key": "Llave de Acceso",
  "approved_applications": {
    "new": {
      "submit": "Aprobar",
      "title": "Aprobar solicitud"
    }
  },
  "archive_accessories": {
    "create": {
      "notice": "El Accessorio ha sido archivado correctamente."
    },
    "destroy": {
      "notice": "El Accessorio ha sido desarchivado correctamente."
    }
  },
  "attachments": {
    "errors": {
      "blank": "Es necesario cargar un archivo.",
      "size": "El tamaño del archivo no debe superar los 7 MB.",
      "type": "Solo se permiten archivos de tipo GIF, TIFF, PNG, JPG/JPEG y PDF."
    },
    "preview": "Ver archivo"
  },
  "authorize_with_password": {
    "invalid": "Contraseña incorrecta",
    "label": "Contraseña",
    "title": "Autorización con contraseña"
  },
  "block_customers": {
    "create": {
      "notice": "Cliente ha sido bloqueado."
    },
    "destroy": {
      "notice": "Cliente ha sido desbloqueado."
    }
  },
  "block_users": {
    "create": {
      "success": "El usuario %{email} ha sido bloqueado satisfactoriamente"
    },
    "destroy": {
      "success": "El usuario %{email} ha sido desbloqueado satisfactoriamente"
    }
  },
  "bulk_payments": {
    "attributes": {
      "payment_date": "Fecha de pago."
    },
    "bulk_payments_modal": {
      "title": "Seleccione una fecha para aplicar los pagos masivos."
    },
    "errors": {
      "amount_cents": {
        "less_than": "El monto cobrado es menor al total por pagar."
      },
      "banks": {
        "not_found": "No se encontró el banco seleccionado para mapear"
      },
      "credit": {
        "not_found": "Crédito no encontrado."
      },
      "file": {
        "empty": "El archivo no cuenta con registros que procesar.",
        "invalid": "Archivo invalido."
      },
      "installment": {
        "invalid": "No se encontró ningún Plazo como pendiente por pagar o es posible que haya expirado."
      },
      "payment": {
        "failed": "Falló al realizar el cobro del pago."
      },
      "payment_date": {
        "different_than": "La fecha de pago del Plazo es diferente a la fecha capturada.",
        "invalid": "Fecha de pago invalida."
      },
      "status": {
        "invalid": "Se encontró un estado \"%{status}\" que no coincide con la lista de estados para la columna %{header} en la línea: %{line}"
      }
    },
    "headers": {
      "consecutive": "consecutivo",
      "credit_id": "numero_de_credito",
      "invalid": "%{header} es una cabecera invalida en el archivo.",
      "result": "resultado"
    },
    "payment": {
      "created": "El pago ha sido efectuado correctamente.",
      "failed": "El pago ha sido fallido."
    },
    "upload_file_bancomer": "Subir archivo Bancomer"
  },
  "catalogs": {
    "create": {
      "notice": "Catálogo ha sido creado correctamente."
    },
    "edit": {
      "title": "Editar catálogo"
    },
    "form": {
      "wrong_configuration": "La clase %{model} no existe. Contacte a soporte técnico."
    },
    "new": {
      "title": "Nuevo Catálogo"
    },
    "show": {
      "title": "Catálogo %{name}"
    },
    "update": {
      "notice": "Catálogo ha sido actualizado correctamente."
    }
  },
  "change_password": "Cambiar Contraseña",
  "conditions_fields": {
    "add": "Agregar",
    "condition": "Condición",
    "fields": "Campos",
    "fields_added": "Campos agregados",
    "title": "Campos de condiciones",
    "value": "Valor"
  },
  "constrains": {
    "limits_applied": "Límites aplicados",
    "maximum_amount_allowed": "Monto máximo agotado",
    "maximum_credit_allowed": "Máximo de créditos agotado"
  },
  "contract_templates": {
    "field_not_found": "Ocurrió un error al Generar el Contrato, el campo \"%{field}\" no existe. Necesitas corregir la Plantilla del Contrato."
  },
  "contracts": {
    "create": {
      "notice": "Contrato generado exitosamente."
    }
  },
  "create_credits": {
    "new": {
      "submit": "Aceptar",
      "title": "Convertir a crédito"
    }
  },
  "credit_adjustments": {
    "interest_adjustment": "AJUSTE A INTERESES",
    "mezzanine_fund_profit_adjustment": "Utilidades (Último plazo)",
    "new": {
      "description": "Descripción",
      "fixed_value": "Monto",
      "fixed_value_placeholder": "$ 0.00",
      "partial_fixed_value_amount": "Capturar monto",
      "payment_base": "Tipo de ajuste",
      "title": "Ajuste"
    },
    "payment_bases": {
      "interest": "Intereses (plazo actual)",
      "mezzanine_fund_profit": "Utilidades (Último plazo)",
      "penalty_interest": "Intereses moratorios (plazo actual)",
      "quita": "Quita",
      "total_payment": "Total de accesorios (plazo actual)"
    },
    "penalty_interest_adjustment": "AJUSTE A INTERESES MORATORIOS",
    "quita_adjustment": "QUITA",
    "total_payment_adjustment": "AJUSTE A TOTAL DE ACCESORIOS"
  },
  "credit_application_validation": {
    "approved_amount": "Necesitas guardar el Monto Aprobado para poder aprobar la Solicitud.",
    "product_selected_and_saved": "Necesitas seleccionar y guardar un producto para poder calificar una solicitud.",
    "rejected_notes": "Necesitas guardar los Motivos de Rechazo para poder rechazar la Solicitud."
  },
  "credit_applications": {
    "accept_button": {
      "label": "Aceptar"
    },
    "alert": "La solicitud que intentas actualizar se encuentra %{state}.",
    "approve_button": {
      "label": "Aprobar"
    },
    "attachments": {
      "attachment_submit": "Adjuntar archivo"
    },
    "contract": {
      "no_contract": "La Solicitud no ha sido aprobada, al aprobarla, se generará automáticamente el contrato."
    },
    "create": {
      "alert": "Algo salió mal al crear la Solicitud de Crédito.",
      "notice": "La Solicitud de crédito ha sido creado exitosamente. Ahora puedes editarla."
    },
    "create_credit_button": {
      "label": "Convertir a Crédito"
    },
    "create_crowdfund_button": {
      "label": "Convertir a Campaña"
    },
    "create_crowdfund_campaign_modal": {
      "submit": "Convertir a campaña",
      "title": "Convertir a campaña"
    },
    "credit_histories": {
      "credit_history_submit": "Crear Historial Crediticio"
    },
    "crowdfunds": {
      "rejected_notes": "La Solicitud ha sido rechazada por falta de préstamos en la Campaña"
    },
    "edit": {
      "title": "Solicitud de crédito %{application_number}"
    },
    "expenses": {
      "expense_submit": "Crear gasto"
    },
    "form": {
      "address": "Dirección",
      "client_information": "Información del cliente",
      "credit": "Crédito",
      "product": "Producto",
      "status": "Detalle de la solicitud"
    },
    "incomes": {
      "income_submit": "Crear ingreso"
    },
    "preview": {
      "summary": "Resumen de la Solicitud",
      "title": "Solicitud de crédito %{application_number}",
      "total_credit_amount": "Monto Total",
      "total_interest": "Total de Intereses",
      "total_interest_tax": "Total de IVA de Intereses"
    },
    "preview_button": {
      "label": "Visualizar a crédito"
    },
    "references": {
      "reference_submit": "Crear referencia"
    },
    "reject_button": {
      "label": "Rechazar"
    },
    "score_button": {
      "label": "Calificar"
    },
    "score_flow_results": {
      "actions": "Acciones",
      "customer_credit_score_submit": "Actualizar",
      "manual_approve": "Aprobar",
      "no_notes": "N/A",
      "no_score": "El Flujo de Calificación no se ha ejecutado todavía sobre la Solicitud de Crédito.",
      "no_value": "N/A",
      "pending_header": "Componentes Pendientes",
      "results_header": "Componentes Ejecutados",
      "score_result": "Calificación: %{score}"
    },
    "show": {
      "address": "Dirección",
      "alert": "La Solicitud de crédito necesita un Producto.",
      "client_information": "Información del cliente",
      "credit": "Crédito",
      "product": "Producto",
      "status": "Detalle de la solicitud",
      "title": "Solicitud de crédito %{application_number}"
    },
    "update": {
      "alert": "Algo salió mal al actualizar la Solicitud de Crédito.",
      "notice": "La información de la Solicitud ha sido guardada correctamente."
    },
    "view_credit": "Ver Crédito"
  },
  "credit_histories": {
    "show": {
      "title": "Historial Crediticio"
    }
  },
  "credit_restructure": {
    "blank": "Ningún campo puede estar vacío",
    "error_approve": "Error al aprobar la nueva solicitud",
    "error_credit": "Error al convertir a crédito",
    "error_credit_application": "Error al crear la nueva solicitud",
    "error_credit_application_update": "Error al actualizar la nueva solicitud",
    "error_credit_restrutured": "Error al restruturar crédito original",
    "error_score_flow": "Error al calificar la nueva solicitud",
    "invalid_password": "Constraseña incorrecta",
    "record_not_found": "Crédito no encontrado",
    "score_component_note": "Calificado por reestructuracion"
  },
  "credits": {
    "create": {
      "alert": "Algo salió mal creando el Crédito.",
      "notice": "El Crédito fue creado satisfactoriamente."
    },
    "credit_simples": {
      "installment_plan": {
        "credit_adjustment": "Ajuste"
      }
    },
    "index": {
      "ending_balance": "Saldo disponible ó pendiente"
    },
    "investment_funds": {
      "installment_plan": {
        "credit_adjustment": "Ajuste"
      }
    },
    "periods": {
      "current_period": "Periodo actual",
      "last_previous_period": "Periodo previo al anterior",
      "previos_period": "Periodo anterior",
      "promotions": {
        "progress_bar_text": "%{elapsed} de %{total}"
      }
    },
    "restructure_modal": {
      "restructure": "Reestructurar"
    },
    "show": {
      "credit_details": "Detalles del crédito",
      "customer_information": "Información del cliente",
      "general_information": "Información general",
      "initial_capital": "Capital inicial",
      "interests_paid": "Intereses pagados",
      "missing_amount": "Pendiente de pago para el plazo No. %{number}",
      "penalty_interest_paid": "Intereses moratorios pagados",
      "taxes_paid": "IVA pagado",
      "title": "Crédito %{application_number}"
    },
    "view_credit_application": "Ver solicitud"
  },
  "customers": {
    "attachments": {
      "attachment_submit": "Adjuntar archivo"
    },
    "create": {
      "notice": "El cliente ha sido creado. Ahora puedes comenzar a llenar su Solicitud de Crédito."
    },
    "credit_applications": {
      "amount": "Monto",
      "application_number": "Solicitud",
      "create_new_credit_application": "Crear nueva solicitud",
      "status": "Estado"
    },
    "edit": {
      "title": "Cliente %{email}"
    },
    "events": {
      "event_submit": "Crear evento",
      "participant_ids_prompt": "Seleccione uno o más",
      "participants": "Participantes: %{users}"
    },
    "new": {
      "title": "Nuevo Cliente"
    },
    "show": {
      "create_investor": "Crear inversionista",
      "show_investor": "Ver inversionista",
      "title": "Cliente %{email}"
    },
    "update": {
      "notice": "Cliente ha sido actualizado correctamente."
    }
  },
  "dashboards": {
    "show": {
      "welcome": "Bienvenido a"
    }
  },
  "demo": {
    "blog": "Blog",
    "confirm": "Iniciar sesión",
    "confirm_info": "Introduzca el código de accesso que recibió o utilice el link del correo electrónico.",
    "demo_user_missing": "Tienes que generar un usuario demo con el correo \"demo@creditar.io\" para poder continuar.",
    "description": "Prueba Aoorora de la manera más simple y sin compromiso. Descubre lo sencillo que es administrar créditos simples y revolventes de tus clientes.",
    "email_label": "Correo electrónico",
    "email_placeholder": "Introduce tu correo electrónico",
    "help": "Enviaremos un correo con el código de accesso y link para iniciar tu prueba. Revisa la bandeja de SPAM si no lo recibes.",
    "identity": "Identidad",
    "pretitle": "Prueba sin compromiso",
    "start_info": "Inicia la prueba con un solo paso.",
    "submit": "Iniciar mi prueba",
    "submited_code_description": "Te hemos enviado un código de accesso",
    "subtitle": "es muy sencillo",
    "title": "Prueba nuestra plataforma,",
    "token_email": "Código de accesso",
    "token_placeholder": "HJn7-5840-HxkX",
    "vacancies": "Vacantes"
  },
  "demo_notification_mailer": {
    "subject": "Solicitud de Demo",
    "subject_html": "Se ha solicitado una sesión de demo de Aoorora desde el correo",
    "team_of": "Equipo de"
  },
  "duplicate_records": {
    "duplicate_record": "Duplicar registro",
    "duplicated_record": "Registro duplicado",
    "record_not_found": "Registro no encontrado",
    "record_not_permited": "Registro no permitido para duplicar"
  },
  "error_pages": {
    "not_found": {
      "description": "Lo sentimos pero parece que no podemos encontrar el recurso que estás buscando. Esto normalmente sucede cuando un recurso ya no está disponible, fue eliminado o la dirección es incorrecta.",
      "message": "NO SE ENCONTRÓ EL RECURSO SOLICITADO"
    },
    "unauthorized": {
      "description": "Lo sentimos pero parece que no cuentas con el permiso para realizar esta acción.",
      "message": "NO SE TIENE EL PERMISO"
    }
  },
  "events": {
    "show": {
      "no_participants": "No hay participantes en el evento."
    }
  },
  "exportable": {
    "credit": {
      "credit_application": {
        "application_number": "Número de Crédito"
      }
    },
    "installment": {
      "credit_application": {
        "application_number": "Número de Crédito"
      }
    }
  },
  "footer": {
    "blog": "Blog",
    "company": "Compañia",
    "copyright": "© 2022 Digital LMS, Inc. Todo los derechos reservados.",
    "description": "La plataforma bancaria para tu negocio financiero.",
    "documentation": "Documentación",
    "email": "hola@aoorora.com",
    "facebook": "Facebook",
    "footer": "Footer",
    "github": "GitHub",
    "identity": "Identidad",
    "instagram": "Instagram",
    "legal": "Legal",
    "platform": "Plataforma bancaria",
    "privacy": "Privacidad",
    "solutions": "Soluciones",
    "support": "Soporte",
    "twitter": "Twitter",
    "vacancies": "Vacantes"
  },
  "global_settings": "Configuración Global",
  "helpers": {
    "label": {
      "product_accessory": {
        "accessory_id": "Accesorio"
      }
    },
    "placeholder": {
      "accessory": {
        "days_offset": "Ej. 0",
        "fixed_value": "Ej. $10",
        "name": "Ej. Comisión por apertura",
        "percentage": "Ej. 10%"
      },
      "contract_template": {
        "title": "Título del Contrato"
      },
      "customer": {
        "curp": "Clave CURP",
        "date_of_birth": "11/09/1991",
        "email": "cliente@email.com",
        "first_name": "Juán",
        "last_name": "Pérez",
        "mother_name": "García",
        "rfc": "Clave RFC",
        "second_name": "Carlos"
      },
      "holiday": {
        "description": "Día del Trabajo",
        "happening_at": "01/05/2019"
      },
      "product": {
        "name": "Nombre"
      },
      "score_flow": {
        "name": "Nombre del Flujo de Calificación"
      },
      "user": {
        "email": "usuario@micorreo.com",
        "new_password": "*******",
        "password": "*******",
        "password_confirmation": "*******"
      },
      "webhook_presenter": {
        "callback_url": "https://www.example.com/payload",
        "name": "Nombre del Webhook"
      }
    },
    "prompt": {
      "accessory": {
        "calculation_base": "Base de cálculo",
        "calculation_type": "Tipo de cálculo",
        "payment_base": "Base de cobro"
      },
      "attachment": {
        "attachment_type": "Selecciona una opción"
      },
      "credit": {
        "product_id": "Selecciona un Producto"
      },
      "credit_application": {
        "attachment_type": "Selecciona un Tipo de archivo",
        "classification": "Selecciona un Tipo de Crédito",
        "flow": "Selecciona un Flujo",
        "product_id": "Selecciona un Producto"
      },
      "customer": {
        "attachment_type": "Selecciona un Tipo de archivo",
        "source": "Seleccione un opción"
      },
      "event": {
        "event_type": "Selecciona una opción"
      },
      "expense": {
        "classification": "Selecciona un Tipo de egreso"
      },
      "income": {
        "classification": "Selecciona un Tipo de ingreso"
      },
      "models": {
        "credit_presenter": {
          "attributes": {
            "product_id": "Selecciona un Producto"
          }
        }
      },
      "payment": {
        "payment_method": "Selecciona un método de pago"
      },
      "product": {
        "calculator_class": "Selecciona una Calculadora",
        "late_payment_base": "Selecciona una opción",
        "payment_periodicity": "Selecciona una opción",
        "score_flow_id": "Selecciona un Flujo de Calificación"
      },
      "product_accessory": {
        "accessory_id": "Selecciona un accesorio"
      },
      "reference": {
        "classification": "Selecciona un Tipo de referencia"
      },
      "webhook_presenter": {
        "field": "Selecciona un Campo"
      }
    },
    "submit": {
      "accessory": {
        "create": "Crear accesorio",
        "update": "Actualizar accesorio"
      },
      "catalog": {
        "create": "Crear catálogo",
        "update": "Editar catálogo"
      },
      "credit_application": {
        "update": "Actualizar solicitud"
      },
      "customer": {
        "create": "Crear cliente",
        "update": "Actualizar cliente"
      },
      "holiday": {
        "create": "Crear día feriado",
        "update": "Editar día feriado",
        "delete": "Eliminar",
        "edit": "Modificar",
        "new": "Nuevo día feriado"
      },
      "product": {
        "create": "Crear producto",
        "update": "Editar producto"
      },
      "score_flow": {
        "create": "Crear flujo",
        "update": "Actualizar flujo"
      },
      "user": {
        "create": "Invitar usuario",
        "update": "Actualizar usuario",
        "block": "Bloquear",
        "edit": "Modificar",
        "new": "Invitar usuario",
        "unblock": "Desbloquear"
      },
      "webhook": {
        "create": "Crear webhook",
        "update": "Actualizar webhook"
      },
      "password": {
        "update": "Cambiar contraseña"
      }
    }
  },
  "holidays": {
    "create": {
      "notice": "El día feriado ha sido guardado satisfactoriamente."
    },
    "edit": {
      "title": "Modificar día feriado"
    },
    "new": {
      "title": "Nuevo día feriado",
      "description": "Indique el día feriado para tomar en cuenta en los cálculos de fechas."
    },
    "update": {
      "notice": "El día feriado ha sido actualizado correctamente."
    },
    "index": {
      "description": "Una lista de todos los días feriados registrados, incluyendo el día celebre y descripción."
    }
  },
  "installment_plans": {
    "active_version": "Versión Activa",
    "inactive_version": "Versión Inactiva del %{date}"
  },
  "invites_mailer": {
    "invite_email": {
      "body_html": "¡Ya registramos este correo electrónico! Para empezar a usar la cuenta, por favor solo establece tu <br/> contraseña en el siguiente enlace.",
      "disclaimer_html": "Si tú o alguien de tu equipo de trabajo no solicitó este movimiento, por favor ignora este correo <br/> electrónico. <br/> Igualmente, si crees que recibiste este correo por error.",
      "link_text": "ESTABLECER CONTRASEÑA",
      "subject": "Te han invitado a que te conviertas en Administrador de una cuenta en Aoorora",
      "subject_html": "Te han invitado a que te conviertas en <b>Administrador</b> de una cuenta en Aoorora <br/>",
      "title": "¡Hola!"
    }
  },
  "logout": "Cerrar sesión",
  "manual_score_component_results": {
    "update": {
      "alert": "Uno de los Componentes de Calificación no cumplió las expectativas o se trata de un Componente Manual.<br /> Aquí las Notas: %{notes}",
      "invalid": "El Componente del Resultado no es del tipo Manual.",
      "notice": "Calificación obtenida satisfactoriamente."
    }
  },
  "menu": {
    "application": {
      "dropdown": {
        "api_key": "Llave de acceso",
        "change_password": "Cambiar contraseña",
        "crowdfunding_settings": "Configuración de Crowdfunding",
        "disable_2fa": "Desactivar 2FA",
        "disable_sandbox": "Desactivar entorno de pruebas",
        "enable_2fa": "Activar 2FA",
        "enable_sandbox": "Activar entorno de pruebas",
        "global_settings": "Configuración global",
        "holidays": "Días feriados",
        "logout": "Cerrar sesión",
        "nuke_sandbox": "Borrar datos de entorno de pruebas",
        "users": "Usuarios"
      },
      "sidebar": {
        "accessories": "Accesorios",
        "catalogs": "Catálogos",
        "credit_applications": "Solicitudes",
        "credits": "Creditos",
        "crowdfunds": "Campañas",
        "customers": "Clientes",
        "investors": "Inversionistas",
        "products": "Productos",
        "reports": "Reportes",
        "score_flows": "Flujos de calificación",
        "webhooks": "Webhooks"
      }
    }
  },
  "metadata": {
    "add": "Agregar",
    "attribute": "Atributo",
    "edit": "Editar metadatos",
    "metadata_title": "Metadatos",
    "remove": "Eliminar",
    "show": "Ver metadatos",
    "submit": "Guardar",
    "value": "Valor"
  },
  "nuke_sandbox": {
    "create": {
      "notice": "Datos eliminados de Entorno de pruebas"
    }
  },
  "otp": {
    "complete": {
      "show": {
        "copied_to_clipboard": "Copied to clipboard",
        "copy": "Copiar",
        "download": "Descargar",
        "information_html": "<p class=\"has-font-size-16 barlow-font has-text-prussian-blue m-b-20\">Es muy importante que guardes los códigos de recuperación que aparecen abajo. </br>Debido a que si pierdes o cambias de teléfono no podrás tener acceso a tu cuenta sin utilizar alguno de estos códigos.</p>\n<p class=\"has-font-size-16 barlow-font has-text-prussian-blue\"><b>Recuerda que puedes perder acceso a tu cuenta si no tienes estos códigos.</b></br>Por favor, guárdalos y mantenlos en un lugar seguro.</p>\n",
        "ok_i_saved_codes": "Ok, he guardado los códigos",
        "print": "Imprimir",
        "subtitle": "Paso 3",
        "title": "Configuración 2FA",
        "your_recovery_codes": "Tus códigos de recuperación"
      }
    },
    "configure": {
      "create": {
        "verification_code_is_invalid": "Código de verificación inválida"
      },
      "step1": {
        "configure_2fa": "Configurar 2FA",
        "information": "Two-factor authentication es un segundo paso a la hora de iniciar sesión que te ayuda a proteger tu cuenta.",
        "subtitle": "Two factor authentication",
        "title": "Seguridad"
      },
      "step2": {
        "cancel": "Olvídalo, regresemos",
        "configure_2fa": "Configurar 2FA",
        "info_step1": "Primero, deberías entrar con tu email y contraseña como siempre.",
        "info_step2": "Después, tu aplicación generá un código de seguridad único.",
        "info_step3": "Por último, tendrás que usar ese código de seguridad único para verificar que realmente eres tú.",
        "information_html": "<p>Cada vez que inicies sesión, <b>sera necesario utilizar código de seguridad único que genera la aplicación de tu télefono</b>. De esta manera, si alguien tiene tu contraseña, de todos modos no podrá accesar a tu cuenta porque no tiene el código.</p>\n<p>Configurar el 2FA toma soló unos minutos, pero no te preocupes. Te acompañaremos durante el proceso.</p>\n",
        "subtitle": "Cuando el Two-factor authentication (2FA) esté activado, tu inicio de sesión sera así:",
        "title": "Two-factor authentication"
      },
      "step3": {
        "accept": "Listo, tengo una APP",
        "cancel": "Regresar",
        "information_html": "<p class=\"has-font-size-16 has-text-prussian-blue barlow-font\">Para empezar, es necesario tener en tu télefono una aplicación de two-factor authentication, para generar los códigos de seguridad únicos cada que inicies sesión.</p>\n<p class=\"has-font-size-16 has-text-prussian-blue barlow-font\">Si ya utilizas Duo, 1Password o Goole Authenticator estás preparado.</p>\n<p class=\"has-font-size-16 has-text-prussian-blue barlow-font\">Si es la primera vez que utilizas 2FA, te recomendamos descargar una de las anteriores o utilizar la que más prefieras.</p>\n",
        "subtitle": "Paso 1",
        "title": "Configuración 2FA"
      },
      "step4": {
        "cancel": "Regresar",
        "information_html": "Ahora, escanea esta imagen con tu aplicación de autenticación y después ingresa el código que genere en el recuadro que aparece debajo de la imagen.\n",
        "second_information_html": "Código de autenticación de seis dígitos\n",
        "subtitle": "Paso 2",
        "title": "Configuración 2FA",
        "trouble_scanning_secret_html": "<p class=\"barlow-font has-text-prussian-blue has-font-size-16 m-b-20\">¿Problemas escaneado el código? Utiliza esta llave en tu aplicación:</p>\n<p class=\"barlow-font has-text-jelly-bean has-font-size-16 otp-secret-key\">%{secret}</p>\n",
        "validating": "Validando...",
        "verify_code": "Verificar y continuar"
      }
    },
    "disable": {
      "destroy": {
        "notice": "Se ha desactivado two-factor authentication sastifactoriamente"
      },
      "show": {
        "are_you_sure_disable_2fa": "¿Estás seguro que deseas deshabilitar el two-factor authentication?",
        "cancel": "Olvídalo, regresemos",
        "disable_2fa": "Sí, deshabilitar",
        "information_html": "Si el two-factor authentication se deshabilita, ya no será necesario ingresar el código generado en la aplicación de tu teléfono para iniciar sesión.\n</br>\nPor lo tanto, <b>tu cuenta volverá a tener seguridad estándar.</b>\n",
        "title": "Deshabilitar 2FA"
      }
    },
    "disable_2fa": "Desactivar 2FA",
    "enable_2fa": "Activar 2FA",
    "twofa_already_enabled": "(2FA) Two-factor authentication ya se encuentra activado",
    "twofa_not_enabled": "(2FA) Two-factor authentication no se encuentra activado",
    "verify": {
      "create": {
        "verification_code_is_invalid": "Código de verificación inválida"
      },
      "new": {
        "code": "Código de verificación",
        "information": "Ingresa el código de seguridad generado por la aplicación de autenticación de tu teléfono para verificar tu identidad.",
        "subtitle": "Verificación 2FA",
        "title": "Two-factor authentication",
        "validating": "Validando...",
        "verify": "Verificar"
      }
    }
  },
  "passwords": {
    "edit": {
      "description": "Su nueva contraseña debe tener un mínimo de 8 caracteres e incluir, al menos, una mayúscula, minúscula, un número y un caracter especial.",
      "title": "Cambiar contraseña"
    },
    "update": {
      "alert": "No se pudo actualizar la contraseña, inténtalo de nuevamente.",
      "notice": "Tú contraseña ha sido actualizada"
    }
  },
  "payment_modal": {
    "forgive_penalty_interest": "Condonar intereses moratorios",
    "installments_number": "Número de plazos",
    "payment_type": {
      "annuity": "Pago a la parcialidad de",
      "balance": "Pago del saldo pendiente:",
      "ending_balance": "Pago al saldo insoluto de %{amount}",
      "minimum_amount": "Pago minimo de:",
      "partial": "Pago parcial",
      "zero_amount": "Acumular la totalidad del pago para la siguiente parcialidad"
    },
    "placeholder_prepaid_types": "Seleccione un tipo de pago adelantado",
    "submit": "Confirmar pago",
    "title": "Capturar pago",
    "total_penalty_interest": "Intereses moratorios más IVA"
  },
  "privileges": {
    "admin": "Administrador",
    "analyst": "Analista",
    "collection": "Cobranza",
    "customer": "Promotor/Cliente",
    "owner": "Dueño",
    "product": "Producto"
  },
  "products": {
    "accessories": {
      "accessory_submit": "Asignar accessorio"
    },
    "contract_template": {
      "available_fields": "Lista de Campos Disponibles",
      "contract_template_submit": "Guardar plantilla del contrato",
      "fields_hint": "Recuerda que para usarlos, debes poner el nombre del campo dentro de %{}",
      "no_contract_template": "No hay Plantilla del Contrato creada todavía. Puedes crearla al editar el Producto."
    },
    "create": {
      "notice": "El Producto se ha creado satisfactoriamente."
    },
    "edit": {
      "product_needs_calculator": "Para poder activar el Producto necesitas asignarle una Calculadora de Interés.",
      "product_needs_contract_template": "Para poder activar el Producto necesitas crear la Plantilla del Contrato.",
      "product_needs_score_flow": "Para poder activar el Producto necesitas asignarle un Flujo de Calificación.",
      "title": "Producto %{short_name}"
    },
    "form": {
      "configuration": "Configuración",
      "first": "Primero",
      "fourth": "Cuarto",
      "general_information": "Información general",
      "second": "Segundo",
      "third": "Tercero",
      "values": "Valores"
    },
    "late_payment_base": {
      "ending_balance": "Saldo Insoluto",
      "principal": "Capital"
    },
    "markdown_hints_modal": {
      "title": "Sintaxis de Markdown"
    },
    "minimum_payment": {
      "amount_types": {
        "approved_amount": "Límite de crédito",
        "total_payment": "Saldo deudor"
      },
      "errors": {
        "amounts": {
          "invalid": "Monto mínimo deber ser menor que el Monto máximo y no deben estar vaciós"
        },
        "percentage": {
          "invalid": "Porcentaje debe ser mayor a cero"
        }
      },
      "label": "Pago mínimo",
      "maximum_amount": "Monto máximo",
      "minimum_amount": "Monto mínimo",
      "percentage": "Porcentaje"
    },
    "new": {
      "title": "Nuevo Producto"
    },
    "score_flow": {
      "no_score_flow": "No has asignado un Flujo de Calificación al Producto.",
      "save_score_flow": "Guardar Flujo de Calificación",
      "see_score_flow": "Ver Flujo de Calificación"
    },
    "show": {
      "title": "Producto %{short_name}"
    },
    "update": {
      "notice": "El Producto ha sido actualizado correctamente."
    }
  },
  "rejected_applications": {
    "new": {
      "submit": "Rechazar",
      "title": "Rechazar solicitud"
    }
  },
  "reports": {
    "cancel": "Cancelar",
    "error": "No hay columnas que exportar",
    "export": "Exportar",
    "export_to_csv_file": "Exportar a un archivo CSV",
    "select_fields_to_include_in_csv_file": "Selecciona los campos que deseas incluir en tu archivo CSV:",
    "title": "Reportes"
  },
  "sandbox": {
    "alert": "Entorno de pruebas activado",
    "label": {
      "disable": "Desactivar entorno de pruebas",
      "enable": "Activar entorno de pruebas"
    },
    "nuke": "Borrar datos de Entorno de pruebas"
  },
  "score_components": {
    "balance": {
      "title": "Calificador de balance"
    },
    "buro": {
      "title": "Calificador de buró"
    },
    "conditional": {
      "title": "Calificador condicional"
    },
    "delete_component_alert": "No puedes eliminar un Componente cuyo Flujo de Calificacion ha sido ejecutado en una Solicitud.",
    "fields": {
      "placeholder_application_fields": "Seleccione uno o más",
      "placeholder_customer_fields": "Seleccione uno o más",
      "title": "Calificador de campos"
    },
    "incomplete": "El %{component} necesita ser completado en el flujo de calificación",
    "manual": {
      "title": "Calificador manual"
    }
  },
  "score_flow_result": "Calificación",
  "score_flow_results": {
    "create": {
      "alert": "Existe uno o más Componentes en el Flujo de Calificación que necesitan atención. Consulta la sección de Calificación.",
      "notice": "Calificación obtenida satisfactoriamente."
    }
  },
  "score_flows": {
    "create": {
      "alert": "No se pudo crear el Flujo de Calificación",
      "notice": "Flujo de calificación ha sido creado correctamente."
    },
    "edit": {
      "title": "Actualizar Flujo de Calificación"
    },
    "form": {
      "formula": "C1 + C2 - (C3 * C4) / 100",
      "name": "Ej. Calificación de solicitudes"
    },
    "formula_help": {
      "headers": {
        "component": "Componente",
        "factor": "Factor",
        "variable": "Variable"
      },
      "help_part_one_html": "Para escribir una fórmula, es necesario utilizar la siguiente lista de variables por cada uno de\nlos componentes de tu flujo de calificación y también los operadores aritméticos permitidos para definir la fórmula.\n\n<h4 class=\"mb-4 mt-4\">Operaciones aritméticas permitidas</h4>\n<p><b>+</b> Sumar</p>\n<p><b>-</b> Restar</p>\n<p><b>*</b> Multiplicar</p>\n<p class=\"mb-4\"><b>/</b> Dividir</p>\n",
      "help_part_second_html": "<h4 class=\"mb-4\">Ejemplo:</h4>\n<pre class=\"mb-4\">\n  C1 + C2 - (C3 * C4) / 100\n</pre>\n<p>Nota: Puedes utilizar los parentesis <code>()</code> para definir el orden de la operación en la fórmula.</p>\n",
      "title": "Ayuda",
      "title_body": "¿Cómo escribo una fórmula para calcular la puntuación?"
    },
    "new": {
      "title": "Nuevo Flujo de Calificación"
    },
    "score_qualifiers": {
      "conditional": {
        "failure_notes": "Algunos campos no cumplieron con la condición. Del Cliente: %{customer_fields}. De la Solicitud: %{application_fields}."
      },
      "fields": {
        "failure_notes": "Algunos campos hicieron falta. Del Cliente: %{customer_fields}. De la Solicitud: %{application_fields}."
      }
    },
    "show": {
      "title": "Flujo de Calificación"
    },
    "update": {
      "alert": "No se pudo actualizar el Flujo de Calificación",
      "notice": "Flujo de Calificación actualizado correctamente."
    }
  },
  "score_qualifiers": {
    "conditional": {
      "add": "Agregar",
      "application_fields": "Campos de Solicitud",
      "condition": "Condición",
      "customer_fields": "Campos de Cliente",
      "fields": "Campo",
      "fields_added": "Campos agregados",
      "submit": "Guardar",
      "value": "Valor"
    }
  },
  "shared": {
    "modals": {
      "accessory_modal": {
        "added_at": "Añadido el",
        "calculate_iva": "Calcular IVA",
        "calculation_base": "Base de cálculo",
        "conditions": "Condiciones",
        "days_offset": "Días de gracia",
        "fixed_value": "Valor",
        "is_active": "Activo",
        "percentage": "Porcentaje"
      },
      "webhook_tracker_modal": {
        "created_at": "Fecha de creación",
        "payload": "Parámetros",
        "response": "Respuesta",
        "status_code": "Estado",
        "target_type": "Modelo",
        "target_uuid": "ID del modelo"
      }
    },
    "search_bar": {
      "placeholder": "Buscar en %{resource}..."
    }
  },
  "support": {
    "array": {
      "last_word_connector": " y ",
      "two_words_connector": " y ",
      "words_connector": ", "
    }
  },
  "users": {
    "create": {
      "alert": "El correo electrónico ya ha sido invitado",
      "notice": "Se ha enviado la invitación al correo: %{email}"
    },
    "edit": {
      "cancel": "Ir atrás",
      "submit": "Guardar",
      "title": "Editar Usuario"
    },
    "index": {
      "create": "Crear e Invitar Usuario",
      "description": "Una lista de todos los usuarios de su cuenta, incluyendo su nombre, cargo, correo electrónico y rol."
    },
    "new": {
      "cancel": "Ir atrás",
      "description": "Al invitar un usuario se envía un correo electrónico para que acepte la invitación.",
      "email": "Correo electrónico del nuevo Usuario",
      "send": "Enviar Invitación",
      "title": "Invitar a Usuario"
    },
    "passwords": {
      "edit": {
        "description": "Para su nueva contraseña elija una combinación de letras, números y símbolos. No utilice fechas de algún evento importante, nombres o frases que sean fáciles de adivinar.",
        "sign_in": "Iniciar sesión",
        "submit": "Restablecer",
        "title": "Restablecer contraseña"
      },
      "new": {
        "description": "Introduce tu correo electrónico y recibirás ahí la información de cómo restablecer tu contraseña.",
        "email_placeholder": "usuario@micorreo.com",
        "sign_in": "Iniciar sesión",
        "submit": "Enviar instrucciones",
        "title": "Restablecer contraseña"
      }
    },
    "sessions": {
      "new": {
        "description": "Inicia tu sesión",
        "forgot_your_password": "Olvidé mi contraseña",
        "inactive_account": "Tu cuenta se encuentra desactivada.",
        "submit": "Iniciar sesión",
        "title": "Iniciar sesión"
      }
    },
    "update": {
      "notice": "Se ha actualizado el Usuario correctamente."
    },
    "user": {
      "block": "Bloquear Usuario",
      "block_status": {
        "one": "Bloqueado",
        "two": "Activo"
      },
      "confirm": "¿Estás seguro?",
      "disabling": "Cargando...",
      "edit_link": "Editar",
      "edit_success": "El Usuario ha sido actualizado satisfactoriamente.",
      "no_actions": "No hay acciones disponibles para este usuario.",
      "resend_invite": "Reenviar Invitación",
      "unblock": "Desbloquear Usuario"
    },
    "attributes": {
      "roles": {
        "admin": "Administrador",
        "analyst": "Analista",
        "collection": "Cobranza",
        "customer": "Promotor/Cliente",
        "owner": "Dueño",
        "product": "Producto"
      }
    }
  },
  "webhooks": {
    "accordions": {
      "attribute_default": "attributo",
      "attribute_value_default": "El valor del attributo del registro.",
      "event_type_default_html": "El attributo del campo seleccionado junto con el tipo del evento, e.g. <code>attributo_updated</code>.",
      "help_to_you_decryption_note": "Nota: Para descifrar el mensaje es necesario utilizar Base64 y utilizar como firma la llave de acceso a la API para así obtener el mensaje del payload ya descifrado.\n",
      "help_to_you_html": "El Webhook realiza una petición HTTPS/POST a la URL del callback a tu servidor, enviando el payload en formato application/json. Como dato adicional, en el payload incluimos el atributo del campo seleccionado y su valor al momento de que se ejecuta el Webhook. Por seguridad, el payload es enviado con un cifrado utilizando Base64 firmado con la llave de acceso que se utiliza para consumir la API de creditar.io. El mensaje ya cifrado es enviado en el siguiente ejemplo:\n",
      "model_default": "El modelo del campo seleccionado.",
      "record_uuid": "Identificador unico del registro.",
      "title": "¿Cómo envía el Webhook el payload al URL del callback?",
      "webhook_id_default": "Identificador del Webhook."
    },
    "create": {
      "alert": "No se pudo crear el Webhook.",
      "notice": "El Webhook se ha creado correctamente."
    },
    "destroy": {
      "notice": "El Webhook ha sido eliminado correctamente."
    },
    "edit": {
      "title": "Editar Webhook"
    },
    "new": {
      "title": "Nuevo Webhook"
    },
    "update": {
      "alert": "No se pudo actualizar el Webhook.",
      "notice": "El Webhook ha sido actualizado correctamente."
    }
  },
  "write_off_modal": {
    "blank": "Ningún campo puede estar vacío",
    "invalid_password": "Constraseña incorrecta",
    "record_not_found": "Crédito no encontrado",
    "write_off": "Incobrable"
  },
  "confirmations": {
    "holiday": {
      "delete": "¿Está seguro de querer eliminar este día feriado?"
    },
    "user": {
      "block": "¿Está seguro que quiere bloquear a este usuario?",
      "unblock": "¿Está seguro que quiere desbloquear a este usuario?"
    }
  },
  "maxlength": {
    "default": 35,
    "holiday": {
      "happening_at": 10
    },
    "user": {
      "email": 45
    }
  },
  "notifications": {
    "alert": "Ha ocurrido un error al realizar la operación, revise el formulario y corrija el problema."
  },
  "search": {
    "placeholder": "Buscar en %{resource}"
  },
  "api_keys": {
    "show": {
      "cancel": "Cancelar",
      "copied": "Copiado",
      "copy": "Copiar llave",
      "description": "Esta llave proporciona acceso de otras aplicaciones a la información de créditos. No la compartas a desconocidos. Regenera la llave de forma periódica aquí.",
      "label": "API Key",
      "regenerate": "Generar nueva llave",
      "title": "Llave de acceso al API"
    },
    "update": {
      "notice": "La llave de acceso ha sido re-generada satisfactoriamente."
    }
  },
  "index": {
    "filters": {
      "boolean": {
        "no": "No",
        "yes": "Sí"
      },
      "clear": "Limpiar",
      "count": {
        "one": "1 Filtro",
        "other": "%{count} Filtros"
      },
      "datetime": {
        "last_15_days": "Desde hace 15 días",
        "last_3_months": "Desde hace 3 meses",
        "last_6_months": "Desde hace 6 meses",
        "last_7_days": "Desde hace 7 días",
        "last_month": "Desde hace 1 mes",
        "last_year": "Desde hace 1 año"
      },
      "submit": "Aplicar filtros"
    },
    "table": {
      "actions": "Acciones"
    }
  },
  "pagination": {
    "next": "Siguiente",
    "previous": "Anterior",
    "records": "Mostrando del %{from} al %{to} de %{count} resultados"
  },
  "roles": {
    "admin": {
      "description": "Configuración de catalogos, vista de reportes, acceso a la llave de la API",
      "label": "Administrador"
    },
    "analyst": {
      "description": "Seguimiento a los flujos de calificación y aprobación de solicitudes",
      "label": "Analista"
    },
    "collection": {
      "description": "Tablas de amortización, seguimiento de pagos, consulta de reportes",
      "label": "Cobranza"
    },
    "customer": {
      "description": "Registro de perfil de cliente y apertura de una solicitud",
      "label": "Promotor/Cliente"
    },
    "owner": {
      "description": "Some description",
      "label": "Dueño"
    },
    "product": {
      "description": "Configurar productos financieros",
      "label": "Producto"
    }
  }
},
  "en": {
  "date": {
    "formats": {
      "default": "%B %d, %Y",
      "short": "%b %d",
      "long": "%B %d, %Y"
    },
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "abbr_month_names": [
      null,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    "order": [
      "year",
      "month",
      "day"
    ]
  },
  "time": {
    "formats": {
      "default": "%B %d, %Y %I:%M %P",
      "short": "%d %b %H:%M",
      "long": "%B %d, %Y %H:%M",
      "day_first": "%d of %B from %Y to %I:%M %P"
    },
    "am": "A.M",
    "pm": "p.m"
  },
  "support": {
    "array": {
      "words_connector": ",",
      "two_words_connector": "and",
      "last_word_connector": "and"
    }
  },
  "number": {
    "format": {
      "separator": ".",
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "currency": {
      "format": {
        "format": "%u%n",
        "unit": "$",
        "separator": ".",
        "delimiter": ",",
        "precision": 2,
        "significant": false,
        "strip_insignificant_zeros": false
      }
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    },
    "human": {
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "kb": "KB",
          "mb": "MB",
          "gb": "GB",
          "tb": "TB",
          "pb": "PB",
          "eb": "EB"
        }
      },
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "unit": "",
          "thousand": "Thousand",
          "million": "Million",
          "billion": "Billion",
          "trillion": "Trillion",
          "quadrillion": "Quadrillion"
        }
      }
    },
    "nth": {
      "ordinals": "#<Proc:0x0000000138850f00 /Users/dabit/.rbenv/versions/3.2.0/lib/ruby/gems/3.2.0/gems/activesupport-7.0.4.1/lib/active_support/locale/en.rb:7 (lambda)>",
      "ordinalized": "#<Proc:0x0000000138850ed8 /Users/dabit/.rbenv/versions/3.2.0/lib/ruby/gems/3.2.0/gems/activesupport-7.0.4.1/lib/active_support/locale/en.rb:26 (lambda)>"
    }
  },
  "errors": {
    "format": "%{attribute} %{message}",
    "messages": {
      "model_invalid": "Validation failed: %{errors}",
      "inclusion": "is not included in the list",
      "exclusion": "is reserved",
      "invalid": "is invalid",
      "confirmation": "doesn't match %{attribute}",
      "accepted": "must be accepted",
      "empty": "can't be empty",
      "blank": "can't be blank",
      "present": "must be blank",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      },
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "equal_to": "must be equal to %{count}",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "other_than": "must be other than %{count}",
      "in": "must be in %{count}",
      "odd": "must be odd",
      "even": "must be even",
      "required": "must exist",
      "taken": "has already been taken",
      "invalid_currency": "has invalid format (must be '100', '5%{decimal}24', or '123%{thousands}456%{decimal}78'). Got %{currency}",
      "already_confirmed": "was already confirmed, please try signing in",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "expired": "has expired, please request a new one",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "taken_in_past": "was used previously.",
      "equal_to_current_password": "must be different than the current password.",
      "equal_to_email": "must be different than the email.",
      "password_complexity": {
        "digit": {
          "one": "must contain at least one digit",
          "other": "must contain at least %{count} digits"
        },
        "lower": {
          "one": "must contain at least one lower-case letter",
          "other": "must contain at least %{count} lower-case letters"
        },
        "symbol": {
          "one": "must contain at least one punctuation mark or symbol",
          "other": "must contain at least %{count} punctuation marks or symbols"
        },
        "upper": {
          "one": "must contain at least one upper-case letter",
          "other": "must contain at least %{count} upper-case letters"
        }
      }
    },
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n",
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n"
  },
  "activerecord": {
    "errors": {
      "messages": {
        "record_invalid": "Validation failed: %{errors}",
        "restrict_dependent_destroy": {
          "has_one": "Cannot delete record because a dependent %{record} exists",
          "has_many": "Cannot delete record because dependent %{record} exist"
        }
      },
      "models": {
        "accessory": {
          "attributes": {
            "active": {
              "already_used": "The accessory is being used by a product"
            },
            "calculation_type": {
              "blank": "The calculation type cannot be empty.",
              "name": "The calculation type cannot be empty."
            },
            "days_offset": {
              "blank": "The grace days value cannot be empty.",
              "greater_than_or_equal_to": "Grace days must be greater than or equal to 0.",
              "not_a_number": "The value of grace days is not correct."
            },
            "fixed_value": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "name": {
              "blank": "The name cannot be empty."
            },
            "payment_base": {
              "blank": "Select a charging base"
            },
            "value": {
              "blank": "The accessory value cannot be empty.",
              "not_a_number": "The value of the accessory is not correct.",
              "not_an_integer": "The value of the accessory is not correct."
            }
          }
        },
        "accessory_applicable": {
          "attributes": {
            "description": {
              "blank": "Description cannot be empty."
            },
            "fixed_value": {
              "less_than": "The amount must be less than the outstanding balance.",
              "less_than_ending_balance": "The amount must be less than the outstanding balance.",
              "less_than_interest": "The amount must be less than the net interest.",
              "less_than_or_equal_to": "Amount exceeds the allowed limit",
              "negative_amount": "The amount must be negative to apply credit adjustment.",
              "negative_less_than": "The amount must be negative and less than the outstanding balance."
            },
            "payment_base": {
              "blank": "Select a setting type",
              "credit_adjustment_invalid": "The setting type is invalid.",
              "quita_taken": "There is already a setting with Quita"
            }
          }
        },
        "accessory_result": {
          "attributes": {
            "value": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            }
          }
        },
        "attachment": {
          "attributes": {
            "attachment_type": {
              "blank": "File type cannot be empty"
            }
          }
        },
        "catalog": {
          "attributes": {
            "field": {
              "invalid": "Invalid format, check that it is correct."
            },
            "name": {
              "blank": "Name cannot be empty."
            },
            "value": {
              "blank": "Value cannot be empty.",
              "taken": "There is already a catalog with that value."
            }
          }
        },
        "contract_template": {
          "attributes": {
            "body": {
              "blank": "The contract template must have a description."
            },
            "title": {
              "blank": "The contract template must have a title."
            }
          }
        },
        "credit_application": {
          "attributes": {
            "accepted_at": {
              "invalid": "The Acceptance Date is not in a valid format",
              "less_than_or_equal_to": "The Acceptance Date must be less than or equal to the Initial Payment Date.",
              "state_invalid": "The request cannot be accepted when it has not been approved"
            },
            "amount": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit",
              "not_a_number": "The amount entered is not valid."
            },
            "approved_amount": {
              "amount_not_allowed": "Maximum amount depleted",
              "blank": "You must enter an approved amount",
              "credit_not_allowed": "Maximum credits exhausted",
              "less_than": "The approved amount cannot be greater than the requested amount",
              "less_than_or_equal_to": "Amount exceeds the allowed limit",
              "not_a_number": "You must enter an approved amount"
            },
            "approved_amount_cents": {
              "blank": "You must enter an approved amount",
              "greater_than": "The approved amount cannot be greater than the requested amount",
              "less_than": "The approved amount cannot be greater than the requested amount",
              "not_a_number": "You must enter an approved amount"
            },
            "approved_at": {
              "invalid": "It is necessary that the application has been qualified and with all its components approved in order to be approved"
            },
            "base": {
              "cant_create_contract": "There are problems with the assigned product contract template",
              "contract_template_missing": "It is necessary to complete the contract template of the product assigned to the request"
            },
            "clabe": {
              "blank": "The CLABE cannot be empty.",
              "format": "The CLABE is invalid."
            },
            "closing_date_at": {
              "blank": "Cut-off date must not be empty",
              "greater_than": "The cut-off date must be greater than the current date"
            },
            "credit": {
              "cant_be_updated": "The Application cannot be modified as it has an associated Credit."
            },
            "first_payment_date": {
              "blank": "The Initial Payment Date cannot be empty.",
              "greater_than_or_equal_to": "The Initial Payment Date must be a current or future date."
            },
            "installments": {
              "greater_than": "The number of Terms must be greater than zero.",
              "not_a_number": "You must enter a whole number for Installments."
            },
            "maximum_penalty_days": {
              "greater_than_or_equal_to": "Invalid value, must be greater than or equal to 0",
              "not_a_number": "You must enter a numeric value"
            },
            "payment_days": {
              "blank": "Payment days must not be empty",
              "greater_than": "Pay days must be greater than zero"
            },
            "product_id": {
              "amount_range": "The requested amount is outside the range allowed by the Product.",
              "blank": "You need to select a Product.",
              "cant_be_changed": "You cannot change the Product of the Request.",
              "cant_enable_crowdfunding": "It is not possible to use this product, please contact the email support@creditar.io",
              "inactive": "The Product needs to be active.",
              "installments_number": "The number of Terms does not correspond to that of the Product.",
              "installments_range": "The number of Terms is outside the range allowed by the Product."
            },
            "rejected": {
              "rejected_notes": "The request expired after %{days_count} days."
            },
            "rejected_notes": {
              "blank": "Reason for rejection must not be empty"
            }
          }
        },
        "credit_history": {
          "attributes": {
            "document": {
              "blank": "Document cannot be empty."
            }
          }
        },
        "crowdfunding/fund": {
          "attributes": {
            "amount": {
              "article_forty_nine": "The investor exceeds the amount of funding allowed",
              "rule_i_ii_invalid": "The investor exceeds the amount of funding allowed",
              "rule_iii_invalid": "The investor exceeds the amount of funding allowed",
              "rule_iv_invalid": "The investor exceeds the amount of funding allowed"
            }
          }
        },
        "crowdfunding/investor": {
          "attributes": {
            "signed_contract_at": {
              "different_than": "The contract signing date must be current date"
            }
          }
        },
        "crowdfunding/movement": {
          "attributes": {
            "reference": {
              "taken": "A reference already exists"
            },
            "tracking_number": {
              "taken": "Tracking key already exists"
            }
          }
        },
        "customer": {
          "attributes": {
            "base": {
              "product_id": "The Customer must have an active Product."
            },
            "blocked": {
              "blocked": "The Client is blocked."
            },
            "date_of_birth": {
              "invalid": "The Date of Birth does not have a valid date format.",
              "not_a_date": "Date of birth cannot be empty"
            },
            "email": {
              "blank": "The mail cannot be empty.",
              "invalid": "The email is not in a valid format.",
              "taken": "There is already a client with that email."
            }
          }
        },
        "event": {
          "attributes": {
            "event_type": {
              "blank": "Select an Event Type"
            },
            "subject": {
              "blank": "The Event title cannot be empty."
            }
          }
        },
        "expense": {
          "attributes": {
            "amount": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "classification": {
              "blank": "You need to select a type of expense"
            }
          }
        },
        "global_setting": {
          "attributes": {
            "maximum_amount_allowed": {
              "greater_than_or_equal_to": "The amount is not valid, it must be greater than or equal to 0",
              "not_a_number": "The amount can't be empty"
            },
            "maximum_credit_allowed": {
              "greater_than_or_equal_to": "The amount of credits is not valid, it must be greater than or equal to 0",
              "not_a_number": "The amount of credits can't be empty"
            }
          }
        },
        "holiday": {
          "attributes": {
            "happening_at": {
              "blank": "The date cannot be empty.",
              "inclusion": "The date has to be in the future.",
              "taken": "The holiday already exists."
            }
          }
        },
        "income": {
          "attributes": {
            "amount": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "classification": {
              "blank": "You need to select a type of income"
            }
          }
        },
        "installment": {
          "attributes": {
            "accessories_amount": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "ending_balance": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "interest": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "missing_amount": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "penalty_interest": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "penalty_interest_tax": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "principal": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "total_payment": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            }
          }
        },
        "movement": {
          "attributes": {
            "amount": {
              "blank": "The amount must not be empty",
              "greater_than": "Enter an amount greater than zero",
              "less_than": "Credit without sufficient balance",
              "less_than_or_equal_to": "Amount exceeds the allowed limit",
              "not_a_number": "The amount must not be empty"
            },
            "movement_type": {
              "blank": "Select a type of movement",
              "invalid": "The type of movement is invalid"
            },
            "reference": {
              "blank": "Reference must not be empty"
            }
          }
        },
        "payment": {
          "attributes": {
            "amount": {
              "equal_to_ending_balance": "The amount must be equal to the unpaid balance",
              "greater_than": "The amount must be greater than zero",
              "greater_than_total_payment": "The amount must be greater than the annuity payment",
              "less_than_ending_balance": "The amount must be less than the unpaid balance",
              "less_than_or_equal_to": "Amount exceeds the allowed limit"
            },
            "payment_method": {
              "blank": "Select a payment method"
            },
            "prepaid_type": {
              "blank": "Select an advance payment type.",
              "not_allow_prepaid": "Advance payments are not allowed.",
              "invalid_enum": "%Invalid {value}"
            },
            "reduced_installments_number": {
              "less_than": "The number of installments must be less than the number of pending installments or greater than one installment"
            },
            "status": {
              "not_cancelable": "It is not possible to cancel the payment because it is not the most recent."
            },
            "tracking_number": {
              "taken": "Tracking key already exists"
            }
          }
        },
        "product": {
          "attributes": {
            "active": {
              "crowdfunding_deactivate": "You cannot deactivate a product with fundraising requests.",
              "deactivate": "You cannot deactivate a product with requests already approved."
            },
            "base": {
              "inactive": "You cannot select an inactive product."
            },
            "calculator_class": {
              "blank": "Interest calculator cannot be empty."
            },
            "contract_template": {
              "blank": "The product must have a contract template."
            },
            "crowdfunding": {
              "amortization_system_invalid": "For an amortization system with a line of credit it is invalid.",
              "cant_be_updated": "It is not possible to enable fundraising."
            },
            "crowdfunding_type": {
              "blank": "Select a type of crowdfunding operation",
              "inclusion": "Invalid type of crowdfunding operation"
            },
            "default_amount": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit",
              "not_a_number": "The amount of the default amount is not correct."
            },
            "grace_days_interest": {
              "less_than_or_equal_to": "Grace days for interest collection must be less than or equal to 7."
            },
            "interest_rate_days": {
              "greater_than": "Interest rate days must be greater than 0.",
              "less_than_or_equal_to": "Interest rate days must be less than or equal to 365."
            },
            "late_payment_base": {
              "blank": "Basis for calculating penalty."
            },
            "maximum_amount": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit",
              "not_a_number": "The amount of the maximum amount is not correct."
            },
            "minimum_amount": {
              "less_than_or_equal_to": "Amount exceeds the allowed limit",
              "not_a_number": "The amount of the minimum amount is not correct."
            },
            "name": {
              "blank": "The name cannot be empty."
            },
            "payment_periodicity": {
              "less_than_or_equal_to": "The periodicity must be less than or equal to 365.",
              "not_a_number": "You must select a payment periodicity."
            },
            "score_flow": {
              "cant_be_updated": "You cannot change the Qualification Flow because there are Requests in &quot;Qualifying&quot; status that have the current Qualification Flow"
            },
            "short_name": {
              "blank": "The short name cannot be empty."
            }
          }
        },
        "product_accessory": {
          "attributes": {
            "accessory": {
              "required": "You must select an Accessory."
            },
            "accessory_id": {
              "invalid": "The Accessory must be active."
            },
            "product_id": {
              "invalid": "To add an Accessory it is necessary that the Product is deactivated."
            }
          }
        },
        "promotion": {
          "attributes": {
            "total_installments": {
              "blank": "The number of terms cannot be empty"
            }
          }
        },
        "score_component": {
          "attributes": {
            "score_flow_id": {
              "locked": "You cannot update because the Rating Flow is blocked."
            },
            "settings": {
              "min_amount_cents": "The minimum quantity must be less than the maximum quantity."
            }
          }
        },
        "score_flow": {
          "attributes": {
            "formula": {
              "invalid": "Formula is invalid.",
              "score_components_empty": "There is no Rating Component to define a formula."
            },
            "name": {
              "blank": "Name cannot be empty."
            }
          }
        },
        "user": {
          "attributes": {
            "current_password": {
              "invalid": "Invalid current password"
            },
            "email": {
              "blank": "The mail cannot be empty.",
              "invalid": "The email is not in a valid format."
            },
            "old_password": {
              "blank": "Current password cannot be empty",
              "invalid": "Incorrect current password"
            },
            "password": {
              "blank": "Password cannot be empty",
              "too_short": "The password is too short, enter at least 8 characters"
            },
            "password_confirmation": {
              "confirmation": "Password confirmation is not the same as the new password",
              "invalid": "Password confirmation is not the same as the new password"
            }
          }
        },
        "webhook": {
          "attributes": {
            "callback_url": {
              "blank": "Callback URL cannot be empty.",
              "invalid": "Callback URL must be HTTPS."
            },
            "description": {
              "blank": "Description cannot be empty."
            },
            "field": {
              "blank": "Field cannot be empty.",
              "invalid": "The format of the Field is invalid."
            },
            "name": {
              "blank": "Name cannot be empty."
            }
          }
        }
      }
    },
    "models": {
      "no_password/session": "Session",
      "accessory": "Accessory",
      "accessory_result": "Accessories result",
      "attachment": "Records",
      "catalog": "Catalog",
      "contract": "Contract",
      "contract_template": "Contract Template",
      "credit": "Credit",
      "credit_application": "Application",
      "credit_history": "Credit History",
      "customer": "Customer",
      "event": "Event",
      "expense": "Spending",
      "global_setting": "Global settings",
      "holiday": {
        "one": "Holiday",
        "other": "Holidays"
      },
      "income": "Entry",
      "installment": "Term",
      "installment_plan": "Amortization Table",
      "movement": "Movement",
      "payment": "Payment",
      "product": "Product",
      "promotion": "Promotion",
      "reference": "Reference",
      "report": "Report",
      "score_component": "Rating Component",
      "score_component_result": {
        "one": "Rating Component Result",
        "other": "Qualification"
      },
      "score_flow": "Qualification Flow",
      "user": {
        "one": "User",
        "other": "Users"
      },
      "webhook": {
        "one": "Webhook",
        "other": "Webhooks"
      }
    },
    "attributes": {
      "no_password/session": {
        "email": "Your email"
      },
      "accessory": {
        "active": "Active",
        "archived": "Archived",
        "calculate_iva": "Calculate VAT",
        "calculation_base": "Calculation base",
        "calculation_type": "Calculation type",
        "created_at": "Creation date",
        "days_offset": "Grace days",
        "description": "Description",
        "fixed_value": "Value",
        "name": "Name",
        "payment_base": "Charge basis",
        "percentage": "Percentage",
        "settings": "Terms",
        "updated_at": "Last update"
      },
      "accessory_applicable": {
        "calculate_iva": "Calculate VAT"
      },
      "accessory_result": {
        "status": "State",
        "triggers_at": "Activation date",
        "value_cents": "Amount"
      },
      "attachment": {
        "attachment_type": "Type of file",
        "created_at": "Creation date",
        "file": "File"
      },
      "catalog": {
        "active": "Active",
        "field": "Field",
        "name": "Label",
        "value": "Value"
      },
      "contract": {
        "body": "Content",
        "title": "Qualification"
      },
      "contract_template": {
        "body": "Content",
        "title": "Qualification"
      },
      "credit": {
        "accepted_at": "Accepted on",
        "amount": "Amount requested",
        "amount_cents": "Amount requested",
        "application_number": "Credit number",
        "approved_amount": "Amount approved",
        "approved_amount_cents": "Amount approved",
        "approved_at": "Approved on",
        "attachment_type": "Type of file",
        "average_daily_balance": "Average daily balance",
        "charges_amount": "Total charges",
        "charges_promotion_informative_amount": "Total promotion charges",
        "city_address": "Town",
        "clabe": "CLABE",
        "classification": "Type of credit",
        "closing_date": "Cutoff date",
        "closing_date_day": "Cut-off date days",
        "created_at": "Creation date",
        "credit_type": "Type of credit",
        "customer": "Customer",
        "description": "Description",
        "ending_balance": {
          "one": "Outstanding balance",
          "zero": "Available balance"
        },
        "ending_balance_cents": "Outstanding balance",
        "exterior_number_address": "Outdoor Number",
        "first_payment_date": "Initial payment date",
        "flow": "Flow",
        "home_phone": "Home phone",
        "installments": "Deadlines",
        "installments_state": "Credit status",
        "interest_rate": "Interest",
        "interior_number_address": "Interior number",
        "late_payment_interest": "Interest on arrears",
        "metadata": "Metadata",
        "minimum_payment_amount": "Minimum pay",
        "mobile_phone": "Cell phone",
        "neighborhood_address": "Suburb",
        "outstanding_balance_previous": "Previous balance",
        "password_confirmation": "Confirm Password",
        "payment_due_limit_date": "Payday limit",
        "payments_amount": "Total fertilizers",
        "period_balance_amount": "Balance of the period",
        "period_commissions": "Commissions for the period",
        "period_commissions_tax": "Commission VAT for the period",
        "period_days": "Period days",
        "period_interests": "Interest for the period",
        "period_interests_tax": "Interest VAT for the period",
        "product": "Product",
        "product_id": "Product",
        "rejected_at": "Rejected at",
        "rejected_notes": "Reason for rejection",
        "state": "Credit status",
        "state_address": "State",
        "street_address": "Street",
        "updated_at": "Last update",
        "zipcode_address": "Postal Code"
      },
      "credit_application": {
        "accepted_at": "Accepted on",
        "amount": "Amount requested",
        "amount_cents": "Amount requested",
        "application_number": "Application number",
        "approved_amount": "Amount approved",
        "approved_amount_cents": "Amount approved",
        "approved_at": "Approved on",
        "attachment_type": "Type of file",
        "city_address": "Town",
        "clabe": "CLABE",
        "classification": "Type of credit",
        "closing_date_at": "Cutoff date",
        "created_at": "Creation date",
        "customer": "Customer",
        "description": "Description",
        "exterior_number_address": "Outdoor Number",
        "first_payment_date": "Initial payment date",
        "flow": "Request flow",
        "home_phone": "Home phone",
        "installments": "Number of terms",
        "interest_rate": "Interest",
        "interior_number_address": "Interior number",
        "late_payment_interest": "Interest on arrears",
        "maximum_penalty_days": "Maximum days of penalties",
        "metadata": "Metadata",
        "mobile_phone": "Cell phone",
        "neighborhood_address": "Suburb",
        "payment_days": "Paydays",
        "product": "Product",
        "product_id": "Product",
        "rejected_at": "Rejected at",
        "rejected_notes": "Reason for rejection",
        "state": "Credit status",
        "state_address": "State",
        "street_address": "Street",
        "updated_at": "Last update",
        "zipcode_address": "Postal Code"
      },
      "credit_history": {
        "created_at": "Creation date",
        "document": "Document (Credit bureau)"
      },
      "crowdfunding/fund": {
        "total_paid_amount": "Total paid"
      },
      "crowdfunding/movement": {
        "reference": "Reference",
        "tracking_number": "Tracking key"
      },
      "crowdfunding/setting": {
        "udis": "UDIS value",
        "udis_at": "Date of last update of UDIS value"
      },
      "customer": {
        "attachment_type": "Type of file",
        "blocked": {
          "one": "Locked",
          "other": "To block",
          "zero": "To unlock"
        },
        "created_at": "Registration date",
        "credit_applications": "Credit Applications",
        "credit_score": "Credit rating",
        "curp": "CURP",
        "curp_hash": "CURP",
        "date_of_birth": "Date of birth",
        "date_of_birth_hash": "Date of birth",
        "email": "Email",
        "first_name": "First name",
        "fullname": "Full name",
        "last_name": "Last name",
        "last_names": "Surnames",
        "mother_name": "Mother's last name",
        "names": "Names",
        "rfc": "RFC",
        "rfc_hash": "RFC",
        "second_name": "Second name",
        "source": "Acquisition medium",
        "updated_at": "Last update"
      },
      "event": {
        "body": "Message",
        "created_at": "Created in",
        "event_type": "Type",
        "owner": "Creator",
        "participant_ids": "Participants",
        "subject": "Reason"
      },
      "expense": {
        "amount": "Quantity",
        "classification": "Expense type"
      },
      "global_setting": {
        "maximum_amount_allowed": "Maximum credit amount",
        "maximum_credit_allowed": "Maximum number of credits"
      },
      "holiday": {
        "created_at": "Creation date",
        "description": "Description",
        "happening_at": "Date",
        "updated_at": "Last update"
      },
      "income": {
        "amount": "Quantity",
        "classification": "Type of income"
      },
      "installment": {
        "accessories_amount_cents": "Total accessories",
        "application_number": "Credit number",
        "created_at": "Creation date",
        "ending_balance_cents": "Final balance",
        "interest_cents": "Interest payment",
        "interest_tax_cents": "Interest VAT",
        "number": "Term No.",
        "payment_date": "Payment date",
        "penalty_interest_cents": "Default interest",
        "penalty_interest_tax_cents": "VAT on delinquent interest",
        "principal_cents": "Payment to capital",
        "status": "State",
        "total_payment_cents": "Total to pay"
      },
      "movement": {
        "amount": {
          "one": "Movement amount",
          "zero": "Amount"
        },
        "amount_cents": "Amount",
        "commission": "It&#39;s a commission",
        "created_at": "Creation date",
        "folio_number": "Folio number",
        "interest": "It is an interest",
        "movement_type": "Movement type",
        "reference": "Reference",
        "tax": "It&#39;s a tax"
      },
      "payment": {
        "amount": "Amount",
        "amount_cents": "Amount",
        "created_at": "Payment date",
        "payment_method": "Payment method",
        "payment_reference": "Payment reference",
        "payment_type": "Payment type",
        "prepaid_type": "Prepaid type",
        "reduced_installments_number": "No. of terms",
        "status": "State"
      },
      "product": {
        "active": "Active",
        "allow_prepaid": "Allow to pay in advance",
        "amortization_system_class": "Amortization system",
        "calculator_class": "Anticipation calculator",
        "created_at": "Creation date",
        "crowdfunding": "Collective financing",
        "crowdfunding_type": "Type of crowdfunding operation",
        "default_amount": "Default amount",
        "default_amount_cents": "Default amount",
        "description": "Description",
        "grace_days_application_expires": "Automatic rejection days",
        "grace_days_interest": "Grace days",
        "installments_maximum": "Deadline",
        "installments_minimum": "Minimum term",
        "installments_number": "Default deadlines",
        "interest_rate": "Interest",
        "interest_rate_days": "Interest rate days",
        "late_payment_base": "Penalty calculation",
        "late_payment_interest": "Interest on arrears",
        "maximum_amount": "Maximum amount",
        "maximum_amount_cents": "Maximum amount",
        "maximum_penalty_days": "Maximum days of penalties",
        "minimum_amount": "Minimum amount",
        "minimum_amount_cents": "Minimum amount",
        "name": "Name",
        "payment_days": "Paydays",
        "payment_periodicity": "Payment period",
        "score_flow_id": "Qualification flow",
        "short_name": "Product short name",
        "tax": "VAT",
        "updated_at": "Update date"
      },
      "promotion": {
        "amount": "Amount",
        "created_at": "Creation date",
        "elapsed_installments": "Payments",
        "interest": "Interest",
        "monthly_payment_amount": "Monthly payment",
        "reference": "Reference",
        "remaining_balance_cents": "Balance"
      },
      "reference": {
        "classification": "Reference type",
        "name": "Name",
        "phone": "Telephone"
      },
      "score_component": {
        "application_fields": "Request fields",
        "condition_fields": "Conditional fields",
        "created_at": "Creation date",
        "customer_fields": "Customer fields",
        "details_manual": "Details",
        "factor": "Factor",
        "label": "Name",
        "max_amount_balance": "Maximum amount",
        "min_amount_balance": "Minimum amount",
        "min_score_buro": "Minimum score",
        "position": "Position",
        "qualifier_class": "Component name",
        "updated_at": "Update date"
      },
      "score_component_result": {
        "created_at": "Creation date",
        "notes": "Notes",
        "score_component": "Rating component",
        "state": "State",
        "updated_at": "Update date",
        "value": "Value"
      },
      "score_flow": {
        "created_at": "Creation date",
        "formula": "Formula",
        "id": "ID",
        "name": "Name",
        "updated_at": "Update date"
      },
      "user": {
        "blocked": "Locked",
        "email": "Email",
        "id": "ID",
        "old_password": "Current password",
        "password": "Password",
        "roles": "Privileges",
        "roles_mask": "Privileges"
      },
      "webhook": {
        "active": "Active",
        "callback_url": "Callback url",
        "created_at": "Creation date",
        "description": "Description",
        "field": "Field",
        "name": "Name",
        "updated_at": "Update date"
      },
      "webhook_tracker": {
        "created_at": "Creation date",
        "response": "Answer",
        "status_code": "HTTP status code",
        "target_id": "Model ID",
        "target_type": "Model",
        "updated_at": "Update date"
      }
    }
  },
  "datetime": {
    "distance_in_words": {
      "half_a_minute": "half a minute",
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      },
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      }
    },
    "prompts": {
      "year": "Year",
      "month": "Month",
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "second": "Seconds"
    }
  },
  "helpers": {
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "update": "Update %{model}",
      "submit": "Save %{model}",
      "accessory": {
        "create": "Create accessory",
        "update": "Update accessory"
      },
      "catalog": {
        "create": "Create catalog",
        "update": "Edit catalog"
      },
      "credit_application": {
        "update": "Update request"
      },
      "customer": {
        "create": "Create customer",
        "update": "Update client"
      },
      "holiday": {
        "create": "Create holiday",
        "update": "Edit holiday",
        "delete": "Delete",
        "edit": "Edit",
        "new": "New holiday"
      },
      "product": {
        "create": "Create product",
        "update": "Edit product"
      },
      "score_flow": {
        "create": "Create flow",
        "update": "Update flow"
      },
      "user": {
        "create": "Invite user",
        "update": "Update user",
        "block": "Block",
        "edit": "Edit",
        "new": "Invite user",
        "unblock": "Unblock"
      },
      "webhook": {
        "create": "Create webhook",
        "update": "Update webhook"
      },
      "password": {
        "update": "Change password"
      }
    },
    "page_entries_info": {
      "entry": {
        "zero": "entries",
        "one": "entry",
        "other": "entries"
      },
      "one_page": {
        "display_entries": {
          "zero": "No %{entry_name} found",
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}"
        }
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      }
    },
    "label": {
      "product_accessory": {
        "accessory_id": "Accessory"
      }
    },
    "placeholder": {
      "accessory": {
        "days_offset": "Ex. 0",
        "fixed_value": "Ex. $ 10",
        "name": "Eg Commission for opening",
        "percentage": "Ex. 10%"
      },
      "contract_template": {
        "title": "Contract Title"
      },
      "customer": {
        "curp": "CURP code",
        "date_of_birth": "09/11/1991",
        "email": "customer@email.com",
        "first_name": "Juan",
        "last_name": "Perez",
        "mother_name": "Garcia",
        "rfc": "RFC key",
        "second_name": "Charlie"
      },
      "holiday": {
        "description": "Labor Day",
        "happening_at": "05/01/2019"
      },
      "product": {
        "late_payment_base": "Select an option",
        "name": "Name",
        "payment_periodicity": "Select a payment periodicity",
        "score_flow_id": "Select a qualification flow"
      },
      "score_flow": {
        "name": "Rating Flow Name"
      },
      "user": {
        "email": "user@mymail.com",
        "new_password": "*******",
        "password": "*******",
        "password_confirmation": "*******"
      },
      "webhook_presenter": {
        "callback_url": "https://www.example.com/payload",
        "name": "Webhook name"
      }
    },
    "prompt": {
      "accessory": {
        "calculation_base": "Calculation base",
        "calculation_type": "Calculation type",
        "payment_base": "Charge basis"
      },
      "attachment": {
        "attachment_type": "Please select an option"
      },
      "credit": {
        "product_id": "Select a Product"
      },
      "credit_application": {
        "attachment_type": "Select a File Type",
        "classification": "Select a Credit Type",
        "flow": "Select a Flow",
        "product_id": "Select a Product"
      },
      "customer": {
        "attachment_type": "Select a File Type",
        "source": "Select an option"
      },
      "event": {
        "event_type": "Please select an option"
      },
      "expense": {
        "classification": "Select a Type of egress"
      },
      "income": {
        "classification": "Select a Type of income"
      },
      "models": {
        "credit_presenter": {
          "attributes": {
            "product_id": "Select a Product"
          }
        }
      },
      "payment": {
        "payment_method": "Select a payment method"
      },
      "product": {
        "calculator_class": "Select a Calculator",
        "late_payment_base": "Please select an option",
        "payment_periodicity": "Please select an option",
        "score_flow_id": "Select a Qualification Flow"
      },
      "product_accessory": {
        "accessory_id": "Select an accessory"
      },
      "reference": {
        "classification": "Select a Reference Type"
      },
      "webhook_presenter": {
        "field": "Select a Field"
      }
    }
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      },
      "destroy": {
        "notice": "%{resource_name} was successfully destroyed.",
        "alert": "%{resource_name} could not be destroyed."
      }
    },
    "update": {
      "invalid_code": {
        "alert": {
          "title": "Token in invalid",
          "description": "Check your token, it maybe expired. Request a new one if you are unable to start a session."
        }
      },
      "session": {
        "alert": {
          "title": "Unauthorized",
          "description": "Start a session to continue."
        }
      }
    },
    "record_not_found": "The record you are trying to access does not exist.",
    "user_not_authorized": "You are not authorized to perform this action."
  },
  "views": {
    "pagination": {
      "first": "Beginning",
      "last": "The end",
      "previous": "Previous",
      "next": "Next",
      "truncate": "&hellip;"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "locked": "Your account is locked.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing.",
      "session_limited": "Your login credentials were used in another browser. Please sign in again to continue in this browser.",
      "expired": "Your account has expired due to inactivity. Please contact the site administrator."
    },
    "mailer": {
      "confirmation_instructions": {
        "subject": "Confirmation instructions"
      },
      "reset_password_instructions": {
        "subject": "Reset password instructions",
        "body_html": "Don&#39;t worry, we can help you.<br> To reset your password please use the link.",
        "disclaimer_html": "If you did not request to change your password, you can ignore this email.<br/> Your password will not change until you use the link above.",
        "help": "If the link doesn&#39;t work, please copy and paste this URL into your browser:",
        "link": "RESET MY PASSWORD",
        "title": "Did you forget your password?"
      },
      "unlock_instructions": {
        "subject": "Unlock instructions",
        "body_html": "For security reasons, your account has been blocked due to the excess of unsuccessful attempts to<br/> log in.<br/> To unlock it please use the following link.",
        "disclaimer_html": "If it was not you, please contact us %{mail}.<br/> If you think you received this email by mistake, please ignore it.",
        "link_text": "UNLOCK MY ACCOUNT",
        "title": "Hello!"
      },
      "email_changed": {
        "subject": "Email Changed"
      },
      "password_change": {
        "subject": "Password Changed"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirm link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again."
    },
    "sessions": {
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully.",
      "already_signed_out": "Signed out successfully."
    },
    "unlocks": {
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    },
    "invalid_captcha": "The captcha input was invalid.",
    "invalid_security_question": "The security question answer was invalid.",
    "paranoid_verify": {
      "code_required": "Please enter the code our support team provided"
    },
    "paranoid_verification_code": {
      "updated": "Verification code accepted",
      "show": {
        "submit_verification_code": "Submit verification code",
        "verification_code": "Verification code",
        "submit": "Submit"
      }
    },
    "password_expired": {
      "updated": "Your new password is saved.",
      "change_required": "Your password is expired. Please renew your password.",
      "show": {
        "renew_your_password": "Renew your password",
        "current_password": "Current password",
        "new_password": "New Password",
        "new_password_confirmation": "Confirm new password",
        "change_my_password": "Change my password",
        "description": "Enter a new password to continue",
        "sign_in": "Log in",
        "submit": "Change Password",
        "title": "Your password has expired"
      }
    }
  },
  "form": {
    "placeholders": {
      "email": "ana@my-email.com",
      "token": "4646-39103-0867"
    },
    "labels": {
      "token": "Received token"
    },
    "submit": {
      "login": "Continue"
    }
  },
  "layouts": {
    "no_password": {
      "mailer": {
        "service": "Service provided by",
        "platform": "The banking core platform on which to run your financial business.",
        "from": "no-reply@aoorora.com"
      }
    },
    "mailer": {
      "from": "no-reply@aoorora.com"
    }
  },
  "mailers": {
    "default_from": "no-reply@aoorora.com",
    "send_token": {
      "subject": "Here is your temporary login token",
      "greetings": "Hello!",
      "instructions_1": "You requested a login token to start a new session. Here is your code. You can copy/paste the code into our website. Please don't share this code to anyone.",
      "instructions_2": "Or use this button to start a new session. It will open a new browser window.",
      "instructions_2_text": "Or use the follwing link to start a new session. Copy and paste it your browser.",
      "start_session": "Continue to your session",
      "instructions_3": "If you did not request this email, please ignore and delete it. Do not resend or share it with other people."
    }
  },
  "no_password": {
    "sessions": {
      "new": {
        "title": "New session",
        "description": "Enter your email below to receive a code and a link to log in."
      }
    },
    "session_confirmations": {
      "edit": {
        "title": "Confirm your session",
        "description": "We sent you an email. Use the code on it to continue or use the link provided. Check your spam folder if you can't find our email in your inbox.",
        "request_token": "Request new token"
      }
    }
  },
  "accepted_applications": {
    "new": {
      "notice": "This action is understood that the Client accepted the credit.",
      "submit": "Accept",
      "title": "Accept request"
    }
  },
  "accessories": {
    "calculation_type": {
      "fixed": "Permanent",
      "percentage": "Percentage"
    },
    "conditions": {
      "add": "Add",
      "condition": "Condition",
      "condition_fields": "Terms",
      "conditions": {
        "condition": "Select a condition",
        "field": "Select a field"
      },
      "fields": "Fields",
      "fields_added": "Added fields",
      "value": "Value"
    },
    "create": {
      "notice": "The Accessory was created successfully."
    },
    "edit": {
      "title": "Accessory %{name}"
    },
    "model_fields": " %{model} fields",
    "new": {
      "title": "New Accessory"
    },
    "show": {
      "title": "Accessory %{name}"
    },
    "update": {
      "notice": "The Accessory was updated successfully."
    }
  },
  "actions": {
    "back": "Return",
    "cancel": "Cancel",
    "delete": "Remove",
    "edit": "Modify",
    "new": "New",
    "preview": "Preview",
    "show": "See details",
    "view": "Consult"
  },
  "activate_accessories": {
    "create": {
      "notice": "The Accessory has been activated successfully."
    },
    "destroy": {
      "notice": "The Accessory has been successfully deactivated."
    }
  },
  "activate_catalogs": {
    "create": {
      "notice": "The catalog has been successfully activated."
    },
    "destroy": {
      "notice": "The catalog has been successfully deactivated."
    }
  },
  "activate_products": {
    "create": {
      "notice": "The Product has been successfully activated."
    },
    "destroy": {
      "notice": "The Product has been successfully deactivated."
    }
  },
  "activate_sandbox": {
    "create": {
      "activated": "Sandbox enabled"
    },
    "destroy": {
      "desactivated": "Sandbox disabled"
    }
  },
  "activate_webhooks": {
    "create": {
      "notice": "The Webhook has been successfully activated."
    },
    "destroy": {
      "notice": "The Webhook has been successfully disabled."
    }
  },
  "activemodel": {
    "errors": {
      "models": {
        "balance_score_qualifier": {
          "attributes": {
            "incomes": {
              "above_maximum": "The difference between Income and Expenses exceeds the maximum.",
              "below_minimum": "The difference between Income and Expenses does not exceed the minimum."
            }
          }
        },
        "buro_score_qualifier": {
          "attributes": {
            "credit_history": {
              "below_minimum": "The Bureau&#39;s Score does not exceed the expected minimum.",
              "not_found": "The Credit Application does not have any Credit History associated with it."
            }
          }
        },
        "document_score_qualifier": {
          "attributes": {
            "application": {
              "document_blank": "There is no document to verify",
              "document_invalid": "Invalid document",
              "document_timeout": "Could not process due to connection problems"
            }
          }
        },
        "product": {
          "attributes": {
            "active": {
              "crowdfunding_deactivate": "Can't deactivate a product with crowdfunding applications.",
              "deactivate": "Can't deactivate a product with approved credit applications."
            },
            "base": {
              "inactive": "Cant select an inactive product."
            },
            "calculator_class": {
              "blank": "Interest calculator can't be blank."
            },
            "contract_template": {
              "blank": "The product must have a contract."
            },
            "crowdfunding": {
              "amortization_system_invalid": "Crowdfunding is invalid for a amortization system with credit line.",
              "cant_be_updated": "Can't enable crowdfunding."
            },
            "crowdfunding_type": {
              "blank": "Select an option for crowdfunding.",
              "inclusion": "Invalid crowdfunding type."
            },
            "default_amount": {
              "less_than_or_equal_to": "The default amount is above the credit limit.",
              "not_a_number": "Default amount must be numeric."
            },
            "grace_days_interest": {
              "less_than_or_equal_to": "Grace period days must be less than or equal to 7."
            },
            "interest_rate_days": {
              "greater_than": "Interest rate days must be grater than 0.",
              "less_than_or_equal_to": "Interest rate days must be less than or equal to 365."
            },
            "late_payment_base": {
              "blank": "The project must have an penalty calculation option."
            },
            "maximum_amount": {
              "less_than_or_equal_to": "The maximum amount is above the credit limit.",
              "not_a_number": "Maximum amount must be numeric."
            },
            "minimum_amount": {
              "less_than_or_equal_to": "The minimum amount is above the credit limit.",
              "not_a_number": "Minimum amount must be numeric."
            },
            "name": {
              "blank": "The name can't be blank."
            },
            "payment_periodicity": {
              "less_than_or_equal_to": "Payment periodicity must be less than or equal to 365.",
              "not_a_number": "Must select a payment periodicity."
            },
            "score_flow": {
              "cant_be_updated": "Can't edit the qualification flow because there is applications in a status of \"in qualification\" that have the qualification flow."
            },
            "short_name": {
              "blank": "The short name can't be blank."
            }
          }
        }
      }
    }
  },
  "api": {
    "errors": {
      "generic": {
        "not_found": "The %{model_name} does not exist."
      }
    }
  },
  "api_key": "Access Key",
  "approved_applications": {
    "new": {
      "submit": "Pass",
      "title": "Approve request"
    }
  },
  "archive_accessories": {
    "create": {
      "notice": "The Accessory has been successfully archived."
    },
    "destroy": {
      "notice": "The Accessory has been successfully unarchived."
    }
  },
  "attachments": {
    "errors": {
      "blank": "You need to upload a file.",
      "size": "The file size must not exceed 7 MB.",
      "type": "Only GIF, TIFF, PNG, JPG / JPEG and PDF files are allowed."
    },
    "preview": "View file"
  },
  "authorize_with_password": {
    "invalid": "Incorrect password",
    "label": "Password",
    "title": "Password authorization"
  },
  "block_customers": {
    "create": {
      "notice": "Client has been blocked."
    },
    "destroy": {
      "notice": "Client has been unlocked."
    }
  },
  "block_users": {
    "create": {
      "success": "User %{email} has been successfully blocked"
    },
    "destroy": {
      "success": "User %{email} has been successfully unblocked"
    }
  },
  "bulk_payments": {
    "attributes": {
      "payment_date": "Payment date."
    },
    "bulk_payments_modal": {
      "title": "Select a date to apply bulk payments."
    },
    "errors": {
      "amount_cents": {
        "less_than": "The amount charged is less than the total payable."
      },
      "banks": {
        "not_found": "The bank selected to map was not found"
      },
      "credit": {
        "not_found": "Credit not found."
      },
      "file": {
        "empty": "The file has no records to process.",
        "invalid": "Invalid file."
      },
      "installment": {
        "invalid": "No Installment was found as pending payment or it may have expired."
      },
      "payment": {
        "failed": "Failed to collect the payment."
      },
      "payment_date": {
        "different_than": "The payment date of the Term is different from the captured date.",
        "invalid": "Invalid payment date."
      },
      "status": {
        "invalid": "Found a status &quot; %{status}&quot; that does not match the status list for column %{header} on line: %{line}"
      }
    },
    "headers": {
      "consecutive": "consecutive",
      "credit_id": "credit_number",
      "invalid": " %{header} is an invalid header in the file.",
      "result": "Outcome"
    },
    "payment": {
      "created": "The payment has been made correctly.",
      "failed": "The payment has been unsuccessful."
    },
    "upload_file_bancomer": "Upload Bancomer file"
  },
  "catalogs": {
    "create": {
      "notice": "Catalog has been created successfully."
    },
    "edit": {
      "title": "Edit catalog"
    },
    "form": {
      "wrong_configuration": "The class %{model} does not exist. Contact technical support."
    },
    "new": {
      "title": "New Catalog"
    },
    "show": {
      "title": "Catalog %{name}"
    },
    "update": {
      "notice": "Catalog has been updated successfully."
    }
  },
  "change_password": "Change Password",
  "conditions_fields": {
    "add": "Add",
    "condition": "Condition",
    "fields": "Fields",
    "fields_added": "Added fields",
    "title": "Condition fields",
    "value": "Value"
  },
  "constrains": {
    "limits_applied": "Applied limits",
    "maximum_amount_allowed": "Maximum amount depleted",
    "maximum_credit_allowed": "Maximum credits exhausted"
  },
  "contract_templates": {
    "field_not_found": "An error occurred while Generating the Contract, the field &quot;% {field}&quot; does not exist. You need to correct the Contract Template."
  },
  "contracts": {
    "create": {
      "notice": "Successfully generated contract."
    }
  },
  "create_credits": {
    "new": {
      "submit": "Accept",
      "title": "Convert to credit"
    }
  },
  "credit_adjustments": {
    "interest_adjustment": "ADJUSTMENT TO INTEREST",
    "new": {
      "description": "Description",
      "fixed_value": "Amount",
      "fixed_value_placeholder": "$ 0.00",
      "partial_fixed_value_amount": "Capture amount",
      "payment_base": "Fit type",
      "title": "Adjustment"
    },
    "payment_bases": {
      "interest": "Interest (current term)",
      "penalty_interest": "Default interest (current term)",
      "quita": "Remove",
      "total_payment": "Total accessories (current term)"
    },
    "penalty_interest_adjustment": "ADJUSTMENT TO MORATORY INTERESTS",
    "quita_adjustment": "REMOVE",
    "total_payment_adjustment": "ADJUSTMENT TO TOTAL ACCESSORIES"
  },
  "credit_application_validation": {
    "approved_amount": "You need to save the Approved Amount in order to approve the Request.",
    "product_selected_and_saved": "You need to select and save a product in order to qualify an application.",
    "rejected_notes": "You need to save the Reason for Rejection in order to reject the Request."
  },
  "credit_applications": {
    "accept_button": {
      "label": "Accept"
    },
    "alert": "The request you are trying to update is in %{state}.",
    "approve_button": {
      "label": "Pass"
    },
    "attachments": {
      "attachment_submit": "Attach file"
    },
    "contract": {
      "no_contract": "The Request has not been approved, when approving it, the contract will be generated automatically."
    },
    "create": {
      "alert": "Something went wrong creating the Credit Request.",
      "notice": "The Credit Application has been created successfully. Now you can edit it."
    },
    "create_credit_button": {
      "label": "Convert to Credit"
    },
    "create_crowdfund_button": {
      "label": "Convert to Campaign"
    },
    "create_crowdfund_campaign_modal": {
      "submit": "Convert to campaign",
      "title": "Convert to campaign"
    },
    "credit_histories": {
      "credit_history_submit": "Create Credit History"
    },
    "crowdfunds": {
      "rejected_notes": "The Application has been rejected due to lack of loans in the Campaign"
    },
    "edit": {
      "title": "Credit application %{application_number}"
    },
    "expenses": {
      "expense_submit": "Create expense"
    },
    "form": {
      "address": "Address",
      "client_information": "Customer information",
      "credit": "Credit",
      "product": "Product",
      "status": "Request detail"
    },
    "incomes": {
      "income_submit": "Create income"
    },
    "preview": {
      "summary": "Request Summary",
      "title": "Credit application %{application_number}",
      "total_credit_amount": "Total amount",
      "total_interest": "Total Interest",
      "total_interest_tax": "Total VAT Interest"
    },
    "preview_button": {
      "label": "View on credit"
    },
    "references": {
      "reference_submit": "Create reference"
    },
    "reject_button": {
      "label": "Refuse"
    },
    "score_button": {
      "label": "Qualify"
    },
    "score_flow_results": {
      "actions": "Actions",
      "customer_credit_score_submit": "Update",
      "manual_approve": "Pass",
      "no_notes": "N / A",
      "no_score": "The Rating Flow has not yet been executed on the Credit Request.",
      "no_value": "N / A",
      "pending_header": "Pending Components",
      "results_header": "Components Executed",
      "score_result": "Rating: %{score}"
    },
    "show": {
      "address": "Address",
      "alert": "The Credit Application requires a Product.",
      "client_information": "Customer information",
      "credit": "Credit",
      "product": "Product",
      "status": "Request detail",
      "title": "Credit application %{application_number}"
    },
    "update": {
      "alert": "Something went wrong updating the Credit Application.",
      "notice": "The information on the Request has been saved correctly."
    },
    "view_credit": "See Credit"
  },
  "credit_histories": {
    "show": {
      "title": "Credit History"
    }
  },
  "credit_restructure": {
    "blank": "No field can be empty",
    "error_approve": "Failed to approve new request",
    "error_credit": "Error converting to credit",
    "error_credit_application": "Error creating new request",
    "error_credit_application_update": "Error updating new request",
    "error_credit_restrutured": "Error restoring original credit",
    "error_score_flow": "Failed to qualify new application",
    "invalid_password": "Wrong password",
    "record_not_found": "Credit not found",
    "score_component_note": "Qualified for restructuring"
  },
  "credits": {
    "create": {
      "alert": "Something went wrong creating the Credit.",
      "notice": "The Credit was created successfully."
    },
    "credit_simples": {
      "installment_plan": {
        "credit_adjustment": "Adjustment"
      }
    },
    "index": {
      "ending_balance": "Available or pending balance"
    },
    "periods": {
      "current_period": "Current period",
      "last_previous_period": "Period prior to the previous one",
      "previos_period": "Previous period",
      "promotions": {
        "progress_bar_text": " %{elapsed} of %{total}"
      }
    },
    "restructure_modal": {
      "restructure": "Restructure"
    },
    "show": {
      "credit_details": "Credit details",
      "customer_information": "Customer information",
      "general_information": "General information",
      "initial_capital": "Initial capital",
      "interests_paid": "Interest paid",
      "missing_amount": "Pending payment for term No. %{number}",
      "paid_iva_amount": "VAT paid",
      "penalty_interest_paid": "Late interest paid",
      "taxes_paid": "VAT paid",
      "title": "Credit %{application_number}"
    },
    "view_credit_application": "See Request"
  },
  "crowdfunding": {
    "crowdfunds": {
      "create": {
        "alert": "An error arose when creating the Campaign: %{error}",
        "success": "Campaign successfully created"
      },
      "funds": {
        "new_fund": "New fund"
      },
      "new": {
        "title": "Convert to campaign"
      },
      "reject_crowdfund": {
        "rejected": "The campaign has been rejected"
      },
      "show": {
        "title": "Campaign %{crowdfund_number}"
      }
    },
    "forbidden": "It is not possible to use Crowdfunding, please contact us via email support@creditar.io",
    "funds": {
      "create": {
        "description": {
          "fund": "Campaign loan"
        }
      },
      "new": {
        "title": "New loan"
      }
    },
    "investors": {
      "button": {
        "create": "Create as Investor"
      },
      "create": {
        "alert": "An error arose when creating the Investor: %{error}",
        "notice": "Investor successfully created"
      },
      "edit": {
        "title": "Investor %{email}"
      },
      "movement_modal": {
        "select": "Select",
        "submit": "Create movement",
        "title": "New movement"
      },
      "movements": {
        "new_movement": "New movement"
      },
      "settings": {
        "update": {
          "alert": "Something went wrong updating the settings",
          "notice": "Settings successfully updated"
        }
      },
      "show": {
        "general_information": "General information",
        "title": "Investor %{email}"
      },
      "update": {
        "alert": "Something went wrong updating the Investor",
        "notice": "Investor successfully updated"
      }
    },
    "movements": {
      "description": {
        "cancellation": "Cancellation of %{description}",
        "cash_out": "Withdrawal of funds",
        "commission": "Commission charge for account management",
        "commission_tax": "VAT collection for account management",
        "credit": "Deposit of funds to account",
        "fund": "Campaign loan",
        "payment": "Partial payment paid",
        "refund": "Refund by %{description}"
      },
      "movements": {
        "new_movement": "New movement"
      },
      "new": {
        "title": "New movement"
      }
    },
    "settings": {
      "contract_template": {
        "available_fields": "List of Available Fields",
        "fields_hint": "Remember that to use them, you must put the name of the field inside of %{}"
      },
      "edit": {
        "configuration_crowdfunds": "Crowdfunds configuration",
        "general_configuration": "General configuration"
      },
      "update": {
        "alert": "Something went wrong",
        "notice": "Settings updated successfully"
      }
    }
  },
  "customers": {
    "attachments": {
      "attachment_submit": "Attach file"
    },
    "create": {
      "notice": "The customer has been created. Now you can start filling out your Credit Application."
    },
    "credit_applications": {
      "amount": "Amount",
      "application_number": "Application",
      "create_new_credit_application": "Create new request",
      "status": "State"
    },
    "edit": {
      "title": "Client %{email}"
    },
    "events": {
      "event_submit": "Create event",
      "participant_ids_prompt": "Select one or more",
      "participants": "Participants: %{users}"
    },
    "new": {
      "title": "New customer"
    },
    "show": {
      "create_investor": "Create investor",
      "show_investor": "See investor",
      "title": "Client %{email}"
    },
    "update": {
      "notice": "Client has been successfully updated."
    }
  },
  "dashboards": {
    "show": {
      "welcome": "Welcome to"
    }
  },
  "datepicker": {
    "ranges": {
      "custom": "Custom range",
      "last_30_days": "Last 30 days",
      "last_3_months": "Last 3 months",
      "last_7_days": "Last 7 days",
      "last_month": "Last month",
      "this_month": "Current month",
      "today": "Today",
      "yesterday": "Yesterday"
    }
  },
  "demo": {
    "blog": "Blog",
    "button": "Start my trial",
    "confirm": "Go to demo",
    "confirm_info": "Enter the code here, or click on the button included in the received message.",
    "default_email": "Type in your email",
    "demo_user_missing": "Demo user missing",
    "description": "With Aoorora, you'll be able to manage your customer loan processing with ease and accuracy, saving time and money all along the way. Plus, automated features mean you won't have to worry about spending hours each day slogging through paperwork.",
    "email_placeholder": "Type in your email",
    "help": "That's it! You're ready to explore the world of automated finance with Aoorora.",
    "identity": "Identity",
    "label": "Email",
    "pretitle": "Discover a smarter way to take care of customer loans",
    "start_info": "To get started, enter your email address and then simply enter the code sent to your inbox.",
    "step": "Start your trial with one simple step.",
    "submit": "Start my trial",
    "submited_code_description": "Check your email for your access code",
    "subtitle": "In one simple step",
    "title": "Try Aoorora today",
    "token_placeholder": "HJn7-5840-HxkX",
    "vacancies": "Vacancies"
  },
  "duplicate_records": {
    "duplicate_record": "Duplicate record",
    "duplicated_record": "Duplicate record",
    "record_not_found": "Record not found",
    "record_not_permited": "Registration not allowed to duplicate"
  },
  "error_pages": {
    "not_found": {
      "description": "We&#39;re sorry, but we can&#39;t seem to find the resource you&#39;re looking for. This typically happens when a resource is no longer available, has been deleted, or the address is wrong.",
      "message": "THE REQUESTED REMEDY WAS NOT FOUND"
    },
    "unauthorized": {
      "description": "We&#39;re sorry, but you don&#39;t seem to have permission to do this.",
      "message": "YOU DO NOT HAVE THE PERMISSION"
    }
  },
  "events": {
    "show": {
      "no_participants": "There are no participants in the event."
    }
  },
  "exportable": {
    "credit": {
      "credit_application": {
        "application_number": "Credit Number"
      }
    },
    "installment": {
      "credit_application": {
        "application_number": "Credit Number"
      }
    }
  },
  "filters": {
    "apply": "Apply",
    "between": "Between dates",
    "cancel": "Cancel",
    "clear": "Clean up",
    "contains_to": "Contains",
    "different_than": "Different from",
    "equal_than": "Equal to",
    "greater_or_equal_than": "Greater or equal to",
    "greater_than": "Greater than",
    "less_or_equal_than": "Less than or equal to",
    "less_than": "Smaller than",
    "operator": "Filters",
    "operator_prompt": "Choose",
    "submit": "Apply",
    "value": "Value"
  },
  "footer": {
    "copyright": "© 2022 Digital LMS, Inc. All rights reserved.",
    "description": "The banking platform for your financial business",
    "email": "hola@aoorora.com",
    "vacancies": "Jobs"
  },
  "generic": {
    "accept": "Accept",
    "actions": "Actions",
    "activate": "Activate",
    "add": "Add",
    "archive": "file",
    "branding": "Aoorora",
    "cancel": "Cancel",
    "close": "Close",
    "conditions": {
      "contains_to": "Contains",
      "different_than": "Different than",
      "equal_to": "Equal to",
      "greater_or_equal_to": "Greater than or equal",
      "greater_than": "Greater than",
      "less_or_equal_to": "Less than or equal to",
      "less_than": "Smaller than"
    },
    "confirm": "Are you sure?",
    "create": "Create",
    "deactivate": "Deactivate",
    "delete": "Remove",
    "deleting": "Removing...",
    "disabling": "Charging...",
    "edit": "Edit",
    "errors_header": "The following errors arose:",
    "false": "No",
    "file_not_charge": "No file has been uploaded",
    "file_upload": "Upload file",
    "filters": {
      "between": "Between dates",
      "contains_to": "Contains",
      "different_than": "Different from",
      "equal_than": "Equal to",
      "greater_or_equal_than": "Greater or equal to",
      "greater_than": "Greater than",
      "less_or_equal_than": "Less than or equal to",
      "less_than": "Smaller than"
    },
    "n_a": "N / A",
    "no": "No",
    "no_catalogs": "There are no Catalogs with the field: %{field}",
    "no_matches": "There are no %{model} that meet your filter criteria.",
    "no_records": "There are no %{model} created yet.",
    "no_records_associated": "There is no %{association} for this %{model}",
    "no_results": "Your search had no results.",
    "nothing_to_show": "There is no %{label} to display",
    "notification": "Notification",
    "print": "Print",
    "processing": "Processing ...",
    "record_not_found": "The resource was not found.",
    "records_count": {
      "one": "1 record",
      "other": " %{count} records"
    },
    "save": "Save",
    "selecting": "To select",
    "show_or_hide": {
      "one": "Show %{label}",
      "zero": "Hide %{label}"
    },
    "show_resource_name": "See %{resource_name}",
    "submit": "Send",
    "true": "Yes",
    "unarchive": "Unarchive",
    "update": "Update",
    "yes": "Yes"
  },
  "global_settings": "Global settings",
  "holidays": {
    "create": {
      "notice": "The holiday has been successfully saved."
    },
    "edit": {
      "title": "Edit holiday"
    },
    "new": {
      "title": "New holiday",
      "description": "Indicate the holiday to be taken into account in date calculations."
    },
    "update": {
      "notice": "The holiday has been updated successfully."
    },
    "index": {
      "description": "A list of all registered holidays including their happening date and description."
    }
  },
  "installment_plans": {
    "active_version": "Active Version",
    "inactive_version": "Inactive version of %{date}"
  },
  "invites_mailer": {
    "invite_email": {
      "body_html": "We already registered this email! To start using the account, please just set your<br/> password in the following link.",
      "disclaimer_html": "If you or someone from your work team did not request this move, please ignore this email<br/> electronic.<br/> Likewise, if you think you received this email by mistake.",
      "link_text": "SET PASSWORD",
      "subject": "You have been invited to become an Administrator of an account at Creditar.io",
      "subject_html": "You have been invited to become an <b>Administrator</b> of an account at Creditar.io<br/>",
      "title": "Hello!"
    }
  },
  "logout": "Sign off",
  "manual_score_component_results": {
    "update": {
      "alert": "One of the Rating Components did not meet expectations or is a Manual Component.<br />Notes: %{notes}",
      "invalid": "The Result Component is not of the Manual type.",
      "notice": "Qualification obtained satisfactorily."
    }
  },
  "menu": {
    "application": {
      "dropdown": {
        "api_key": "Access key",
        "change_password": "Change Password",
        "crowdfunding_settings": "Crowdfunding Settings",
        "disable_2fa": "Disable 2FA",
        "disable_sandbox": "Disable sandbox",
        "enable_2fa": "Activate 2FA",
        "enable_sandbox": "Activate sandbox",
        "global_settings": "Global Settings",
        "holidays": "Holidays",
        "logout": "Sign off",
        "nuke_sandbox": "Clear Sandbox data",
        "users": "Users"
      },
      "sidebar": {
        "accessories": "Accessories",
        "catalogs": "Catalogs",
        "credit_applications": "Applications",
        "credits": "Credits",
        "crowdfunds": "Crowdfunds",
        "customers": "Customers",
        "investors": "Investors",
        "products": "Products",
        "reports": "Reports",
        "score_flows": "Qualification calificación",
        "webhooks": "Webhooks"
      }
    }
  },
  "metadata": {
    "add": "Add",
    "attribute": "Attribute",
    "edit": "Edit metadata",
    "metadata_title": "Metadata",
    "remove": "Remove",
    "show": "View metadata",
    "submit": "Save",
    "value": "Value"
  },
  "movements": {
    "new": {
      "title": "New movement"
    },
    "payment_reference": "Period payment: %{id}",
    "promotions": {
      "interest_movement_reference": " %{elapsed_installments} of %{total_installments}% {movement_reference} interests",
      "monthly_payment_movement_reference": " %{elapsed_installments} from %{total_installments}% {movement_reference}",
      "tax_movement_reference": " %{elapsed_installments} of %{total_installments}% {movement_reference} VAT"
    }
  },
  "nuke_sandbox": {
    "create": {
      "notice": "Data deleted from Sandbox"
    }
  },
  "otp": {
    "complete": {
      "show": {
        "copied_to_clipboard": "Copiado al portapapeles",
        "copy": "Copy",
        "download": "Download",
        "information_html": "<p class=\"has-font-size-16 barlow-font has-text-prussian-blue m-b-20\">It is very important that you save the recovery codes that appear below.</br> Because if you lose or change your phone, you will not be able to access your account without using one of these codes.</p><p class=\"has-font-size-16 barlow-font has-text-prussian-blue\"> <b>Remember that you can lose access to your account if you do not have these codes.</b></br> Please keep them and keep them in a safe place.</p>",
        "ok_i_saved_codes": "Ok, I have saved the codes",
        "print": "Print",
        "subtitle": "Step 3",
        "title": "2FA configuration",
        "your_recovery_codes": "Your recovery codes"
      }
    },
    "configure": {
      "create": {
        "verification_code_is_invalid": "Invalid verification code"
      },
      "step1": {
        "configure_2fa": "Configure 2FA",
        "information": "Two-factor authentication is a second step when it comes to logging in that helps you protect your account.",
        "subtitle": "Two factor authentication",
        "title": "Security"
      },
      "step2": {
        "cancel": "Forget it, let's go back",
        "configure_2fa": "Configure 2FA",
        "info_step1": "First, you should enter with your email and password as usual.",
        "info_step2": "Then your application generates a unique security code.",
        "info_step3": "Finally, you will have to use that unique security code to verify that it is really you.",
        "information_html": "<p>Every time you log in, it <b>will be necessary to use a unique security code generated by your phone application</b> . This way, if someone has your password, they won&#39;t be able to access your account anyway because they don&#39;t have the code.</p><p> Setting up 2FA takes just a few minutes, but don&#39;t worry. We will accompany you during the process.</p>",
        "subtitle": "When Two-factor authentication (2FA) is enabled, your login will look like this:",
        "title": "Two-factor authentication"
      },
      "step3": {
        "accept": "Done, I have an APP",
        "cancel": "Return",
        "information_html": "<p class=\"has-font-size-16 has-text-prussian-blue barlow-font\">To begin with, it is necessary to have a two-factor authentication application on your phone, to generate the unique security codes each time you log in.</p><p class=\"has-font-size-16 has-text-prussian-blue barlow-font\"> If you already use Duo, 1Password or Goole Authenticator you are ready.</p><p class=\"has-font-size-16 has-text-prussian-blue barlow-font\"> If you are using 2FA for the first time, we recommend downloading one of the above or using the one you prefer.</p>",
        "subtitle": "Step 1",
        "title": "2FA configuration"
      },
      "step4": {
        "cancel": "Return",
        "information_html": "Now, scan this image with your authentication application and then enter the code it generates in the box that appears below the image.",
        "second_information_html": "Six-digit authentication code",
        "subtitle": "Step 2",
        "title": "2FA configuration",
        "trouble_scanning_secret_html": "<p class=\"barlow-font has-text-prussian-blue has-font-size-16 m-b-20\">Trouble scanning the code? Use this key in your application:</p><p class=\"barlow-font has-text-jelly-bean has-font-size-16 otp-secret-key\">  %{secret}</p>",
        "validating": "Validating ...",
        "verify_code": "Check and continue"
      }
    },
    "disable": {
      "destroy": {
        "notice": "Two-factor authentication has been successfully disabled"
      },
      "show": {
        "are_you_sure_disable_2fa": "Are you sure you want to disable two-factor authentication?",
        "cancel": "Forget it, let's go back",
        "disable_2fa": "Yes, disable",
        "information_html": "If the two-factor authentication is disabled, it will no longer be necessary to enter the generated code in your phone application to log in.</br> Therefore, <b>your account will return to standard security.</b>",
        "title": "Disable 2FA"
      }
    },
    "disable_2fa": "Disable 2FA",
    "enable_2fa": "Activate 2FA",
    "twofa_already_enabled": "(2FA) Two-factor authentication is already enabled",
    "twofa_not_enabled": "(2FA) Two-factor authentication is not activated",
    "verify": {
      "create": {
        "verification_code_is_invalid": "Invalid verification code"
      },
      "new": {
        "code": "Verification code",
        "information": "Enter the security code generated by your phone&#39;s authentication application to verify your identity.",
        "subtitle": "2FA verification",
        "title": "Two-factor authentication",
        "validating": "Validating ...",
        "verify": "check"
      }
    }
  },
  "passwords": {
    "edit": {
      "description": "Your password must be at least 8 characters long and include at least one uppercase, one lowercase, one number and one special character.",
      "title": "Change Password"
    },
    "update": {
      "alert": "Could not update password, please try again.",
      "notice": "Your password has been updated"
    }
  },
  "payment_modal": {
    "forgive_penalty_interest": "Forgive late interest",
    "installments_number": "Number of installments",
    "payment_type": {
      "annuity": "Partial payment of",
      "balance": "Payment of the outstanding balance:",
      "ending_balance": "Payment to the unpaid balance of %{amount}",
      "minimum_amount": "Minimum payment of:",
      "partial": "Partial payment",
      "zero_amount": "Accumulate the entire payment for the next installment"
    },
    "placeholder_prepaid_types": "Select a prepayment type",
    "submit": "Confirm payment",
    "title": "Capture payment",
    "total_penalty_interest": "Default interest plus VAT"
  },
  "payments": {
    "alerts": {
      "not_found": "The Term you are trying to process was not found."
    },
    "cancel": "Cancel",
    "capture": "Capture",
    "destroy": {
      "notice": "The payment has been successfully canceled."
    },
    "expired": "Expired",
    "not_cancelable": "TRANSLATE_API_ERROR",
    "paid": "Paid out",
    "payment_capture": "Capture payment",
    "pending": "Pending",
    "prepaid_types": {
      "reduce_annuity": "Reduce annuity",
      "reduce_installments_automatically": "Reduce payments automatically",
      "reduce_installments_number": "Reduce number of payments"
    }
  },
  "privileges": {
    "admin": "Administrator",
    "analyst": "Analyst",
    "collection": "Collection",
    "customer": "Promoter / Client",
    "owner": "Owner",
    "product": "Product"
  },
  "products": {
    "accessories": {
      "accessory_submit": "Assign accessory"
    },
    "contract_template": {
      "available_fields": "List of Available Fields",
      "contract_template_submit": "Save contract template",
      "fields_hint": "Remember that to use them, you must put the name of the field inside of %{}",
      "no_contract_template": "There is no Contract Template created yet. You can create it by editing the Product."
    },
    "create": {
      "notice": "The Product has been created successfully."
    },
    "edit": {
      "product_needs_calculator": "In order to activate the Product you need to assign it an Interest Calculator.",
      "product_needs_contract_template": "In order to activate the Product you need to create the Contract Template.",
      "product_needs_score_flow": "In order to activate the Product you need to assign a Qualification Flow to it.",
      "title": "Product %{short_name}"
    },
    "form": {
      "configuration": "Settings",
      "first": "First",
      "fourth": "Room",
      "general_information": "General information",
      "second": "Second",
      "third": "Third",
      "values": "Values"
    },
    "late_payment_base": {
      "ending_balance": "Outstanding balance",
      "principal": "Capital"
    },
    "markdown_hints_modal": {
      "title": "Markdown syntax"
    },
    "minimum_payment": {
      "amount_types": {
        "approved_amount": "Credit limit",
        "total_payment": "Debit balance"
      },
      "errors": {
        "amounts": {
          "invalid": "Minimum amount must be less than the maximum amount and must not be empty"
        },
        "percentage": {
          "invalid": "Percentage must be greater than zero"
        }
      },
      "label": "Minimum pay",
      "maximum_amount": "Maximum amount",
      "minimum_amount": "Minimum amount",
      "percentage": "Percentage"
    },
    "new": {
      "title": "New product"
    },
    "score_flow": {
      "no_score_flow": "You have not assigned a Qualification Flow to the Product.",
      "save_score_flow": "Save Qualification Flow",
      "see_score_flow": "See Qualification Flow"
    },
    "show": {
      "title": "Product %{short_name}"
    },
    "update": {
      "notice": "The Product has been successfully updated."
    }
  },
  "queryable": {
    "models": {
      "credit": {
        "credit_application": {
          "attributes": {
            "application_number": "Credit Number"
          }
        }
      },
      "installment": {
        "credit_application": {
          "attributes": {
            "application_number": "Credit Number"
          }
        }
      }
    }
  },
  "rejected_applications": {
    "new": {
      "submit": "Refuse",
      "title": "Reject request"
    }
  },
  "reports": {
    "cancel": "Cancel",
    "error": "There are no columns to export",
    "export": "To export",
    "export_to_csv_file": "Export to a CSV file",
    "select_fields_to_include_in_csv_file": "Select the fields you want to include in your CSV file:",
    "title": "Reports"
  },
  "sandbox": {
    "alert": "Sandbox enabled",
    "label": {
      "disable": "Disable sandbox",
      "enable": "Activate sandbox"
    },
    "nuke": "Clear Sandbox data"
  },
  "score_components": {
    "balance": {
      "title": "Balance qualifier"
    },
    "buro": {
      "title": "Bureau qualifier"
    },
    "conditional": {
      "title": "Conditional qualifier"
    },
    "delete_component_alert": "You cannot delete a Component whose Qualification Flow has been executed in a Request.",
    "fields": {
      "placeholder_application_fields": "Select one or more",
      "placeholder_customer_fields": "Select one or more",
      "title": "Field qualifier"
    },
    "incomplete": "The %{component} needs to be completed in the qualification flow",
    "manual": {
      "title": "Manual qualifier"
    }
  },
  "score_flow_result": "Qualification",
  "score_flow_results": {
    "create": {
      "alert": "There are one or more Components in the Rating Flow that need attention. See the Rating section.",
      "notice": "Qualification obtained satisfactorily."
    }
  },
  "score_flows": {
    "create": {
      "alert": "Qualification Flow could not be created",
      "notice": "Qualification flow has been created successfully."
    },
    "edit": {
      "title": "Update Qualification Flow"
    },
    "form": {
      "formula": "C1 + C2 - (C3 * C4) / 100",
      "name": "Eg qualification of requests"
    },
    "formula_help": {
      "headers": {
        "component": "Component",
        "factor": "Factor",
        "variable": "Variable"
      },
      "help_part_one_html": "To write a formula, it is necessary to use the following list of variables for each of the components of your qualification flow and also the allowed arithmetic operators to define the formula.<h4 class=\"mb-4 mt-4\"> Arithmetic operations allowed</h4><p> <b>+</b> Add</p><p> <b>-</b> subtract</p><p> <b>*</b> Multiply</p><p class=\"mb-4\"> <b>/</b> Divide</p>",
      "help_part_second_html": "<h4 class=\"mb-4\">Example:</h4><pre class=\"mb-4\">\n  C1 + C2 - (C3 * C4) / 100\n</pre><p>Note: You can use parentheses <code>()</code> to define the order of operation in the formula.</p>",
      "title": "Help",
      "title_body": "How do I write a formula to calculate the score?"
    },
    "new": {
      "title": "New Qualification Flow"
    },
    "score_qualifiers": {
      "conditional": {
        "failure_notes": "Some fields did not meet the condition. From Customer: %{customer_fields}. From the Request: %{application_fields}."
      },
      "fields": {
        "failure_notes": "Some fields were needed. From the Customer: %{customer_fields}. From the Request: %{application_fields}."
      }
    },
    "show": {
      "title": "Qualification Flow"
    },
    "update": {
      "alert": "Qualification Flow could not be updated",
      "notice": "Qualification flow updated correctly."
    }
  },
  "score_qualifiers": {
    "conditional": {
      "add": "Add",
      "application_fields": "Request Fields",
      "condition": "Condition",
      "customer_fields": "Customer Fields",
      "fields": "Field",
      "fields_added": "Added fields",
      "submit": "Save",
      "value": "Value"
    }
  },
  "shared": {
    "modals": {
      "accessory_modal": {
        "added_at": "Added the",
        "calculate_iva": "Calculate VAT",
        "calculation_base": "Calculation base",
        "conditions": "Terms",
        "days_offset": "Grace days",
        "fixed_value": "Value",
        "is_active": "Active",
        "percentage": "Percentage"
      },
      "webhook_tracker_modal": {
        "created_at": "Creation date",
        "payload": "Parameters",
        "response": "Answer",
        "status_code": "State",
        "target_type": "Model",
        "target_uuid": "Model ID"
      }
    },
    "search_bar": {
      "placeholder": "Search in %{resource} ..."
    }
  },
  "users": {
    "create": {
      "alert": "The email has already been invited",
      "notice": "The invitation sent to the email address: %{email}"
    },
    "edit": {
      "cancel": "Go back",
      "submit": "Save",
      "title": "Edit User"
    },
    "index": {
      "create": "Create and Invite User",
      "description": "A list of all the users in your account including their name, title, email and role."
    },
    "new": {
      "cancel": "Go back",
      "description": "Inviting user receives an email with instructions to accept the invite",
      "email": "Email of the new User",
      "send": "Send invitation",
      "title": "Invite User"
    },
    "passwords": {
      "edit": {
        "description": "Enter your new password",
        "sign_in": "Log in",
        "submit": "Restore",
        "title": "New Password"
      },
      "new": {
        "description": "Enter your email and there you will receive information on how to reset your password.",
        "email_placeholder": "user@mymail.com",
        "sign_in": "Log in",
        "submit": "Send instructions",
        "title": "Restore password"
      }
    },
    "sessions": {
      "new": {
        "description": "Sign in",
        "forgot_your_password": "I forgot my password",
        "inactive_account": "Your account is deactivated.",
        "submit": "Log in",
        "title": "Welcome!"
      }
    },
    "update": {
      "notice": "User has been updated successfully."
    },
    "user": {
      "block": "Block user",
      "block_status": {
        "one": "Locked",
        "two": "Active"
      },
      "confirm": "Are you sure?",
      "disabling": "Charging...",
      "edit_link": "Edit",
      "edit_success": "The User has been updated successfully.",
      "no_actions": "There are no actions available for this user.",
      "resend_invite": "Resend Invitation",
      "unblock": "Unlock user"
    },
    "attributes": {
      "roles": {
        "admin": "Administrator",
        "analyst": "Analyst",
        "collection": "Collection",
        "customer": "Promoter / Client",
        "owner": "Owner",
        "product": "Product"
      }
    }
  },
  "webhooks": {
    "accordions": {
      "attribute_default": "attribute",
      "attribute_value_default": "The value of the registry attribute.",
      "event_type_default_html": "The attribute of the selected field along with the type of the event, eg <code>attributo_updated</code> .",
      "help_to_you_decryption_note": "Note: To decrypt the message, it is necessary to use Base64 and use the API access key as a signature in order to obtain the message from the already decrypted payload.",
      "help_to_you_html": "The Webhook makes an HTTPS / POST request to the callback URL to your server, sending the payload in application / json format. As additional information, in the payload we include the attribute of the selected field and its value at the time the Webhook is executed. For security, the payload is sent with an encryption using Base64 signed with the access key that is used to consume the creditar.io API. The already encrypted message is sent in the following example:",
      "model_default": "The model of the selected field.",
      "record_uuid": "Unique record identifier.",
      "title": "How does the Webhook send the payload to the callback URL?",
      "webhook_id_default": "Webhook identifier."
    },
    "create": {
      "alert": "The Webhook could not be created.",
      "notice": "The Webhook has been created successfully."
    },
    "destroy": {
      "notice": "The Webhook has been successfully removed."
    },
    "edit": {
      "title": "Edit Webhook"
    },
    "new": {
      "title": "New Webhook"
    },
    "update": {
      "alert": "The Webhook could not be updated.",
      "notice": "The Webhook has been successfully updated."
    }
  },
  "write_off_modal": {
    "blank": "No field can be empty",
    "invalid_password": "Wrong password",
    "record_not_found": "Credit not found",
    "write_off": "Bad Credit"
  },
  "enums": {
    "models": {
      "accessory_applicable": {
        "attributes": {
          "payment_base": {
            "credit_approve_amount": "Credit approve amount",
            "credit_ending_balance": "Credit ending balance",
            "credit_quita": "Credit quita",
            "installment_interest": "Installment interest",
            "installment_penalty_interest": "Installment penalty interest",
            "installment_total_payment": "Installment total payment"
          }
        }
      },
      "accessory_result": {
        "attributes": {
          "status": {
            "applied": "Applied",
            "expired": "Expired",
            "pending": "Pending"
          }
        }
      },
      "credit": {
        "attributes": {
          "credit_type": {
            "credit_balance": "Credit balance",
            "credit_line": "Credit line",
            "credit_simple": "Simple credit"
          },
          "installments_state": {
            "expiring_with_grace_days": "Payment in grace days",
            "more_than_one_expired": "More than one payment expired",
            "no_installments_remaining": "Crédit paid",
            "one_expired": "One payment expired",
            "restructured": "Restructured credit",
            "up_to_date": "Payments up to date",
            "write_off": "Bad credit"
          }
        }
      },
      "credit_application": {
        "attributes": {
          "state": {
            "accepted": "Accepted",
            "approved": "Approved",
            "crowdfund": "Crowdfund",
            "has_credit": "Has credit",
            "ongoing": "Ongoing",
            "rejected": "Rejected",
            "score_flow": "Qualification flow"
          }
        }
      },
      "crowdfunding/crowdfund": {
        "attributes": {
          "status": {
            "active": "Active",
            "closed": "Closed",
            "completed": "Completed",
            "credit": "Credit",
            "rejected": "Rejected"
          }
        }
      },
      "crowdfunding/fund": {
        "attributes": {
          "status": {
            "cancelled": "Cancelled",
            "finished": "Finished",
            "funded": "Funded",
            "funding": "Funding"
          }
        }
      },
      "crowdfunding/investor": {
        "attributes": {
          "investor_type": {
            "donor": "Donor",
            "investor": "Investor"
          }
        }
      },
      "crowdfunding/movement": {
        "attributes": {
          "movement_type": {
            "cancellation": "Cancellation",
            "cash_out": "Cash out",
            "commission": "Commission",
            "commission_tax": "Commission tax",
            "credit": "Credit",
            "fund": "Fund",
            "payment": "Payment",
            "refund": "Refund"
          }
        }
      },
      "installment": {
        "attributes": {
          "status": {
            "cancelable": "To be paid",
            "expired": "Expired",
            "no_paid": "Accumulated to the following partiality",
            "paid": "Paid",
            "pending": "Pending",
            "restructured": "Restructured",
            "write_off": "Bad credit"
          }
        }
      },
      "movement": {
        "attributes": {
          "movement_type": {
            "charge": "Charge",
            "informative": "Informative",
            "payment": "Payment"
          }
        }
      },
      "payment": {
        "attributes": {
          "payment_type": {
            "advance": "Advance",
            "annuity": "Annuity",
            "ending_balance": "Ending balance",
            "minimum_amount": "Minimum amount",
            "partial": "Partial",
            "zero_amount": "Zero amount"
          },
          "prepaid_type": {
            "not_reduce": "Not reduce",
            "reduce_annuity": "Reduce annuity",
            "reduce_installments_automatically": "Reduce installments automatically",
            "reduce_installments_number": "Reduce installments number"
          },
          "status": {
            "cancelable": "Cancelable",
            "canceled": "Canceled",
            "completed": "Completed",
            "failed": "Failed"
          }
        }
      },
      "product": {
        "attributes": {
          "amortization_system_class": {
            "credit_line": "Credit line",
            "french_interest": "French system",
            "global_interest": "Global interest system"
          },
          "crowdfunding_type": {
            "between_people": "Personal loans between people",
            "between_people_with_capital": "Business loans between capital people, co-ownership or royalties",
            "between_people_with_debt": "Business loans between debt people or for real estate"
          }
        }
      },
      "score_component_result": {
        "attributes": {
          "state": {
            "passed": "Passed",
            "pending": "Pending",
            "rejected": "Rejected"
          }
        }
      }
    }
  },
  "confirmations": {
    "holiday": {
      "delete": "Are you sure you want to delete this holiday?"
    },
    "user": {
      "block": "Are you sure you want to block this user?",
      "unblock": "Are you sure you want to unblock this user?"
    }
  },
  "maxlength": {
    "default": 35,
    "holiday": {
      "happening_at": 10
    },
    "user": {
      "email": 45
    }
  },
  "notifications": {
    "alert": "Someting went wrong during the request, take a look at the form and fix the problem."
  },
  "search": {
    "placeholder": "Search into %{resource}"
  },
  "api_keys": {
    "show": {
      "cancel": "Cancel",
      "copied": "Copied",
      "copy": "Copy key",
      "description": "This key provides other applications access to credit information. Do not share it with strangers. Regenerate the key periodically here.",
      "label": "API Key",
      "regenerate": "Generate a new key",
      "title": "API access key"
    },
    "update": {
      "notice": "The access key has been successfully re-generated."
    }
  },
  "index": {
    "filters": {
      "boolean": {
        "no": "No",
        "yes": "Yes"
      },
      "clear": "Clear all",
      "count": {
        "one": "1 Filter",
        "other": "%{count} Filters"
      },
      "datetime": {
        "last_15_days": "Since last 15 days",
        "last_3_months": "Since last 3 months",
        "last_6_months": "Since last 6 months",
        "last_7_days": "Since last 7 days",
        "last_month": "Since last month",
        "last_year": "Since last year"
      },
      "submit": "Apply filters"
    },
    "table": {
      "actions": "Actions"
    }
  },
  "pagination": {
    "next": "Next",
    "previous": "Previous",
    "records": "Showing %{from} to %{to} of %{count} results"
  },
  "roles": {
    "admin": {
      "description": "Catalog setup, prepare reports, API key access",
      "label": "Admin"
    },
    "analyst": {
      "description": "Score flows follow up, application approvals",
      "label": "Analyst"
    },
    "collection": {
      "description": "Amortization table view, keep track of payments, prepare reports",
      "label": "Collection"
    },
    "customer": {
      "description": "Customer registration, customer application progress",
      "label": "Promoter / Client"
    },
    "owner": {
      "description": "Some description",
      "label": "Owner"
    },
    "product": {
      "description": "Financial products set up",
      "label": "Product"
    }
  }
}
}
