import { ApplicationController, useDispatch } from 'stimulus-use';
import Sortable from 'sortablejs';
import Rails from '@rails/ujs';

export default class extends ApplicationController {
  static targets = ['dragContainer', 'dropContainer'];

  connect() {
    useDispatch(this);

    this.sortableDrag = Sortable.create(this.dragContainerTarget, {
      group: {
        name: 'qualifiers',
        pull: 'clone',
        put: false,
      },
      ghostClass: 'drag-ghost',
      sort: false,
      animation: 150,
    });

    this.sortableDrop = Sortable.create(this.dropContainerTarget, {
      group: {
        name: 'components',
        put: 'qualifiers',
      },
      ghostClass: 'drag-ghost',
      animation: 150,
      onAdd: this.onAdd.bind(this),
      onEnd: this.onEnd.bind(this)
    });
  }

  onAdd(event) {
    let item = event.item;
    item.querySelector('form > input[name="score_component[position]"]').value =
      event.newIndex + 1;
    item.querySelector('form > button[type="submit"]').click();
    item.remove();
    this.dispatch('itemAdded');
  }

  onEnd(event) {
    const data = new FormData();
    data.append('new_position', event.newIndex);

    Rails.ajax({
      type: 'PATCH',
      url: event.item.dataset.sortableUrl,
      data: data,
    });
  }
}
