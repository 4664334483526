import { ApplicationController, useDispatch } from 'stimulus-use';

export default class extends ApplicationController {
  static targets = [
    'productSelect',
    'interestRate',
    'latePaymentInterest',
    'profitsPercentage',
    'multipleOutput',
    'investmentFundConfiguration'
  ];

  static values = { products: String }

  connect() {
    useDispatch(this);
  }

  setValues(event) {
    event.preventDefault();

    const products = JSON.parse(this.productSelectTarget.dataset.creditApplicationProductsValue);
    let product = products.find(p => p.id == event.currentTarget.value);

    this.interestRateTarget.value = parseFloat(product.interest_rate);
    this.latePaymentInterestTarget.value = parseFloat(product.late_payment_interest);

    this.dispatch('reloadFields');
  }
}
