
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'balance'
  ];

  showBalance(event) {
    let target = event.target;
    let optionValue = target.options[target.selectedIndex].value;
    let balance = document.getElementById(`balance-${optionValue}`);

    this.balanceTargets.forEach(function(balanceTarget){
      balanceTarget.classList.add('is-hidden');
    });

    if(balance) {
      balance.classList.remove('is-hidden');
    } else {
      document.getElementById('current-movements').classList.remove('is-hidden');
    }
  }
}
